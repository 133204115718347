import dede from "../locales/de-DE/common.json";
import engb from "../locales/en-GB/common.json";
import fifi from "../locales/fi-FI/common.json";
import itit from "../locales/it-IT/common.json";
import svse from "../locales/sv-SE/common.json";

export const resources = {
  de: {
    common: dede,
  },
  en: {
    common: engb,
  },
  fi: {
    common: fifi,
  },
  it: {
    common: itit,
  },
  sv: {
    common: svse,
  },
};

export const languages = {
  deDE: "de",
  enGB: "en",
  fiFI: "fi",
  itIT: "it",
  svSE: "sv",
};

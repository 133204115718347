import * as React from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import GameScore from "../../models/game-result";

import "./style.css";
import {convert, moveElement} from "../../helpers/score-calculation-helper";

type props = {
  gameResults: GameScore[];
  currentResult: GameScore;
  showCorrectMoves?: boolean;
  showCorrectnessPercentage?: boolean;
};

const ScorecardResultsTable: React.FC<props> = (props): JSX.Element => {
  const { t } = useTranslation("common");

  const currentResultId = () => {
    let now = new Date();
    now.setMinutes(now.getMinutes() - 1);
    return props.gameResults.findIndex((x) => x.score == props.currentResult.score && x.createdAt! > now);
  };
  const resultId = currentResultId();
  //console.log("resultId",resultId)

  return (
    <Fragment>
      <h2 className="text-center">{t("games.score_card.your_high_scores")}</h2>
      {Boolean(props.gameResults.length > 0 && props.gameResults[0].level && props.gameResults[0].level > 0) && (
        <h4>
          {t("common.level")}: {props.currentResult.level!}
        </h4>
      )}
      <table>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{t("games.score_card.score")}</th>
            <th scope="col">{t("games.score_card.date")}</th>

            {props.showCorrectnessPercentage ? <th scope="col">{t("games.score_card.correct_answers")}</th> : null}
            {props.showCorrectMoves ? <th scope="col">{t("games.score_card.correct_answers")}</th> : null}
          </tr>
        </thead>
        <tbody>
          {props.gameResults.map((item, index) => {
            //let date = item.createdAt?.toISOString().split("T")[0].split("-").reverse().join("/")
            const arr1 = item.createdAt?.toLocaleDateString().split("T")[0].split("/");
            const arr = arr1!.map(x => convert(x));
            
            const fromIndex = arr!.indexOf(arr![0]);
            const toIndex = 1;
            
            const result = moveElement(arr!, fromIndex, toIndex);
            let date = result.join("/");

            return (
              <tr key={index} className={`${index == resultId ? "table-tr-current" : ""}`}>
                <th scope="row" data-label="#">
                  {index + 1}
                </th>
                <td data-label={`${t("games.score_card.score")}`}>{item.score}</td>
                <td data-label={`${t("games.score_card.date")}`}>{date}</td>

                {props.showCorrectnessPercentage ? <td data-label={`${t("games.score_card.correct_answers")}`}>{item.percentage}%</td> : null}
                {props.showCorrectMoves ? <td data-label={`${t("games.score_card.correct_answers")}`}>{item.correctItems}</td> : null}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Fragment>
  );
};

export default ScorecardResultsTable;

import * as React from "react";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";

import { TripletData, TripletsColor, TripletsItem } from "../../models/triplets";
import { GameLevelsData } from "../../models/game-level-data";

import { getRandomTriplets, Triplet, numberOfQuestions } from "../../helpers/episodic-memory-relational-helpers";

import Questions from "./questions";

import "./style.css";
import {handleUpdateAppWithUserWeeklyScheduleGames} from "../../helpers/routes";
import {useUserInfo} from "../../contexts/user-info-context";

interface props {
  tripletData: TripletData[];
  colorDefinitions: TripletsColor[];
  intervalDuration: number;
  level: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
  setStart: React.Dispatch<React.SetStateAction<boolean>>;
  levelButtonsForTheGame: GameLevelsData[];
}

const Game = (props: props) => {
  const { t } = useTranslation("common");
  const { currentUserInfo, updateCurrentUserInfoWithGameSchedule, noSchedule } = useUserInfo();
  const { tripletData, colorDefinitions, intervalDuration, level, setStart, setCounter, levelButtonsForTheGame } = props;
  const [colors, setColors] = useState<TripletsColor[]>(JSON.parse(JSON.stringify(props.colorDefinitions)));
  const [items, setItems] = useState<TripletsItem[]>(tripletData.map((item) => item.word));
  const [referenceItems, setReferenceItems] = useState<TripletsItem[]>(tripletData.map((referenceItem) => referenceItem.referencedWord));

  const [index, setIndex] = useState<number>(0);
  const [randomTriplets, setRandomTriplets] = useState<Triplet[]>(
    getRandomTriplets(numberOfQuestions(level, levelButtonsForTheGame)!, colors, items, referenceItems)
  );
  
  const [duration, setDuration] = useState<number>(intervalDuration);
  const [scoresFromAnswers, setScoresFromAnswers] = useState<number[]>([]);

  const [startDateTime, setStartDateTime] = useState<Date>(new Date());
  const [totalTimeToLearningTriplets, setTotalTimeToLearningTriplets] = useState<number>(0);

  const completePhase = (score: number) => {
    setScoresFromAnswers((prevState) => [...prevState, score]);
  };

  const handleIndex = () => {
    setIndex(index === randomTriplets.length ? 0 : index + 1);
  };

  useEffect(() => {
    if (index < numberOfQuestions(level, levelButtonsForTheGame)!) {
      setTimeout(handleIndex, duration);
    }
  }, [index]);

  useEffect(() => {
    if (index >= numberOfQuestions(level, levelButtonsForTheGame)!) {
      let fromStart = differenceInMilliseconds(new Date(), startDateTime!);
      let fromStartToPrevious = 0;
      setTotalTimeToLearningTriplets(fromStart-fromStartToPrevious);
    }
  }, [index]);
  
  // useEffect(() => {
  //  console.log({randomTriplets})
  // },[randomTriplets])

  const safariRenderHack = { opacity: index % 2 ? 1 : 0.99 };

  return (
    <Fragment>
      <div className="d-flex justify-content-center">
        {index < numberOfQuestions(level, levelButtonsForTheGame)! ? (
          <div style={safariRenderHack} className="d-flex flex-column">
            <h3 className="mb-5 d-flex justify-content-center">{t("games.triplets.memorize_the_triplets_shown")}</h3>
            <div style={safariRenderHack}>
              <div
                className={`triplets-box-2 ${randomTriplets[index % randomTriplets.length].color.htmlColorName === "yellow" ? "shadowed-text-for-yellow" : ""}`}
                style={{ color: `${randomTriplets[index % randomTriplets.length].color.htmlColorName}` }}
              >
                <div>{randomTriplets[index % randomTriplets.length].item.toUpperCase()}</div>
                <div>{randomTriplets[index % randomTriplets.length].referenceItem.toUpperCase()}</div>
              </div>
            </div>
            <hr className="hr-style" />
            <div className="text-center mt-5">
              <Link to="/" className="m-3 text-center">
                <Button variant="outline-secondary" size="lg" onClick={() => handleUpdateAppWithUserWeeklyScheduleGames(updateCurrentUserInfoWithGameSchedule, noSchedule)}>
                  {t("common.close")}
                </Button>
              </Link>
            </div>
          </div>
        ) : (
          <Questions
            randomTriplets={randomTriplets}
            colors={colors}
            referenceItems={referenceItems}
            completePhase={completePhase}
            level={level}
            setStart={setStart}
            setCounter={setCounter}
            levelButtonsForTheGame={levelButtonsForTheGame}
            totalTimeToLearningTriplets={totalTimeToLearningTriplets}
          />
        )}
      </div>
    </Fragment>
  );
};

export default Game;

import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarthAfrica } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import { useUserInfo } from "../../contexts/user-info-context";
import * as React from "react";

import "./style.css";

const LanguageSwitcher = () => {
  const tokenFromOutsideUrl = process.env.REACT_APP_TOKEN;
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { currentUserInfo, scheduleGameForUser } = useUserInfo();

  const { i18n } = useTranslation("common");

  const handleLanguageUpdate = (value: any) => {
    i18n.changeLanguage(value).then((r) => r);
    if (currentUserInfo !== null) {
      return new Promise<void>(async (resolve, reject) => {
        try {
          const token = !isAuthenticated
            ? localStorage.getItem(process.env.REACT_APP_TOKEN_NAME!)
            : await getAccessTokenSilently();
          const requestOptions = {
            method: "PUT",
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ languageCulture: value }),
          };
          const res = await fetch(
            process.env.REACT_APP_API_SERVER_URL +
              `/user/${currentUserInfo!.id}/${value}`,
            requestOptions
          );
          const resJson = await res.json();
          resolve();
          //console.log({ resJson });
        } catch (err) {
          reject(err);
          console.log(err);
        }
      });
    }
  };

  return (
    <DropdownButton
      title={
        <FontAwesomeIcon
          className="float-end text-white"
          icon={faEarthAfrica}
          size="2x"
        />
      }
      onSelect={handleLanguageUpdate}
      variant="#4473AF"
    >
      <Dropdown.Item eventKey="en">English</Dropdown.Item>
      <Dropdown.Item eventKey="fi">Suomi</Dropdown.Item>
      <Dropdown.Item eventKey="sv">Svenska</Dropdown.Item>
      <Dropdown.Item eventKey="it">Italiano</Dropdown.Item>
      <Dropdown.Item eventKey="de">Deutsch</Dropdown.Item>
    </DropdownButton>
  );
};

export default LanguageSwitcher;

import * as React from "react";
import TabNavItem from "./tab-nav-item";
import TabContent from "./tab-content";
import "./style.css";
import { useTranslation } from "react-i18next";
import { ActiveTab } from "../../models/active-tab";

interface props {
  children: React.ReactNode;
  activeTab: ActiveTab;
  toggleTab?: (id: ActiveTab) => void;
}

const Tabs = (props: props) => {
  const { t } = useTranslation("common");
  const { children, activeTab, toggleTab } = props;

  // const handleTabPracticing = () => {
  //     setActiveTab("Practicing")
  // }
  // const handleTabTraining = () => {
  //     setActiveTab("Training")
  // }
  //
  // const handleTab = (tabTitle: string) => {
  //     setActiveTab(tabTitle === "Practicing" ? "Practicing": "Training")
  // }
  return (
    <div>
      <ul className="nav nav-tabs justify-content-center">
        <TabNavItem id="Training" title={t("common.training")} activeTab={activeTab} toggleTab={toggleTab} />
        <TabNavItem id="Practicing" title={t("common.practicing")} activeTab={activeTab} toggleTab={toggleTab} />
      </ul>
      <div className="outlet">
        <TabContent id="Training" activeTab={activeTab}>
          {children}
        </TabContent>
        <TabContent id="Practicing" activeTab={activeTab}>
          {children}
        </TabContent>
      </div>
    </div>
  );
};

export default Tabs;

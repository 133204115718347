import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { useUserInfo } from "../../contexts/user-info-context";
import { useEffect } from "react";

const SignUp: React.FunctionComponent<any> = (props) => {
  const { loginWithRedirect } = useAuth0();
  const { currentUserInfo } = useUserInfo();
  const { i18n, t } = useTranslation("common");
  // const handleLogin = async () => {
  //     await loginWithRedirect();
  //     if(currentUserInfo != null || currentUserInfo != undefined){
  //         await i18n.changeLanguage(currentUserInfo!.languageCulture.languageCode)
  //     }
  //
  // }

  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/",
      },
      screen_hint: "signup",
    });
  };

  useEffect(() => {
    loginWithRedirect();
  });

  return (
    <section className="vh-100">
      {/* <div className="container h-100">
        <div className="col-lg-12 col-xl-11">
          <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">
            {t("common.login_and_signup_message")}
          </p>
          <div className="card text-black" style={{ borderRadius: "25px" }}>
            <div className="card-body p-md-5">
              <div className="row justify-content-center">
                <div className="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">
                  <form className="mx-1 mx-md-4">
                    <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                      <button
                        type="button"
                        className="btn btn-primary btn-lg"
                        onClick={() => loginWithRedirect()}
                      >
                        {t("common.login")}
                      </button>
                    </div>
                    <div className="form-check d-flex justify-content-center mb-5">
                      <label
                        className="form-check-label"
                        htmlFor="form2Example3"
                      >
                        {t("common.view")}{" "}
                        <a
                          href={
                            process.env.PUBLIC_URL +
                            "/privacy-policy/privacy-policy.pdf"
                          }
                          target="_blank"
                        >
                          {t("common.terms_service")}
                        </a>
                      </label>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default SignUp;

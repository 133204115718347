import * as React from "react";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";

import { TripletsColor, TripletsItem } from "../../models/triplets";
import GameScore from "../../models/game-result";
import { GameStage } from "../../models/game-stage";
import { GameLevelsData } from "../../models/game-level-data";

import { useUserInfo } from "../../contexts/user-info-context";
import { useActiveTab } from "../../contexts/active-tab-context";
//import { getNumberOfOptions, MAX_LEVEL, numberOfQuestions } from "./index";

import {
  compareObjects,
  getQuestionSet,
  Question,
  Triplet,
  getNumberOfOptions,
  MAX_LEVEL,
  numberOfQuestions,
  TripletShown,
  shuffleArray,
} from "../../helpers/episodic-memory-relational-helpers";

import Scorecard from "../../components/scorecard";

import "./style.css";
import { timeStamp } from "console";
import { handleUpdateAppWithUserWeeklyScheduleGames } from "../../helpers/routes";

interface props {
  randomTriplets: Triplet[];
  colors: TripletsColor[];
  referenceItems: TripletsItem[];
  completePhase: (score: number) => void;
  level: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
  setStart: React.Dispatch<React.SetStateAction<boolean>>;
  levelButtonsForTheGame: GameLevelsData[];
  totalTimeToLearningTriplets: number;
}

class DetailedResults {
  answers: Triplet[];
  level: number;
  totalTime: number;
  score: number;
  totalCorrectAnswers: number;
  constructor(
    answers: Triplet[],
    level: number,
    time: number,
    score: number,
    totalCorrectAnswers: number
  ) {
    this.answers = answers;
    this.level = level;
    this.totalTime = time;
    this.score = score;
    this.totalCorrectAnswers = totalCorrectAnswers;
  }
}

const Questions = (props: props) => {
  const MIN_POINTS_FOR_CORRECT_ANSWER = 250;
  const MAX_POINTS_FOR_CORRECT_ANWER = 1000;
  const MIN_POINTS_FOR_CORRECT_COLOR_OR_ITEM = 50;
  const MAX_POINTS_FOR_CORRECT_COLOR_OR_ITEM = 200;
  const TIME_PENALTY_ANSWER_PER_S = 50;
  const TIME_PENALTY_PARTIAL_PER_S = 30;

  const {
    randomTriplets,
    colors,
    completePhase,
    level,
    setCounter,
    setStart,
    levelButtonsForTheGame,
    totalTimeToLearningTriplets,
    referenceItems,
  } = props;
  const { user, getAccessTokenSilently } = useAuth0();

  const { currentUserInfo, updateCurrentUserInfoWithGameSchedule, noSchedule } =
    useUserInfo();
  const { activeTab } = useActiveTab();

  const [givenAnswers, setGivenAnswers] = useState<Triplet[]>([]);
  const [colorDefs, setColorDefs] = useState<TripletsColor[]>(
    JSON.parse(JSON.stringify(props.colors))
  );
  const [items, setItems] = useState<string[]>(
    randomTriplets.map((value) => value.item)
  );
  const [arrayOfReferenceItems, setArrayOfReferenceItems] = useState<string[]>(
    referenceItems.map((x) => x.translation)
  );
  const [index, setIndex] = useState<number>(-1);

  const [questionSets, setQuestionSets] = useState<Question[]>(
    shuffleArray(
      getQuestionSet(
        numberOfQuestions(props.level, levelButtonsForTheGame)!,
        getNumberOfOptions(props.level, levelButtonsForTheGame)!,
        randomTriplets,
        colorDefs,
        arrayOfReferenceItems
      )
    )
  );
  const [questionSetIndex, setIndexQuestionSetIndex] = useState<number>(0);
  const [answeringStartDateTime, setAnsweringStartDateTime] = useState<Date>(
    new Date()
  );

  const { t } = useTranslation("common");

  const [answer, setAnswer] = useState<Triplet>({
    item: "",
    color: new TripletsColor("{}"),
    referenceItem: "",
    answeringTimeMS: 0,
    score: 0,
    correctColor: false,
    correctItem: false,
    colorChoices: [],
    referenceItemChoices: [],
  });

  const [highScore, setHighScore] = useState<number>(0);
  const [selectRefItem, setSelectRefItem] = useState<string[]>([]);
  const [selectColor, setSelectColor] = useState<string[]>([]);
  const [gameStage, setGameStage] = useState<GameStage>("ShowCountdown");

  const [gameScore, setGameScore] = useState<GameScore>({
    correctItems: 0,
    email: currentUserInfo.email!,
    userId: currentUserInfo.id,
    isScheduledGame: activeTab === "Training",
    score: 0,
    totalTimeSeconds: 0,
    gameType: "EpisodicMemoryRelational",
    level: level,
    percentage: 0,
  });

  const onIndexIncrement = () => {
    setIndexQuestionSetIndex(questionSetIndex + 1);
    setIndex(index + 1);
    setSelectColor([]);
    setSelectRefItem([]);
  };

  const onPickedReferenceItem = (item: React.MouseEvent<HTMLElement>) => {
    const pickedReferenceItem =
      item.currentTarget.getAttribute("picked-ref-item")!;
    const pickedItem = item.currentTarget.getAttribute("picked-item")!;
    setAnswer((prevState) => {
      return {
        ...prevState,
        item: pickedItem,
        referenceItem: pickedReferenceItem,
      };
    });

    setSelectRefItem((prevState) => {
      if (prevState.includes(pickedReferenceItem)) {
        return prevState.filter((el) => el !== pickedReferenceItem);
      }
      return [pickedReferenceItem];
    });
  };

  const onPickedColor = (item: React.MouseEvent<HTMLElement>) => {
    const pickedColor = item.currentTarget.getAttribute("picked-color")!;
    const colorDef = questionSets[questionSetIndex].color.find(
      (x) => x.color == pickedColor
    );

    setAnswer((prevState) => {
      return { ...prevState, color: colorDef! };
    });

    setSelectColor((prevState) => {
      if (prevState.includes(pickedColor)) {
        return prevState.filter((el) => el !== pickedColor);
      }
      return [pickedColor];
    });
  };

  const scoreCalculations = () => {
    if (
      answer.color === undefined ||
      answer.color.color === undefined ||
      answer.item.length == 0
    ) {
      return;
    }

    let findTriplet = randomTriplets.filter((e) => e.item === answer.item);
    let tripletShown: TripletShown = {
      item: findTriplet[0].item,
      referenceItem: findTriplet[0].referenceItem,
      color: findTriplet[0].color,
    };

    let givenAnswer = {
      tripletShown: tripletShown,
      color: answer.color,
      item: answer.item,
      referenceItem: answer.referenceItem,
      answeringTimeMS: differenceInMilliseconds(
        new Date(),
        answeringStartDateTime
      ),
      score: 0,
      correctColor: answer.color === tripletShown.color,
      correctItem: answer.referenceItem === tripletShown.referenceItem,
      colorChoices: questionSets[questionSetIndex - 1].color.map(
        (value) => value.color
      ),
      referenceItemChoices: questionSets[questionSetIndex - 1].referenceItem,
    };

    let scoreComp = compareObjects(findTriplet[0], answer);
    switch (scoreComp) {
      case 1:
        givenAnswer.score = Math.round(
          MAX_POINTS_FOR_CORRECT_COLOR_OR_ITEM -
            (TIME_PENALTY_PARTIAL_PER_S * givenAnswer.answeringTimeMS) / 1000
        );
        if (givenAnswer.score < MIN_POINTS_FOR_CORRECT_COLOR_OR_ITEM) {
          givenAnswer.score = MIN_POINTS_FOR_CORRECT_COLOR_OR_ITEM;
        }
        break;
      case 2:
        givenAnswer.score = Math.round(
          MAX_POINTS_FOR_CORRECT_ANWER -
            (TIME_PENALTY_ANSWER_PER_S * givenAnswer.answeringTimeMS) / 1000
        );
        if (givenAnswer.score < MIN_POINTS_FOR_CORRECT_ANSWER) {
          givenAnswer.score = MIN_POINTS_FOR_CORRECT_ANSWER;
        }
        break;
      default:
        break;
    }

    givenAnswers.push(givenAnswer);
    setHighScore(givenAnswers.reduce((a, b) => a + b.score, 0));
    setAnsweringStartDateTime(new Date());
  };

  const restartGame = () => {
    setStart(false);
    setCounter(3);
  };

  useEffect(() => {
    scoreCalculations();
  }, [questionSetIndex]);

  useEffect(() => {
    if (questionSetIndex >= questionSets.length) {
      setGameScore((prevState) => {
        let correctAnswers = givenAnswers.reduce((counter, x) => {
          return (counter += Number(x.correctItem) + Number(x.correctColor));
        }, 0);
        let correctTriplets = givenAnswers.reduce((counter, x) => {
          return (counter += Number(x.correctItem && x.correctColor));
        }, 0);

        return {
          ...prevState,
          score: highScore,
          level: level,
          correctItems: correctAnswers,
          correctSequences: correctTriplets,
          maxLevel: MAX_LEVEL,
          percentage: Math.round(
            (100 * correctAnswers) /
              (numberOfQuestions(level, levelButtonsForTheGame)! * 2)
          ),
          numberOfItems: numberOfQuestions(level, levelButtonsForTheGame)! * 2,
          numberOfSequencers: numberOfQuestions(level, levelButtonsForTheGame)!,
          totalTimeSeconds: Math.round(
            (givenAnswers.reduce((a, b) => a + b.answeringTimeMS, 0) +
              totalTimeToLearningTriplets) /
              1000
          ),
          averageTimeMs: Math.round(
            givenAnswers.reduce((a, b) => a + b.answeringTimeMS, 0) /
              givenAnswers.length
          ),
        };
      });
    }
  }, [highScore, questionSetIndex]);

  useEffect(() => {
    if (gameScore.totalTimeSeconds > 0) {
      setGameStage("ShowScore");
    }
  }, [gameScore]);

  return (
    <section>
      <div>
        {questionSets.map((question, index) =>
          index === questionSetIndex ? (
            <div key={index}>
              <h3 className="mb-4 triplets-text-style">
                {t("games.triplets.instruction_click_object_and_color")}
              </h3>
              <div className="triplets-style-lg triplets-style-md triplets-style-sm triplets-style-xs">
                <div
                  className={
                    question.item.length > 9
                      ? `triplets-box-1-long-word`
                      : `triplets-box-1`
                  }
                >
                  {question.item.toUpperCase()}
                </div>

                <div className="d-flex flex-row justify-content-between">
                  <div className="m-3">
                    {question.referenceItem.map((refItem, index) => (
                      <li
                        key={index}
                        style={{ listStyle: "none" }}
                        className="mb-2"
                      >
                        <button
                          className={`btn ${
                            selectRefItem.includes(refItem) ? "btn-primary" : ""
                          } triplets-button-style`}
                          onClick={onPickedReferenceItem}
                          picked-ref-item={refItem}
                          picked-item={question.item}
                        >
                          {refItem.toUpperCase()}
                        </button>
                      </li>
                    ))}
                  </div>

                  <div className="m-3">
                    {question.color.map((color, index) => (
                      <li
                        key={index}
                        style={{ listStyle: "none" }}
                        className="mb-2"
                      >
                        <button
                          className={`btn ${
                            color.color === "yellow"
                              ? "triplets-button-style-black-text"
                              : "triplets-button-style"
                          } }`}
                          style={{
                            backgroundColor: `${color.htmlColorName}`,
                            color: "white",
                            border: `${
                              selectColor.includes(color.color)
                                ? "5px solid #27b9f2"
                                : ""
                            }`,
                          }}
                          onClick={onPickedColor}
                          picked-color={color.color}
                        >
                          {color.translation.toUpperCase()}
                        </button>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : null
        )}

        {
          <div>
            {gameStage === "ShowScore" || gameStage === "ScoreSent" ? (
              <div>
                <Scorecard
                  showCorrectSequences={false}
                  showCorrectnessPercentage={true}
                  showLenghtOfSequence={false}
                  showAverageTimeMs={false}
                  showCorrectMoves={false}
                  gameScore={gameScore}
                  restartGame={restartGame}
                  setGameStage={setGameStage}
                  gameStage={gameStage}
                  detailedResults={JSON.stringify(
                    new DetailedResults(
                      givenAnswers,
                      gameScore.level!,
                      gameScore.totalTimeSeconds,
                      gameScore.score,
                      gameScore.percentage!
                    )
                  )}
                />
              </div>
            ) : (
              <div className="d-grid gap-2 mt-lg-5 mt-md-5 mt-sm-3 triplets-question-button">
                <Button
                  variant={
                    selectRefItem.length === 0 || selectColor.length === 0
                      ? "secondary"
                      : "success"
                  }
                  onClick={() => onIndexIncrement()}
                  disabled={
                    selectRefItem.length === 0 || selectColor.length === 0
                  }
                >
                  {t("games.triplets.next_question")}
                </Button>
                <hr className="hr-style" />
                <div className="text-center mt-5">
                  <Link to="/" className="m-3 text-center">
                    <Button
                      variant="outline-secondary"
                      size="lg"
                      onClick={() =>
                        handleUpdateAppWithUserWeeklyScheduleGames(
                          updateCurrentUserInfoWithGameSchedule,
                          noSchedule
                        )
                      }
                    >
                      {t("common.close")}
                    </Button>
                  </Link>
                </div>
              </div>
            )}
          </div>
        }
      </div>
    </section>
  );
};

export default Questions;

import * as React from "react";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import g2d1 from "../../images/G2D1b.png";
import g2d2 from "../../images/G2D2b.png";
import g2d3 from "../../images/G2D3b.png";
import "./style.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import Game from "./game";
import CountDown from "../../components/count-down";

import { ActiveTab } from "../../models/active-tab";
import { UserDataForGame } from "../../models/user-data-for-game";
import { GameLevelsData } from "../../models/game-level-data";

import { useUserDataForGame } from "../../contexts/user-data-for-game-context";
import { useActiveTab } from "../../contexts/active-tab-context";
import {handleUpdateAppWithUserWeeklyScheduleGames} from "../../helpers/routes";
import {useUserInfo} from "../../contexts/user-info-context";

export const getGameLevelData = (activeTab: ActiveTab, levelAchieved: number) => {
  return [
    { title: "games.difficulty_level.easy", level: 1, image: g2d1, active: true },
    {
      title: "games.difficulty_level.intermediate",
      level: 2,
      image: g2d2,
      active: activeTab === "Practicing" ? true : levelAchieved >= 2,
    },
    {
      title: "games.difficulty_level.hard",
      level: 3,
      image: g2d3,
      active: activeTab === "Practicing" ? true : levelAchieved >= 3,
    },
  ];
};

// index with level
const PAIRS_PER_DIFFICULTY_LEVEL: number[] = [8, 8, 10, 12];

const EpisodicMemorySpatialSpeed: React.FunctionComponent = () => {
  const { t } = useTranslation("common");

  const { activeTab } = useActiveTab();
  const { userDataForGames, updateUserDataForGames } = useUserDataForGame();
  const {noSchedule, updateCurrentUserInfoWithGameSchedule} = useUserInfo();

  const [data, setData] = useState<string[]>([]);
  const [arrayOfImages, setArrayOfImages] = useState<string[]>([]);
  const [startGame, setStartGame] = useState<boolean>(false);
  const [level, setLevel] = useState<number>(0);
  const [counter, setCounter] = useState<number>(3);

  const [userDataForTheGame, setUserDataForTheGame] = useState<UserDataForGame>();
  const [levelButtonsForTheGame, setLevelButtonsForTheGame] = useState<GameLevelsData[]>([new GameLevelsData()]);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_SERVER_URL + "/images")
      .then((res) => {
        return res.json();
      })
      .then((images) => {
        setData(images);
      });
  }, []);

  const handleGameLevelButton = (level: number) => {
    setLevel(level);
    setStartGame(true);
  };

  const getRandomImage = () => {
    return data[Math.floor(Math.random() * data.length)];
  };

  const getRandomImages = (total: number): string[] => {
    const setOfImages: Set<string> = new Set<string>();
    while (setOfImages.size < total) {
      setOfImages.add(getRandomImage());
    }
    return Array.from(setOfImages);
  };

  useEffect(() => {
    if (userDataForGames != undefined || userDataForGames != null) {
      setUserDataForTheGame(userDataForGames!.find((x: any) => x.gameType === "EpisodicMemorySpatialSpeed"));
    }
  }, [userDataForGames]);

  useEffect(() => {
    setLevelButtonsForTheGame(getGameLevelData(activeTab, userDataForTheGame != undefined ? userDataForTheGame.levelAchieved : 1));
  }, [userDataForTheGame]);

  const getArrayOfImages = () => {
    let images = getRandomImages(PAIRS_PER_DIFFICULTY_LEVEL[level]);
    setArrayOfImages(images);
    return images;
  };

  useEffect(() => {}, [startGame]);

  if (startGame) {
    return <CountDown counter={counter} setCounter={setCounter} setStart={setStartGame} />;
  }

  return (
    <Fragment>
      <section className="vh-100">
        {counter === 0 ? (
          <Game getArrayOfImages={getArrayOfImages} level={level} setStart={setStartGame} setCounter={setCounter} />
        ) : (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="container py-5 h-100"></div>
            <h2 className="text-center mb-4">{t("games.select_difficulty_level")}:</h2>
            <div className="container h-100">
              {/*<h3 className="mb-5 d-flex justify-content-center" style={{ color: "blue" }}>*/}
              {/*  {t("games.memory_cards.memory_cards_title")}*/}
              {/*</h3>*/}
              <Row xs={2} md={3} className="g-4">
                {levelButtonsForTheGame.map((button, index) => (
                  <Col key={index}>
                    <Card
                      className={`card-style ${button.active ? "" : "disabled-link"}`}
                      onClick={button.active ? () => handleGameLevelButton(button.level) : () => {}}
                      role="button"
                    >
                      <Card.Img variant="top" className="image-style-memory" src={`${button.image}`} />
                      <Card.Title className="text-center font-style">{t(button.title)}</Card.Title>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
            <div className="text-center mt-5">
              <Link to="/" className="m-3 text-center">
                <Button variant="outline-secondary" size="lg" onClick={() => handleUpdateAppWithUserWeeklyScheduleGames(updateCurrentUserInfoWithGameSchedule, noSchedule)}>
                  {t("common.close")}
                </Button>
              </Link>
            </div>
          </div>
        )}
        {/*<div>*/}
        {/*    {counter === 0 ? <Game arrayOfImages={arrayOfImages} level={level}/>: ""}*/}
        {/*</div>*/}
      </section>
    </Fragment>
  );
};

export default EpisodicMemorySpatialSpeed;

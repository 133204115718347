import * as React from "react";
import { useEffect, useState } from "react";
import {
  generateRandomColumnNumber,
  generateRandomNumber,
  generateRandomRowNumber,
} from "../../helpers/mental-set-shifting-helpers";
import MentalAnswer from "../../models/mental-shifting-details";
import g6h3 from "../../images/G6H3.png";
import g6h4 from "../../images/G6H4.png";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useKeyPress } from "../../hooks/use-key-press";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import { scoreCalculation } from "../../helpers/score-calculation-helper";
import GameScore from "../../models/game-result";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import ScoreCard from "../../components/scorecard";
import { useUserInfo } from "../../contexts/user-info-context";
import { useLocation } from "react-router";

import "./style.css";
import { useActiveTab } from "../../contexts/active-tab-context";
import { handleUpdateAppWithUserWeeklyScheduleGames } from "../../helpers/routes";

interface props {
  setCounter: React.Dispatch<React.SetStateAction<number>>;
  setStart: React.Dispatch<React.SetStateAction<boolean>>;
}

export class DetailedAnswers {
  email: string;
  level: number;
  score: number;
  totalCorrectAnswers: number;
  totalTimeSeconds: number;
  answers: Array<MentalAnswer>;
  constructor(score: GameScore, answers: Array<MentalAnswer>) {
    this.email = score.email;
    this.level = score.level == null ? 0 : score.level!;
    this.score = score.score;
    this.totalCorrectAnswers = score.percentage!;
    this.totalTimeSeconds = score.totalTimeSeconds;
    this.answers = answers;
  }
}

export const NUMBER_OF_QUESTIONS = 32;

const Game = (props: props) => {
  const { setCounter, setStart } = props;
  const location: any = useLocation();

  const { user } = useAuth0();
  const { t } = useTranslation("common");

  const {
    currentUserInfo,
    scheduleGameForUser,
    updateCurrentUserInfoWithGameSchedule,
    noSchedule,
  } = useUserInfo();
  const { activeTab } = useActiveTab();

  const [numberToShow, setNumberToShow] = useState<number>(0);
  const [columnNumber, setColumnNumber] = useState<number>(0);
  const [rowNumber, setRowNumber] = useState<number>(0);
  const [countAnswer, setCountAnswer] = useState<number>(0);
  const [gameLength, setGameLength] = useState<number>(NUMBER_OF_QUESTIONS);
  const [answers, setAnswers] = useState<Array<MentalAnswer>>([]);
  const [correctAnswer, setCorrectAnswer] = useState<number>(0);
  const [startDateTime, setStartDateTime] = useState<Date>(new Date());

  const [gameScore, setGameScore] = useState<GameScore>({
    correctItems: 0,
    email: currentUserInfo.email!,
    isScheduledGame: activeTab === "Training",
    userId: currentUserInfo.id,
    score: 0,
    totalTimeSeconds: 0,
    averageTimeMs: 0,
    gameType: "MentalSetShifting",
    level: 0,
    percentage: 0,
  });

  const leftKeyPress: boolean = useKeyPress("ArrowLeft");
  const rightKeyPress: boolean = useKeyPress("ArrowRight");

  const generateRandomNumbers = () => {
    setNumberToShow(generateRandomNumber);
    setColumnNumber(generateRandomColumnNumber);
    setRowNumber(generateRandomRowNumber);
  };

  const updateReactionTimes = () => {
    let shownToClickDifferenceInMilliseconds = differenceInMilliseconds(
      new Date(),
      startDateTime!
    );
    let fromStartToPrevious = 0;
    answers.forEach((x) => {
      fromStartToPrevious += x.reactionTimeMs;
    });
    answers[answers.length - 1].reactionTimeMs =
      shownToClickDifferenceInMilliseconds - fromStartToPrevious;
    setCountAnswer((prevState) => prevState + 1);
    let totalTime = answers.reduce(
      (total, obj) => obj.reactionTimeMs + total,
      0
    );
    setGameScore((prevState) => {
      return {
        ...prevState,
        score: Math.round(scoreCalculation(answers)),
        totalTimeSeconds: Math.round(totalTime / 1000),
        averageTimeMs: Math.round(totalTime / gameLength),
        correctItems: answers.filter((x) => x.correct).length,
        numberOfItems: answers.length,
        percentage: Math.round(
          (answers.filter((x) => x.correct).length * 100) / answers.length
        ),
        maxLevel: undefined,
      };
    });
  };

  const restartGame = () => {
    setCounter(3);
    setStart(true);
  };

  useEffect(() => {
    generateRandomNumbers();
  }, []);

  useEffect(() => {}, [scheduleGameForUser]);

  useEffect(() => {
    if (leftKeyPress) {
      onLeftArrowClicked();
    } else if (rightKeyPress) {
      onRightArrowClicked();
    }
  }, [leftKeyPress, rightKeyPress]);

  const onLeftArrowClicked = () => {
    // only handle clicking if game not yet over
    if (countAnswer >= gameLength) {
      return;
    }

    if (rowNumber === 0) {
      if (columnNumber === 0 || columnNumber === 1) {
        answers.push(
          new MentalAnswer(
            numberToShow,
            numberToShow < 5,
            columnNumber === 0 ? "HighLowLeft" : "HighLowRight"
          )
        );
        if (numberToShow < 5) {
          setCorrectAnswer((prevState) => prevState + 1);
        }
      }
    }
    if (rowNumber === 1) {
      if (columnNumber === 0 || columnNumber === 1) {
        answers.push(
          new MentalAnswer(
            numberToShow,
            numberToShow % 2 !== 0,
            columnNumber === 0 ? "EvenOddLeft" : "EvenOddRight"
          )
        );
        if (numberToShow % 2 !== 0) {
          setCorrectAnswer((prevState) => prevState + 1);
        }
      }
    }
    updateReactionTimes();
    generateRandomNumbers();
  };

  const onRightArrowClicked = () => {
    // only handle clicking if game not yet over
    if (countAnswer >= gameLength) {
      return;
    }

    if (rowNumber === 0) {
      if (columnNumber === 0 || columnNumber === 1) {
        answers.push(
          new MentalAnswer(
            numberToShow,
            numberToShow > 5,
            columnNumber === 0 ? "HighLowLeft" : "HighLowRight"
          )
        );
        if (numberToShow > 5) {
          setCorrectAnswer((prevState) => prevState + 1);
        }
      }
    }
    if (rowNumber === 1) {
      if (columnNumber === 0 || columnNumber === 1) {
        answers.push(
          new MentalAnswer(
            numberToShow,
            numberToShow % 2 === 0,
            columnNumber === 0 ? "EvenOddLeft" : "EvenOddRight"
          )
        );
        if (numberToShow % 2 === 0) {
          setCorrectAnswer((prevState) => prevState + 1);
        }
      }
    }
    updateReactionTimes();
    generateRandomNumbers();
  };

  return (
    <>
      {countAnswer >= gameLength ? (
        <ScoreCard
          gameScore={gameScore}
          showAverageTimeMs={false}
          showCorrectMoves={false}
          showCorrectSequences={false}
          showLenghtOfSequence={false}
          showCorrectnessPercentage={true}
          restartGame={restartGame}
          detailedResults={JSON.stringify(
            new DetailedAnswers(gameScore, answers)
          )}
          setGameStage={undefined}
          gameStage={undefined}
        />
      ) : (
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="text-style mb-3">
            {t(
              "games.mental_shifting.click_left_or_right_based_on_number_shown"
            )}
          </div>
          <div>
            <div className="div-style-number-container">
              <div className="row">
                {rowNumber === 0 && columnNumber === 0 ? (
                  <div className="col bg-info div-style-numbers">
                    {numberToShow}
                  </div>
                ) : (
                  <div className="col bg-gradient div-style-numbers"></div>
                )}
                {rowNumber === 0 && columnNumber === 1 ? (
                  <div className="col bg-info div-style-numbers">
                    {numberToShow}
                  </div>
                ) : (
                  <div className="col bg-gradient"></div>
                )}
                <div className="w-100"></div>
                {rowNumber === 1 && columnNumber === 0 ? (
                  <div className="col even-odd-div-color div-style-numbers">
                    {numberToShow}
                  </div>
                ) : (
                  <div className="col bg-gradient div-style-numbers"></div>
                )}
                {rowNumber === 1 && columnNumber === 1 ? (
                  <div className="col even-odd-div-color div-style-numbers">
                    {numberToShow}
                  </div>
                ) : (
                  <div className="col bg-gradient div-style-numbers"></div>
                )}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-around">
              <div
                onClick={onLeftArrowClicked}
                role="button"
                className="div-button"
              >
                <img
                  className="img-style"
                  src={g6h3}
                  alt="left-arrow"
                  draggable="false"
                />
              </div>
              <div
                onClick={onRightArrowClicked}
                role="button"
                className="div-button"
              >
                <img
                  className="img-style"
                  src={g6h4}
                  alt="right-arrow"
                  draggable="false"
                />
              </div>
            </div>
          </div>
          <div className="">
            <div className="text-style mb-3">
              {t("games.task")}: {countAnswer + 1} / {gameLength}
            </div>
          </div>
          <hr className="hr-style" />
          <div className="text-center ">
            <Link to="/" className="m-3 text-center">
              <Button
                variant="outline-secondary"
                size="lg"
                onClick={() =>
                  handleUpdateAppWithUserWeeklyScheduleGames(
                    updateCurrentUserInfoWithGameSchedule,
                    noSchedule
                  )
                }
              >
                {t("common.close")}
              </Button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Game;

import * as React from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import "./style.css";

import g3h1 from "../../images/G3H1.png";
import g3h2 from "../../images/G3H2.png";
import {handleUpdateAppWithUserWeeklyScheduleGames} from "../../helpers/routes";
import {useUserInfo} from "../../contexts/user-info-context";

const LightUpInstructions = () => {
  const { t } = useTranslation("common");
  const {noSchedule, updateCurrentUserInfoWithGameSchedule} = useUserInfo();

  return (
    <section className="py-5 h-100">
      <div className="container h-100">
        <Card className="card-style">
          <Card.Body>
            <Card.Title className="text-center title-style">{t("games.titles.light_up")}</Card.Title>
            <Card.Text className="introduction-text-style">{t("games.light_up.instructions")}</Card.Text>
            <Row className="mb-5">
              <Col>
                <Card.Title className="text-center m-4 font-size-style">{t("games.memorize")}</Card.Title>
                <Card.Img className="card-image-light" src={`${g3h1}`} />
              </Col>
              <Col>
                <Card.Title className="text-center m-4 font-size-style">{t("games.recall")}</Card.Title>
                <Card.Img className="card-image-light" src={`${g3h2}`} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
      <div className="text-center mt-5">
        <Link to="/" className="m-3 text-center">
          <Button variant="outline-secondary" size="lg" onClick={() => handleUpdateAppWithUserWeeklyScheduleGames(updateCurrentUserInfoWithGameSchedule, noSchedule)}>
            {t("common.close")}
          </Button>
        </Link>
        <Link to="/light-up" state={{ level: 3 }} className="m-3 text-center">
          <Button className="m-3 text-center" variant="outline-secondary" size="lg">
            {t("common.play")}
          </Button>
        </Link>
      </div>
    </section>
  );
};

export default LightUpInstructions;

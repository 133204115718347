import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUserInfo } from "../../contexts/user-info-context";
import { useUserInformation } from "../../hooks/user-info";
import { useTranslation } from "react-i18next";

type LoginStage = "Waiting" | "Processing" | "Failed" | "Success";

export function LetheLogin() {
  const { t } = useTranslation("common");
  const { token } = useParams();
  const navigate = useNavigate();
  const userInfo = useUserInformation();
  const { currentUserInfo, scheduleGameForUser } = useUserInfo();
  const [loginStage, setloginStage] = useState<LoginStage>("Waiting");

  useEffect(() => {
    navigate("/", { replace: true });

    // if (localStorage.getItem("loginSuccessful") === "true") {
    //   console.log("Logged in, redirect to main");
    //   navigate("/");
    // } else if (localStorage.getItem("loginSuccessful") != "true" && loginStage == "Processing") {
    //   console.log("login failed");
    //   setloginStage("Failed");
    // }
    // console.log("nothing", localStorage.getItem("loginSuccessful"), loginStage);
  }, [loginStage]);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setloginStage("Processing");
  //     console.log("waiting...");
  //   }, 2000);
  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, []);

  return <div>{loginStage === "Failed" && <h2>{t("common.return_to_lethe")}</h2>}</div>;
}

import * as React from "react";
import { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import g5L from "../../images/G5L.png";
import g5N from "../../images/G5N.png";
import g5C from "../../images/G5C.png";

import g5D1 from "../../images/G5LD1.png";
import g5D2 from "../../images/G5LD2.png";
import g5D3 from "../../images/G5LD3.png";
import { getRandomNumberBetween } from "../../helpers/updating-items-helpers";

import { Button } from "react-bootstrap";

const BUTTONS_GAME_CATEGORY = [
  { id: 0, category: "number", image: g5N },
  { id: 1, category: "alphabet", image: g5L },
  { id: 2, category: "color", image: g5C },
  // {id: 3, category:'spatial', image: g5P}
];

export const BUTTONS_GAME_LEVEL = [
  { difficultyLevel: getRandomNumberBetween(4, 7), title: "easy", level: 1, image: g5D1 },
  { difficultyLevel: getRandomNumberBetween(5, 11), title: "medium", level: 2, image: g5D2 },
  { difficultyLevel: getRandomNumberBetween(6, 15), title: "hard", level: 3, image: g5D3 },
];

const ChooseCategoryAndLevel: React.FunctionComponent = () => {
  const { type } = useParams();
  const [gameCategorySelectedButton, setGameCategorySelectedButton] = useState<number[]>([]);
  const [gameLevelSelectedButton, setGameLevelSelectedButton] = useState<number[]>([]);
  const { t } = useTranslation("common");

  const [selectedGameCriterion, setSelectedGameCriterion] = useState<{ category: string; level: number; difficultyLevel: number }>({
    category: "",
    level: 0,
    difficultyLevel: 0,
  });

  const [start, setStart] = useState<boolean>(false);

  const handleGameCategoryButton = (buttonId: number, category: string) => {
    setGameCategorySelectedButton((prevState) => {
      if (prevState.includes(buttonId)) {
        return prevState.filter((el) => el !== buttonId);
      }
      return [buttonId];
    });
    setSelectedGameCriterion({
      ...selectedGameCriterion,
      category: category,
    });
  };

  const handleGameLevelButton = (difficultyLevel: number, buttonTittle: string, level: number) => {
    setGameLevelSelectedButton((prevState) => {
      if (prevState.includes(difficultyLevel)) {
        return prevState.filter((el) => el !== difficultyLevel);
      }
      return [difficultyLevel];
    });
    setSelectedGameCriterion({
      ...selectedGameCriterion,
      level: level,
      difficultyLevel: difficultyLevel,
    });
  };

  useEffect(() => {}, [selectedGameCriterion]);

  const onStart = () => {
    setStart(true);
  };
  const goToGameLink = () => {
    return (
      <>
        <Link to={`/updating-items/${selectedGameCriterion.category}`}>
          <div className="d-grid gap-2 mt-5">
            <Button variant="primary" size="lg" onClick={() => onStart()}>
              {t("common.play")}
            </Button>
          </div>
        </Link>
      </>
    );
  };

  return <Fragment></Fragment>;
};

export default ChooseCategoryAndLevel;

import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { HashRouter, Route, Routes, useNavigate } from "react-router-dom";
import SignUp from "./pages/signup";
import MentalSetShifting from "./pages/mental-set-shifting";
import UpdatingItems from "./pages/updating-items";

import EpisodicMemorySpatialSpeed from "./pages/episodic-memory-spatial-speed";
import EpisodicMemoryRelational from "./pages/episodic-memory-relational";
import Introduction from "./pages/home/introduction";
import MentalSetShiftingInstructions from "./pages/mental-set-shifting/mental-set-shifting-instructions";
import { useTranslation } from "react-i18next";
import { i18n } from "./i18n/i18n";
import { useUserInfo } from "./contexts/user-info-context";
import LightUp from "./pages/light-up";
import MemorySpatialSpeedInstructions from "./pages/episodic-memory-spatial-speed/memory-spatial-speed-instructions";
import MemoryRelationalInstructions from "./pages/episodic-memory-relational/memory-relational-instructions";
import ShapeMatchMentalSpeedInstructions from "./pages/shape-match-mental-speed/shape-match-mental-speed-instructions";
import LightUpInstructions from "./pages/light-up/light-up-instructions-instructions";
import LightUpSelectLevel from "./pages/light-up/light-up-instructions-select-level";
import ShapeMatchMentalSpeed from "./pages/shape-match-mental-speed";
import GamingStatistics from "./pages/gaming-stats";
import MainPage from "./pages/main-page";
import ScheduleGamePage from "./pages/schedule-game-page";
import { ActiveTabProvider } from "./contexts/active-tab-context";
import { LetheLogin } from "./pages/lethe-logins";
import { UserDataForGamesProvider } from "./contexts/user-data-for-game-context";
import IdleTimer from "./helpers/idle-timer";

export const App: React.FC = () => {
  const { isLoading, logout, isAuthenticated } = useAuth0();
  const { t } = useTranslation();

  const { currentUserInfo, scheduleGameForUser } = useUserInfo();

  const [isTimeOut, setIsTimeOut] = useState(false);
  // expire after 900 seconds (15 minutes)
  const timeToExpired = 900;

  useEffect(() => {
    const timer = new IdleTimer(timeToExpired, () => {
      setIsTimeOut(true);
    });
    return () => {
      timer.cleanUp();
    };
  }, []);

  useEffect(() => {
    if (currentUserInfo !== null) {
      i18n
        .changeLanguage(currentUserInfo.languageCulture.languageCode)
        .then((r) => r);
    }
  }, [currentUserInfo]);

  if (isTimeOut) {
    // if already logged out, don't logout again
    if (isAuthenticated) {
      localStorage.clear();
      localStorage.setItem("loginSuccessful", "false");
      logout({ returnTo: window.location.origin });
      //logout();
    }
  }

  return (
    <UserDataForGamesProvider>
      <ActiveTabProvider>
        <HashRouter>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/schedule-game" element={<ScheduleGamePage />} />
            {/*<Route path="/home-page" element={<HomePage />} />*/}

            <Route path="/introduction" element={<Introduction />} />
            <Route path="/gaming-stats" element={<GamingStatistics />} />

            <Route path="/signup" element={<SignUp />} />

            <Route
              path="/mental-set-shifting"
              element={<MentalSetShifting />}
            />
            <Route
              path="/mental-set-shifting-instructions"
              element={<MentalSetShiftingInstructions />}
            />

            <Route path="/light-up" element={<LightUp />} />
            <Route
              path="/light-up-instructions"
              element={<LightUpInstructions />}
            />
            <Route
              path="/light-up-select-level"
              element={<LightUpSelectLevel />}
            />

            <Route path="/updating-items" element={<UpdatingItems />} />

            <Route
              path="/episodic-memory-spatial-speed"
              element={<EpisodicMemorySpatialSpeed />}
            />
            <Route
              path="/episodic-memory-spatial-speed-instructions"
              element={<MemorySpatialSpeedInstructions />}
            />

            <Route
              path="/episodic-memory-relational"
              element={<EpisodicMemoryRelational />}
            />
            <Route
              path="/episodic-memory-relational-instructions"
              element={<MemoryRelationalInstructions />}
            />

            <Route
              path="/shape-match-mental-speed"
              element={<ShapeMatchMentalSpeed />}
            />
            <Route
              path="/shape-match-mental-speed-instructions"
              element={<ShapeMatchMentalSpeedInstructions />}
            />

            <Route path="/lethe-login/:token" element={<LetheLogin />} />
            {/* <Route path="/lethe/:email" element={<UpdatingItemsInstructions/>}/> */}
          </Routes>
        </HashRouter>
      </ActiveTabProvider>
    </UserDataForGamesProvider>
  );
};

import * as React from "react";
import { Fragment, useCallback, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

import g5Lh1 from "../../images/G5LH1.png";
import g5Lh2 from "../../images/G5LH2.png";
import g5Nh1 from "../../images/G5NH1.png";
import g5Nh2 from "../../images/G5NH2.png";
import g5Ch1 from "../../images/G5CH1.png";
import g5Ch2 from "../../images/G5CH2.png";
import g5Ph1 from "../../images/G5PH1.png";
import g5Ph2 from "../../images/G5PH2.png";

import { Col, Row } from "react-bootstrap";
import { GameType } from "../../models/game-type";

import "./style.css";
import {handleUpdateAppWithUserWeeklyScheduleGames} from "../../helpers/routes";
import {useUserInfo} from "../../contexts/user-info-context";

interface Props {
  title: string;
  gameType: GameType;
  setChooseLevel: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdatingItemsInstructions = (props: Props) => {
  const { t } = useTranslation("common");
  const {noSchedule, updateCurrentUserInfoWithGameSchedule} = useUserInfo();

  const { title, gameType, setChooseLevel } = props;

  //const [contextCategory, setContextCategory] = useState(category);
  // const [contextHandleOpenGameWindow, setContextHandleOpenGameWindow] = useState(handleOpenGameWindow);

  const [categoryImage, setCategoryImage] = useState<any[]>([]);

  const setImageOfGameType = (type: GameType) => {
    switch (type) {
      case "UpdatingNumber": {
        setCategoryImage([g5Nh1, g5Nh2]);
        break;
      }
      case "UpdatingLetter": {
        setCategoryImage([g5Lh1, g5Lh2]);
        break;
      }
      case "UpdatingColor": {
        setCategoryImage([g5Ch1, g5Ch2]);
        break;
      }
      case "UpdatingSpatial": {
        setCategoryImage([g5Ph1, g5Ph2]);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleChooseLevel = useCallback(() => {
    setChooseLevel(true);
  }, []);

  useEffect(() => {
    setImageOfGameType(gameType);
  }, []);

  return (
    <Fragment>
      <div className="container h-100">
        <Card className="card-style">
          <Card.Body>
            <Card.Title className="text-center title-style">{t("common.game_types." + gameType)}</Card.Title>
            <Card.Text className="introduction-text-style">
              {t("games.updating_items.instructions_overall", {
                items: t("common.item_type_from_game_type_partitive." + gameType),
                items_plural_partitive: t("common.item_type_from_game_type_plural_partitive." + gameType),
              })}
            </Card.Text>
            <Row className="mb-5">
              <Col>
                <Card.Title className="text-center m-4 font-size-style">{t("games.memorize")}</Card.Title>
                <Card.Img className="card-image-update-items" src={`${categoryImage[0]}`} />
              </Col>
              <Col>
                <Card.Title className="text-center m-4 font-size-style">{t("games.recall")}</Card.Title>
                <Card.Img className="card-image-update-items" src={`${categoryImage[1]}`} />
              </Col>
            </Row>
            <Card.Text className="introduction-text-style">{t("games.instructions_difficulty")}</Card.Text>
            <Card.Text className="introduction-text-style">{t("games.updating_items.instructions_length", { number: 6 })}</Card.Text>
          </Card.Body>
        </Card>
      </div>
      <div className="text-center mt-5">
        <Link to="/" className="m-3 text-center">
          <Button variant="outline-secondary" size="lg"  onClick={() => handleUpdateAppWithUserWeeklyScheduleGames(updateCurrentUserInfoWithGameSchedule, noSchedule)}>
            {t("common.close")}
          </Button>
        </Link>
        <Link to="/updating-items" state={{ gameType, title }} className="m-3 text-center">
          <Button className="m-3 text-center" variant="outline-secondary" size="lg" onClick={() => handleChooseLevel()}>
            {t("common.play")}
          </Button>
        </Link>
      </div>
    </Fragment>
  );
};

export default UpdatingItemsInstructions;

import * as React from "react";
import Card from "react-bootstrap/Card";
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router";
import {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";

import {NUMBER_OF_SEQUENCES} from "./index";
import { i18n } from "../../i18n/i18n";

import {handleUpdateAppWithUserWeeklyScheduleGames} from "../../helpers/routes";

import g4h1 from "../../images/G4H1.png";
import g4h2_eng from "../../images/G4H2-ENG.png";
import g4h2_fin from "../../images/G4H2-FIN.png";
import g4h2_ger from "../../images/G4H2-GER.png";
import g4h2_ita from "../../images/G4H2-ITA.png";
import g4h2_swe from "../../images/G4H2-SWE.png";

import "./style.css";
import {useUserInfo} from "../../contexts/user-info-context";

const ShapeMatchMentalSpeedInstructions = () => {
    const {t} = useTranslation("common");
    const location: any = useLocation();
    const {noSchedule, updateCurrentUserInfoWithGameSchedule} = useUserInfo();

    const [imageByLanguage, setImageByLanguage] = useState<string[]>([]);

    useEffect(() => {
        switch(i18n.language) {
            case "fi" : {
                setImageByLanguage([g4h1, g4h2_fin]);
                break;
            }
            case "sv": {
                setImageByLanguage([g4h1, g4h2_swe]);
                break;
            }
            case "it": {
                setImageByLanguage([g4h1, g4h2_ita]);
                break;
            }
            case "de": {
                setImageByLanguage([g4h1, g4h2_ger]);
                break;
            }
            default: {
                setImageByLanguage([g4h1, g4h2_eng]);
                break;
            }
        }
    }, [i18n.language]);

    return (
        <section className="py-5 h-100">
            <div className="container h-100">
                <Card className="card-style">
                    <Card.Body>
                        <Card.Title className="text-center title-style">{t("games.titles.shape_match")}</Card.Title>
                        <Card.Text className="introduction-text-style">{t("games.shape-matching.instructions")}</Card.Text>
                        <Row className="mb-5">
                            <Col>
                                <Card.Title className="text-center m-4 font-size-style">{t("games.shape-matching.instructions_previous")}</Card.Title>
                                <Card.Img className="card-image-shape" src={`${imageByLanguage[0]}`}/>
                            </Col>
                            <Col>
                                <Card.Title className="text-center m-4 font-size-style">{t("games.shape-matching.instructions_current")}</Card.Title>
                                <Card.Img className="card-image-shape" src={`${imageByLanguage[1]}`}/>
                            </Col>
                        </Row>
                        <Card.Text className="introduction-text-style">{t("games.instructions_difficulty", {number_of_tasks: NUMBER_OF_SEQUENCES})}</Card.Text>
                    </Card.Body>
                </Card>
            </div>
            <div className="text-center mt-5">
                <Link to="/" className="m-3 text-center">
                    <Button variant="outline-secondary" size="lg" onClick={() => handleUpdateAppWithUserWeeklyScheduleGames(updateCurrentUserInfoWithGameSchedule, noSchedule)}>
                        {t("common.close")}
                    </Button>
                </Link>
                <Link to="/shape-match-mental-speed" state={{isSchedule: location.state.isSchedule}} className="m-3 text-center">
                    <Button className="m-3 text-center" variant="outline-secondary" size="lg">
                        {t("common.play")}
                    </Button>
                </Link>
            </div>
        </section>
    );
};

export default ShapeMatchMentalSpeedInstructions;

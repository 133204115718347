import * as React from "react";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import GameScore from "../../models/game-result";
import { GameStage } from "../../models/game-stage";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ScorecardResultsTable from "../../components/scorecard-results-table";
import { useUserInfo } from "../../contexts/user-info-context";
import {
  loadGameResultsFromBackend,
  postGameDetails,
} from "../../helpers/routes";
import { useUserDataForGame } from "../../contexts/user-data-for-game-context";

type props = {
  // what to show?
  showCorrectSequences: boolean;
  showCorrectnessPercentage: boolean;
  showLenghtOfSequence: boolean;
  showAverageTimeMs: boolean;
  showCorrectMoves?: boolean;
  //
  gameScore: GameScore;
  detailedResults?: string;
  gameStage?: GameStage;
  setGameStage?: React.Dispatch<React.SetStateAction<GameStage>>;
  restartGame: () => void;
};

const ScoreCard: React.FC<props> = (props): JSX.Element => {
  const tokenFromOutsideUrl = process.env.REACT_APP_TOKEN;

  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { t } = useTranslation("common");
  const { currentUserInfo, updateCurrentUserInfo } = useUserInfo();
  const { userDataForGames, updateUserDataForGames } = useUserDataForGame();

  const { gameScore } = props;
  const [gameResults, setGameResults] = useState<GameScore[]>([]);

  const [showResult, setShowResult] = useState<boolean>(false);

  const handleSubmitResult = async () => {
    //console.log("submitting results");
    try {
      if (props.gameStage !== undefined) {
        props.setGameStage!("ScoreSent");
      }
      const token = !isAuthenticated
        ? localStorage.getItem(process.env.REACT_APP_TOKEN_NAME!)
        : await getAccessTokenSilently();
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(gameScore),
      };
      const response = await fetch(
        process.env.REACT_APP_API_SERVER_URL + `/games/score`,
        requestOptions
      );
    } catch (err) {
      console.log(err);
    }

    setShowResult(true);
    await loadGameResults();
    //console.log("posting game detals", props.detailedResults);
    if (props.detailedResults !== undefined) {
      await postDetails();
    }
  };

  const loadGameResults = useCallback(async () => {
    const token = !isAuthenticated
      ? localStorage.getItem(process.env.REACT_APP_TOKEN_NAME!)
      : await getAccessTokenSilently();
    let results = await loadGameResultsFromBackend(
      token,
      gameScore.gameType,
      currentUserInfo.id,
      gameScore.level!
    );
    setGameResults(results);
  }, []);

  const handleGameStart = useCallback(() => {
    props.restartGame();
    updateUserDataForGames!();
    updateCurrentUserInfo!();
  }, []);

  const handleGameDataUpdate = () => {
    updateCurrentUserInfo!();
    updateUserDataForGames!();
  };

  const postDetails = useCallback(async () => {
    //const token = await getAccessTokenSilently();
    const token = !isAuthenticated
      ? localStorage.getItem(process.env.REACT_APP_TOKEN_NAME!)
      : await getAccessTokenSilently();

    let ok = await postGameDetails(
      token,
      gameScore.gameType,
      currentUserInfo.id,
      props.detailedResults!
    );
    //console.log("details posted", gameResults, ok, props.detailedResults);
  }, [props.detailedResults]);

  useEffect(() => {
    if (
      gameScore.totalTimeSeconds > 0 &&
      (props.gameStage === undefined || props.gameStage === "ShowScore")
    ) {
      (async () => {
        await handleSubmitResult();
      })();
    }
  }, [gameScore]);

  return (
    <div className="mt-4">
      {showResult ? (
        <Fragment>
          <section className="vh-100">
            <div className="container h-100">
              <div className="d-flex flex-column align-items-center justify-content-between">
                <div>
                  <h2
                    className="mb-5 d-flex justify-content-center"
                    style={{ color: "#4473AF" }}
                  >
                    {t("common.game_types." + gameScore.gameType)}
                  </h2>
                  <h1 className="mb-5 d-flex justify-content-center">
                    {t("games.score_card.your_score")}: {gameScore.score}{" "}
                  </h1>
                  {props.showLenghtOfSequence ? (
                    <h3 className="mb-5 d-flex justify-content-center">
                      {t("games.score_card.lenght_of_sequence")}:{" "}
                      {gameScore.correctSequences}{" "}
                    </h3>
                  ) : null}
                  {props.showCorrectnessPercentage ? (
                    <h3 className="mb-5 d-flex justify-content-center">
                      {t("games.score_card.correct_answers")}:{" "}
                      {gameScore.percentage}
                      {" %"}
                    </h3>
                  ) : null}
                  {props.showCorrectMoves ? (
                    <h3 className="mb-5 d-flex justify-content-center">
                      {t("games.score_card.correct_answers")}:{" "}
                      {gameScore.correctItems}
                    </h3>
                  ) : null}
                  <h3 className="mb-5 d-flex justify-content-center">
                    {t("games.score_card.total_time")}:{" "}
                    {gameScore.totalTimeSeconds}
                    {" s"}
                  </h3>
                  {props.showAverageTimeMs ? (
                    <h3 className="mb-5 d-flex justify-content-center">
                      {t("games.score_card.average_reaction_time_ms")}:{" "}
                      {gameScore.averageTimeMs}
                      {" ms"}
                    </h3>
                  ) : null}
                  {props.showCorrectSequences ? (
                    <h3 className="mb-5 d-flex justify-content-center">
                      {t("games.score_card.sequences")}:{" "}
                      {gameScore.correctSequences} /{" "}
                      {gameScore.numberOfSequencers}{" "}
                    </h3>
                  ) : null}
                </div>
                <ScorecardResultsTable
                  gameResults={gameResults}
                  currentResult={gameScore}
                  showCorrectMoves={props.showCorrectMoves}
                  showCorrectnessPercentage={props.showCorrectnessPercentage}
                />
                <div className="text-center">
                  <Link to="/" className="m-3 text-center">
                    <Button
                      className="m-3 text-center"
                      variant="outline-secondary"
                      size="lg"
                      onClick={handleGameDataUpdate}
                    >
                      {t("common.close")}
                    </Button>
                  </Link>
                  <Button
                    className="m-3 text-center"
                    variant="outline-secondary"
                    size="lg"
                    onClick={() => handleGameStart()}
                  >
                    {t("common.play")}
                  </Button>
                </div>
              </div>
            </div>
          </section>
        </Fragment>
      ) : (
        ""
      )}
    </div>
  );
};

export default ScoreCard;

import * as React from "react";
import {useEffect, useState} from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router";

import { i18n } from "../../i18n/i18n";

import g1H1_eng from "../../images/G1H1-ENG.png";
import g1H1_fin from "../../images/G1H1-FIN.png";
import g1H1_ger from "../../images/G1H1-GER.png";
import g1H1_ita from "../../images/G1H1-ITA.png";
import g1H1_swe from "../../images/G1H1-SWE.png";

import g1h2_eng from "../../images/G1H2-ENG.png"
import g1h2_fin from "../../images/G1H2-FIN.png"
import g1h2_ger from "../../images/G1H2-GER.png"
import g1h2_ita from "../../images/G1H2-ITA.png"
import g1h2_swe from "../../images/G1H2-SWE.png"

import "./style.css";
import {useUserInfo} from "../../contexts/user-info-context";
import {useUserDataForGame} from "../../contexts/user-data-for-game-context";
import {handleUpdateAppWithUserWeeklyScheduleGames} from "../../helpers/routes";


const MemoryRelationalInstructions = () => {
  const { t } = useTranslation("common");
  const location: any = useLocation();
  
  const [imageByLanguage, setImageByLanguage] = useState<string[]>([]);
  const {updateCurrentUserInfoWithGameSchedule, noSchedule} = useUserInfo();
  const { userDataForGames, updateUserDataForGames } = useUserDataForGame();
  
  useEffect(() => {
      switch(i18n.language) {
        case "fi" : {
          setImageByLanguage([g1H1_fin, g1h2_fin]);
          break;
        }
        case "sv": {
          setImageByLanguage([g1H1_swe, g1h2_swe]);
          break;
        }
        case "it": {
          setImageByLanguage([g1H1_ita, g1h2_ita]);
          break;
        }
        case "de": {
          setImageByLanguage([g1H1_ger, g1h2_ger]);
          break;
        }
        default: {
          setImageByLanguage([g1H1_eng, g1h2_eng]);
          break;
        }
      }
  }, [i18n.language]);

  return (
    <section className="py-5 h-100">
      <div className="container h-100">
        <Card className="card-style">
          <Card.Body>
            <Card.Title className="text-center mb-4 title-style">{t("common.game_types.EpisodicMemoryRelational")}</Card.Title>
            <Card.Text className="introduction-text-style">{t("games.triplets.instructions")}</Card.Text>
            <Row className="mb-5">
              <Col>
                <Card.Title className="text-center m-4 font-size-style">{t("games.memorize")}</Card.Title>
                <Card.Img className="card-image-top" src={`${imageByLanguage[0]}`} />
              </Col>
              <Col>
                <Card.Title className="text-center m-4 font-size-style">{t("games.recall")}</Card.Title>
                <Card.Img className="card-image-top" src={`${imageByLanguage[1]}`} />
              </Col>
            </Row>
            <Card.Text className="introduction-text-style">{t("games.instructions_difficulty")}</Card.Text>
          </Card.Body>
        </Card>
      </div>
      <div className="text-center mt-5">
        <Link to="/" className="m-3 text-center">
          <Button variant="outline-secondary" size="lg" onClick={() => handleUpdateAppWithUserWeeklyScheduleGames(updateCurrentUserInfoWithGameSchedule, noSchedule)}>
            {t("common.close")}
          </Button>
        </Link>
        <Link to="/episodic-memory-relational" state={{ isSchedule: location.state.isSchedule }} className="m-3 text-center">
          <Button className="m-3 text-center" variant="outline-secondary" size="lg">
            {t("common.play")}
          </Button>
        </Link>
      </div>
    </section>
  );
};

export default MemoryRelationalInstructions;

import { Auth0Provider } from "@auth0/auth0-react";
import React, { PropsWithChildren } from "react";

interface Auth0ProviderWithConfigProps {
  children: React.ReactNode;
}

export const Auth0ProviderWithConfig = ({ children }: PropsWithChildren<Auth0ProviderWithConfigProps>): JSX.Element | null => {
  const domain: string | undefined = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId: string | undefined = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience: string | undefined = process.env.REACT_APP_AUTH0_AUDIENCE;
  const apiServerUrl: string | undefined = process.env.REACT_APP_API_SERVER_URL;

  if (!(domain && clientId && audience)) {
    throw new Error("Missing environment variables.");
  }

  return (
    <Auth0Provider domain={domain} clientId={clientId} audience={audience} redirectUri={window.location.origin} useRefreshTokens={true}>
      {children}
    </Auth0Provider>
  );
};

import * as React from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./style.css";

import g3d1 from "../../images/G3_D1.png";
import g3d2 from "../../images/G3_D2.png";
import g3d3 from "../../images/G3_D3.png";
import {handleUpdateAppWithUserWeeklyScheduleGames} from "../../helpers/routes";
import {useUserInfo} from "../../contexts/user-info-context";

const BUTTONS_GAME_LEVEL = [
  { id: 1, title: "games.difficulty_level.easy", level: 1, image: g3d1 },
  { id: 2, title: "games.difficulty_level.intermediate", level: 2, image: g3d2 },
  { id: 3, title: "games.difficulty_level.hard", level: 3, image: g3d3 },
];

const LightUpSelectLevel = () => {
  const { t } = useTranslation("common");
  const {noSchedule, updateCurrentUserInfoWithGameSchedule} = useUserInfo();

  return (
    <section className="py-5 h-100">
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="container py-5 h-100">
          <Link to="/shape-match-mental-speed-instructions" className="link-style">
            <FontAwesomeIcon className="float-end" icon={faCircleQuestion} size="2x" />
          </Link>
        </div>
        <h2 className="text-center mb-4">{t("games.select_difficulty_level")}:</h2>
        <div className="container h-100">
          {/*<h3 className="mb-5 d-flex justify-content-center" style={{ color: "blue" }}>*/}
          {/*</h3>*/}
          <Row xs={2} md={3} className="g-4">
            {BUTTONS_GAME_LEVEL.map((button, index) => (
              <Col key={index}>
                <Link className="card-style" to="/light-up" state={{ level: button.level }} role="button">
                  <Card.Img variant="top" className="image-style-shape" src={`${button.image}`} />
                  <Card.Title className="text-center font-style">{t(button.title)}</Card.Title>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
        <hr className="hr-style" />
        <div className="text-center mt-5">
          <Link to="/" className="m-3 text-center">
            <Button variant="outline-secondary" size="lg"  onClick={() => handleUpdateAppWithUserWeeklyScheduleGames(updateCurrentUserInfoWithGameSchedule, noSchedule)}>
              {t("common.close")}
            </Button>
          </Link>
        </div>
      </div>{" "}
    </section>
  );
};

export default LightUpSelectLevel;

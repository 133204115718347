import { GameType } from "./game-type";

export class UserDataForGame {
  userEmail: string;
  userId: number;
  gameType: GameType;
  levelAchieved: number;
  levelPreferred?: number;
  levelLastPlayed?: number;

  constructor(dto: any) {
    this.userEmail = dto.userEmail;
    this.userId = dto.userId;
    this.gameType = dto.gameType;
    this.levelAchieved = dto.levelAchieved;
    this.levelLastPlayed = dto.levelLastPlayed;
    this.levelPreferred = dto.levelPreferred;
  }
}

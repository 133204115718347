import * as React from "react";
import { createContext, useContext } from "react";
import { User } from "../models/user";
import { useUserInformation } from "../hooks/user-info";
import GameSchedule from "../models/game-schedule";

interface UserInfoInterface {
  currentUserInfo: any;
  scheduleGameForUser: GameSchedule | null;
  goToSignUpPage: boolean;
  updateCurrentUserInfo?: () => void;
  updateCurrentUserInfoWithGameSchedule?: () => void;
  scheduleCompleted: boolean;
  noSchedule: boolean;
}

const UserInfoContext = createContext<UserInfoInterface>({
  currentUserInfo: null,
  scheduleGameForUser: null,
  goToSignUpPage: false,
  scheduleCompleted: false,
  noSchedule: false,
});

export const UserInfoProvider = (props: {
  user?: User;
  schedule?: GameSchedule;
  goToSignUpPage?: boolean;
  children: JSX.Element | JSX.Element[];
}) => {
  const [
    currentUserInfo,
    scheduleGameForUser,
    goToSignUpPage,
    fetchCurrentUserInfo,
    fetchUserInfoWithGameSchedule,
    scheduleCompleted,
    noSchedule,
  ] = useUserInformation();
  // const currentUserInfo = useUserInformation();
  // const scheduleGameForUser = useUserScheduleGame();

  const updateCurrentUserInfo = () => {
    fetchCurrentUserInfo();
  };
  const updateCurrentUserInfoWithGameSchedule = () => {
    fetchUserInfoWithGameSchedule();
  };

  return (
    <UserInfoContext.Provider
      value={{
        currentUserInfo,
        scheduleGameForUser,
        goToSignUpPage,
        updateCurrentUserInfo,
        updateCurrentUserInfoWithGameSchedule,
        scheduleCompleted,
        noSchedule,
      }}
    >
      {props.children}
    </UserInfoContext.Provider>
  );
};

export const useUserInfo = () =>
  useContext(UserInfoContext) as UserInfoInterface;

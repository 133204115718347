import React, { Fragment } from "react";
import HomeNavbar from "../components/navbar";

interface Props {
  children: React.ReactNode;
}

export const PageLayout: React.FC<Props> = ({ children }) => {
  return (
    <div className="page-layout">
      <Fragment>
        <HomeNavbar />
        <div className="page-layout__content">{children}</div>
        {/*<Footer/>*/}
      </Fragment>
    </div>
  );
};

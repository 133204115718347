import * as React from "react";
import { Fragment, useState } from "react";

import "./style.css";

import CountDown from "../../components/count-down";
import Game from "./game";

const MentalSetShifting: React.FunctionComponent = () => {
  const [start, setStart] = useState<boolean>(true);
  const [counter, setCounter] = useState<number>(3);

  if (start) {
    return <CountDown counter={counter} setCounter={setCounter} setStart={setStart} />;
  }

  return (
    <Fragment>
      <div className="container py-5 h-100">
        <section className="vh-100">
          <div>{counter === 0 ? <Game setCounter={setCounter} setStart={setStart} /> : null}</div>
        </section>
      </div>
    </Fragment>
  );
};

export default MentalSetShifting;

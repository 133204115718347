import { GameType } from "./game-type";

export default class GameScore {
  email: string;
  userId: number;
  level?: number | undefined;
  maxLevel?: number | undefined;
  score: number;
  maxScore?: number | undefined;
  correctItems: number;
  correctSequences?: number | undefined;
  numberOfItems?: number | undefined;
  numberOfSequencers?: number | undefined;
  totalTimeSeconds: number;
  averageTimeMs?: number | undefined;
  isScheduledGame: boolean;
  gameType: GameType;
  createdAt?: Date | undefined;
  percentage: number | undefined;

  constructor(dto: any) {
    this.email = dto.email;
    this.userId = dto.userId;
    this.score = dto.score;
    this.level = dto.level;
    this.correctItems = dto.correctItems;
    this.numberOfItems = dto.numberOfItems;
    this.correctItems = dto.correctItems;
    this.totalTimeSeconds = dto.totalTimeSeconds;
    this.isScheduledGame = dto.isScheduledGame;
    this.gameType = dto.gameType;
    this.createdAt = new Date(dto.createdAt);
    if (dto.percentage) {
      this.percentage = dto.percentage;
    }
  }
}

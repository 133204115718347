import {TripletsColor, TripletsItem} from "../models/triplets";
import {ActiveTab} from "../models/active-tab";

import g1d1 from "../images/G1D11.png";
import g1d2 from "../images/G1D31.png";
import g1d3 from "../images/G1D12.png";
import g1d4 from "../images/G1D32.png";
import g1d5 from "../images/G1D23.png";
import g1d6 from "../images/G1D33.png";

import {GameLevelsData} from "../models/game-level-data";

export interface Triplet {
  tripletsShown?: TripletShown;
  item: string;
  color: TripletsColor;
  referenceItem: string;
  answeringTimeMS: number;
  score: number;
  correctColor: boolean;
  correctItem: boolean;
  colorChoices: string[];
  referenceItemChoices: string[];
}

export interface Question {
  item: string;
  color: TripletsColor[];
  referenceItem: string[];
}

export interface TripletShown {
  item: string;
  referenceItem: string;
  color: TripletsColor;
}

export const getRandomTriplets = (length: number, colors: TripletsColor[], items: TripletsItem[], referenceItems: TripletsItem[]): Triplet[] => {
  let object: Triplet[] = [];

  let itemArray = items.map((x) => x.translation);
  //console.log("Item array 1: ", itemArray)
  let referenceItemArray = referenceItems.map((x) => x.translation);
  //console.log("reference Item Array 1: ", referenceItemArray)

  for (let index = 0; index < length; index++) {
    let randomColor = colors[Math.floor(Math.random() * colors.length)];

    let itemIndex = Math.floor(Math.random() * itemArray.length);
    let randomItem = itemArray[itemIndex];
    itemArray.splice(itemIndex, 1);

    //console.log("Item array 2: ", itemArray)

    let referenceItemIndex = Math.floor(Math.random() * referenceItemArray.length);
    let randomReferenceItem = referenceItemArray[referenceItemIndex];
    referenceItemArray.splice(referenceItemIndex, 1);
    //console.log("reference Item Array 2: ", referenceItemArray)

    object.push({
      color: randomColor,
      item: randomItem,
      referenceItem: randomReferenceItem,
      answeringTimeMS: 0,
      score: 0,
      correctColor: false,
      correctItem: false,
      colorChoices: [],
      referenceItemChoices: [],
    });
  }
  return object;
};

export const getRandomColors = (length: number, colors: TripletsColor[], color: TripletsColor): TripletsColor[] => {
  const arr = new Array<TripletsColor>();
  arr.push(color);
  let shuffled = shuffleArray(Array.from(colors));

  shuffled.splice(
    shuffled.findIndex((x) => x.color === color.color),
    1
  );

  for (let i = 0; i + 1 < length && i < shuffled.length; ++i) {
    arr.push(shuffled[i]);
  }
  return arr;
};

export const getRandomRefItems = (length: number, referenceItems: string[], refItem: string): string[] => {
  const set: Set<string> = new Set();
  set.add(refItem);
  let arr = shuffleArray(referenceItems);
  for (let i = 0; i < arr.length && set.size < length; ++i) {
    set.add(referenceItems[i]);
    // if (referenceItems[i] !== refItem) {
    //   set.add(referenceItems[i]);
    // }
  }
  return Array.from(set);
};

export const shuffleArray = (array: any[]): any[] => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};

export const compareObjects = (obj1: Triplet, obj2: Triplet) => {
  return obj1 !== undefined && obj2 !== undefined && obj1.item === obj2.item
    ? Number(obj1.color.color === obj2.color.color) + Number(obj1.referenceItem === obj2.referenceItem)
    : 0;
};

export const getQuestionSet = (
  length: number,
  numberOfOptions: number,
  randomTriplets: Triplet[],
  colorsDefs: TripletsColor[],
  referenceItems: string[]
): Question[] => {
  let questionSet: Question[] = [];

  for (let index = 0; index < length; index++) {
    let color = randomTriplets[index].color;
    let randomColors = getRandomColors(numberOfOptions, colorsDefs, color);

    let refItem = randomTriplets[index].referenceItem;
    let randomRefItems = getRandomRefItems(numberOfOptions, referenceItems, refItem);

    questionSet.push({
      item: randomTriplets[index].item,
      color: shuffleArray(randomColors),
      referenceItem: shuffleArray(randomRefItems),
    });
  }
  return questionSet;
};

export const getGameLevelData = (activeTab: ActiveTab, levelAchieved: number) => {
  return [
    { id: 1, title: "games.difficulty_level.beginner", level: 1, image: g1d1, time_ms: 10000, items: 4, options: 4, active: true },
    {
      id: 2,
      title: "games.difficulty_level.easy",
      level: 2,
      image: g1d2,
      time_ms: 6000,
      items: 4,
      options: 4,
      active: activeTab === "Practicing" ? true : levelAchieved >= 2,
    },
    {
      id: 3,
      title: "games.difficulty_level.medium",
      level: 3,
      image: g1d3,
      time_ms: 10000,
      items: 8,
      options: 4,
      active: activeTab === "Practicing" ? true : levelAchieved >= 3,
    },
    {
      id: 4,
      title: "games.difficulty_level.hard",
      level: 4,
      image: g1d4,
      time_ms: 6000,
      items: 8,
      options: 4,
      active: activeTab === "Practicing" ? true : levelAchieved >= 4,
    },
    {
      id: 5,
      title: "games.difficulty_level.very_hard",
      level: 5,
      image: g1d5,
      time_ms: 8000,
      items: 12,
      options: 4,
      active: activeTab === "Practicing" ? true : levelAchieved >= 5,
    },
    {
      id: 6,
      title: "games.difficulty_level.extreme",
      level: 6,
      image: g1d6,
      time_ms: 6000,
      items: 12,
      options: 4,
      active: activeTab === "Practicing" ? true : levelAchieved >= 6,
    },
  ];
};

export const MAX_LEVEL = 6;

export const numberOfQuestions = (level: number, levelButtonsForTheGame: GameLevelsData[]) => {
  return levelButtonsForTheGame[level <= levelButtonsForTheGame.length ? level - 1 : 0].items;
};

export const displayTimeMs = (level: number, levelButtonsForTheGame: GameLevelsData[]) => {
  return levelButtonsForTheGame[level <= levelButtonsForTheGame.length ? level - 1 : 0].time_ms;
};

export const getNumberOfOptions = (level: number, levelButtonsForTheGame: GameLevelsData[]) => {
  return levelButtonsForTheGame[level <= levelButtonsForTheGame.length ? level - 1 : 0].options;
};

export const WORD_SETS: readonly string[] = ["body_parts", "household", "outdoor", "food"];

import React from "react";

interface CardProps {
  imgSource: string;
  imgDesc: string;
  className: string;
  onClick: () => void;
}

const Card = (props: CardProps) => {
  const { imgSource, imgDesc, className, onClick } = props;

  return (
    <div className={`grid-card ${className}`} onClick={() => onClick()} role="button">
      <img className={`img-thumbnail img-fluid grid-img`} src={imgSource} alt={imgDesc} draggable="false" />
    </div>
  );
};

export default Card;

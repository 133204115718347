import * as React from "react";
import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./style.css";
import GameScore from "../../models/game-result";
import { useUserInfo } from "../../contexts/user-info-context";
import { useAuth0 } from "@auth0/auth0-react";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import ScoreCard from "../../components/scorecard";
import { useLocation } from "react-router";
import {
  LightCoord,
  randomizeNewCoord,
} from "../../components/light-grid/light-coord";
import LightGrid from "../../components/light-grid/light-grid";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from "react-router-dom";
import CountDown from "../../components/count-down";
import { Button } from "react-bootstrap";
import { GameStage } from "../../models/game-stage";
import { useActiveTab } from "../../contexts/active-tab-context";
import { handleUpdateAppWithUserWeeklyScheduleGames } from "../../helpers/routes";

export interface Level {
  level: number;
}

export class DetailedAnswers {
  userAnswers: Array<LightCoord> = [];
  correctSeries: Array<LightCoord> = [];
  isCorrect: boolean = false;
  correctAnswersCount: number = 0;
  answeringTimeMs: number = 0;

  constructor(
    userAnswers: Array<LightCoord>,
    correct: Array<LightCoord>,
    correctAnswersCount: number,
    timeMs: number
  ) {
    this.answeringTimeMs = timeMs;
    this.correctSeries = correct;
    this.userAnswers = userAnswers;
    this.correctAnswersCount = correctAnswersCount;
    this.isCorrect =
      correctAnswersCount > 0 && correctAnswersCount === userAnswers.length;
  }
}

export class DetailedResults {
  level: number = 0;
  answers: Array<DetailedAnswers> = [];
  totalTimeS: number = 0;
  email: string = "";
  score: number = 0;
  totalCorrectAnswers: number = 0;
}

const LightUp: React.FunctionComponent = () => {
  const DEFAULT_LOCATION_DURATION_MS = 2000;
  const POINTS_PER_CORRECT_ANSWER = 200;
  const POINTS_PER_CORRECT_SEQUENCE = 1000;
  const DISPLAY_TIME_FOR_CORRECTNESS_MS = 1000;

  const LEVEL_GAME_SETUP = [
    {
      level: 1,
      time_ms: 1800,
      gridSize: 3,
      points_for_length: 800,
      time_penalty_s: 5,
      randomize_every_round: false,
      show_same_level_n_times: 1,
      allowed_incorrect_answers_per_round: 0,
    },
    {
      level: 2,
      time_ms: 1800,
      gridSize: 4,
      points_for_length: 1000,
      time_penalty_s: 5,
      randomize_every_round: false,
      show_same_level_n_times: 1,
      allowed_incorrect_answers_per_round: 0,
    },
    {
      level: 3,
      time_ms: 1800,
      gridSize: 4,
      points_for_length: 1200,
      time_penalty_s: 5,
      randomize_every_round: true,
      show_same_level_n_times: 3,
      allowed_incorrect_answers_per_round: 2,
    },
  ];
  const location: any = useLocation();

  const { t } = useTranslation("common");
  const [intervalToShowLocation, setintervalToShowLocation] = useState<number>(
    DEFAULT_LOCATION_DURATION_MS
  );

  const { currentUserInfo, updateCurrentUserInfoWithGameSchedule, noSchedule } =
    useUserInfo();
  const { activeTab } = useActiveTab();

  const { user, getAccessTokenSilently } = useAuth0();
  const [start, setStart] = useState<boolean>(true);
  const [counter, setCounter] = useState<number>(3);

  const [startGame, setStartGame] = useState<boolean>(false);
  const level = useRef<number>(0);
  const wrongAnswersThisLevel = useRef<number>(0);
  const levelShownTimes = useRef<number>(0);
  const [gameStage, setGameStage] = useState<GameStage>("ShowCountdown");
  const [sequenceStartTime, setSequenceStartTime] = useState<Date>();
  const [reloadGrid, setReloadGrid] = useState(1);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [coordinates, setCoodinates] = useState<Array<LightCoord>>([]);
  const [answeredCoodinates, setAnsweredCoodinates] = useState<
    Array<LightCoord>
  >([]);
  const detailedResults = useRef<DetailedResults>(new DetailedResults());
  const [answeringTimeStart, setAnsweringTimeStart] = useState<Date>(
    new Date()
  );

  const [gameScore, setGameScore] = useState<GameScore>({
    correctItems: 0,
    email: currentUserInfo.email,
    isScheduledGame: activeTab === "Training",
    userId: 0,
    score: 0,
    totalTimeSeconds: 0,
    averageTimeMs: 0,
    gameType: "WorkingMemoryMaintenance",
    level: 0,
    percentage: 0,
  });

  function isLevel(obj: unknown): obj is Level {
    return typeof obj === "object" && obj !== null && "level" in obj;
  }

  const getLevelInfo = () => {
    let data = LEVEL_GAME_SETUP.find((x) => x.level === level.current);
    return data !== undefined ? data : LEVEL_GAME_SETUP[0];
  };

  const getGridSize = () => {
    let data = LEVEL_GAME_SETUP.find((x) => x.level === level.current);
    return data !== undefined ? data.gridSize : LEVEL_GAME_SETUP[0].gridSize;
  };

  const getDuration = () => {
    let data = LEVEL_GAME_SETUP.find((x) => x.level === level.current);
    return data !== undefined ? data.time_ms : LEVEL_GAME_SETUP[0].time_ms;
  };

  const getPointsPerLength = () => {
    let data = LEVEL_GAME_SETUP.find((x) => x.level === level.current);
    return data !== undefined
      ? data.points_for_length
      : LEVEL_GAME_SETUP[0].points_for_length;
  };

  const getTimePenalty = () => {
    let data = LEVEL_GAME_SETUP.find((x) => x.level === level.current);
    return data !== undefined
      ? data.time_penalty_s
      : LEVEL_GAME_SETUP[0].time_penalty_s;
  };

  const getRandomizeAll = () => {
    let data = LEVEL_GAME_SETUP.find((x) => x.level === level.current);
    return data !== undefined
      ? data.randomize_every_round
      : LEVEL_GAME_SETUP[0].randomize_every_round;
  };

  const randomizeNext = () => {
    setCoodinates((oldArray) => [
      ...oldArray,
      randomizeNewCoord(
        coordinates.length > 0 ? coordinates[coordinates.length - 1] : null,
        getGridSize()
      ),
    ]);
  };

  const randomizeStart = () => {
    let newArr = new Array<LightCoord>();
    let c1 = randomizeNewCoord(null, getGridSize());
    let c2 = randomizeNewCoord(c1, getGridSize());
    newArr.push(c1);
    newArr.push(c2);
    setCoodinates(newArr);
  };

  const randomizeAll = (incrementLength: boolean) => {
    let newArr = new Array<LightCoord>();
    let randLength = incrementLength
      ? coordinates.length + 1
      : coordinates.length;
    for (let i = 0; i < randLength; ++i) {
      newArr.push(
        randomizeNewCoord(
          newArr.length > 0 ? newArr[newArr.length - 1] : null,
          getGridSize()
        )
      );
    }
    setCoodinates(newArr);
  };

  const handleGameStart = () => {
    setStart(true);
  };

  const restartGame = () => {
    levelShownTimes.current = 0;
    wrongAnswersThisLevel.current = 0;
    setGameStage("ShowCountdown");
    setCounter(3);
    detailedResults.current.answers = [];
    detailedResults.current.score = 0;
    detailedResults.current.totalCorrectAnswers = 0;
    detailedResults.current.totalTimeS = 0;
    setStart(true);
  };

  const calculateGameScore = () => {
    let duration = differenceInMilliseconds(new Date(), sequenceStartTime!);
    let correct = 0;
    let correctSeries = 0;
    let totalAnswers = 0;
    detailedResults.current.answers.forEach((result) => {
      correct += result.correctAnswersCount;
      totalAnswers += result.userAnswers.length;
      correctSeries += result.isCorrect ? 1 : 0;
    });
    let score =
      correctSeries * POINTS_PER_CORRECT_SEQUENCE +
      correct * POINTS_PER_CORRECT_ANSWER -
      Math.round((duration / 1000) * getTimePenalty());

    detailedResults.current.totalTimeS = Math.round(duration / 1000);
    detailedResults.current.email = currentUserInfo.email;
    detailedResults.current.score = score;
    detailedResults.current.totalCorrectAnswers = correct;
    setGameScore((prevState) => {
      return {
        ...prevState,
        score: score > 0 ? score : 0,
        userId: currentUserInfo.id,
        correctItems: correct,
        correctSequences: correctSeries,
        numberOfItems: totalAnswers,
        numberOfSequencers: detailedResults.current.answers.length,
        totalTimeSeconds: Math.round(duration / 1000),
        averageTimeMs: Math.round(duration / totalAnswers),
        maxLevel: LEVEL_GAME_SETUP[LEVEL_GAME_SETUP.length - 1].level,
        percentage: Math.round((correct * 100) / totalAnswers),
      };
    });
  };

  const answeredLocation = (xloc: number, yloc: number) => {
    if (gameStage !== "AskingSequence") {
      return;
    }
    answeredCoodinates.push(new LightCoord(xloc, yloc));
    setReloadGrid(Math.random());

    if (getLevelInfo().show_same_level_n_times > 1) {
      answeredLocationCrappyGameMode(xloc, yloc);
      return;
    }
    let duration = differenceInMilliseconds(new Date(), answeringTimeStart);

    if (
      !answeredCoodinates[answeredCoodinates.length - 1].equal(
        coordinates[answeredCoodinates.length - 1]
      )
    ) {
      wrongAnswersThisLevel.current++;
      levelShownTimes.current++;
      detailedResults.current.answers.push(
        new DetailedAnswers(
          answeredCoodinates,
          coordinates,
          calculateCorrect(),
          duration
        )
      );

      if (
        wrongAnswersThisLevel.current >=
        getLevelInfo().allowed_incorrect_answers_per_round
      ) {
        setGameStage("ShowingCorrectnessBeforeScore");
        setCurrentIndex(-1);
      } else {
        let inc =
          levelShownTimes.current >= getLevelInfo().show_same_level_n_times;
        getRandomizeAll() ? randomizeAll(inc) : randomizeNext();
        if (inc) {
          levelShownTimes.current = 0;
          wrongAnswersThisLevel.current = 0;
        }
        setAnsweredCoodinates([]);
        setGameStage("ShowingCorrectness");
      }
    } else if (answeredCoodinates.length === coordinates.length) {
      detailedResults.current.answers.push(
        new DetailedAnswers(
          answeredCoodinates,
          coordinates,
          calculateCorrect(),
          duration
        )
      );
      levelShownTimes.current++;

      let inc =
        levelShownTimes.current >= getLevelInfo().show_same_level_n_times;
      if (inc) {
        levelShownTimes.current = 0;
        wrongAnswersThisLevel.current = 0;
      }
      getRandomizeAll() ? randomizeAll(inc) : randomizeNext();

      setAnsweredCoodinates([]);
      setGameStage("ShowingCorrectness");
    }
  };

  const answeredLocationCrappyGameMode = (xloc: number, yloc: number) => {
    if (answeredCoodinates.length === coordinates.length) {
      levelShownTimes.current++;
      let correct = calculateCorrect();
      if (correct < coordinates.length) {
        wrongAnswersThisLevel.current++;
      }
      let duration = differenceInMilliseconds(new Date(), answeringTimeStart);

      detailedResults.current.answers.push(
        new DetailedAnswers(answeredCoodinates, coordinates, correct, duration)
      );

      if (
        wrongAnswersThisLevel.current >=
          getLevelInfo().allowed_incorrect_answers_per_round &&
        levelShownTimes.current >= getLevelInfo().show_same_level_n_times
      ) {
        setGameStage("ShowingCorrectnessBeforeScore");
        setCurrentIndex(-1);
      } else {
        let inc =
          levelShownTimes.current >= getLevelInfo().show_same_level_n_times;
        if (inc) {
          levelShownTimes.current = 0;
          wrongAnswersThisLevel.current = 0;
        }
        getRandomizeAll() ? randomizeAll(inc) : randomizeNext();

        setAnsweredCoodinates([]);
        setGameStage("ShowingCorrectness");
      }
    }
  };

  const calculateCorrect = () => {
    let correct = 0;
    for (
      let i = 0;
      i < answeredCoodinates.length && i < coordinates.length;
      ++i
    ) {
      correct = correct + (answeredCoodinates[i].equal(coordinates[i]) ? 1 : 0);
    }
    return correct;
  };

  const handleIndexUpdate = () => {
    if (currentIndex + 1 >= coordinates.length) {
      setCurrentIndex(-1);
      setGameStage("AskingSequence");
      setAnsweringTimeStart(new Date());
    } else {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  useEffect(() => {
    if (!start) {
      setintervalToShowLocation(getDuration());
      if (isLevel(location.state)) {
        level.current = location.state.level;
      }
      randomizeStart();
      setCurrentIndex(0);
      levelShownTimes.current = 0;
      wrongAnswersThisLevel.current = 0;
      setAnsweredCoodinates([]);
      setSequenceStartTime(new Date());
      setGameStage("ShowingSequence");
    }
  }, [start]);

  useEffect(() => {
    if (gameStage === "ShowingSequence") {
      setTimeout(() => handleIndexUpdate(), getDuration());
    }
  }, [currentIndex]);

  useEffect(() => {
    if (gameStage === "CalculateScore") {
      calculateGameScore();
      setCoodinates([]);
      setGameStage("ShowScore");
    } else if (gameStage === "ShowingCorrectness") {
      setTimeout(() => {
        setGameStage("ShowingSequence");
        setCurrentIndex(0);
      }, DISPLAY_TIME_FOR_CORRECTNESS_MS);
    } else if (gameStage === "ShowingCorrectnessBeforeScore") {
      setTimeout(() => {
        setGameStage("CalculateScore");
      }, DISPLAY_TIME_FOR_CORRECTNESS_MS);
    }
  }, [gameStage]);

  const showSeries = (index: number) => {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <h3 className="mb-5 text-size">
          {t("games.light_up.memorize_series")}
        </h3>
        <LightGrid
          size={getGridSize()}
          answeredLocation={answeredLocation}
          disabled={true}
          loc={new LightCoord(coordinates[index].x, coordinates[index].y)}
        ></LightGrid>
        <hr className="hr-style" />
        <div className="text-center mt-5">
          <Link to="/" className="m-3 text-center">
            <Button
              variant="outline-secondary"
              size="lg"
              onClick={() =>
                handleUpdateAppWithUserWeeklyScheduleGames(
                  updateCurrentUserInfoWithGameSchedule,
                  noSchedule
                )
              }
            >
              {t("common.close")}
            </Button>
          </Link>
        </div>
      </div>
    );
  };

  const askSeries = () => {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <h3 className="mb-5 text-size">{t("games.light_up.enter_series")}</h3>
        <LightGrid
          size={getGridSize()}
          answeredLocation={answeredLocation}
          correctLocation={coordinates[answeredCoodinates.length]}
          disabled={false}
          loc={new LightCoord(-1, -1)}
          key={answeredCoodinates.length}
        ></LightGrid>
        <hr className="hr-style" />
        <div className="text-center mt-5">
          <Link to="/" className="m-3 text-center">
            <Button
              variant="outline-secondary"
              size="lg"
              onClick={() =>
                handleUpdateAppWithUserWeeklyScheduleGames(
                  updateCurrentUserInfoWithGameSchedule,
                  noSchedule
                )
              }
            >
              {t("common.close")}
            </Button>
          </Link>
        </div>
      </div>
    );
  };

  const showingCorrectness = () => {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div>
          {detailedResults.current.answers[
            detailedResults.current.answers.length - 1
          ].isCorrect ? (
            <h3>
              <FontAwesomeIcon
                style={{ color: "green" }}
                className="float-end"
                icon={faCheck}
                size="6x"
              />
            </h3>
          ) : (
            <h3>
              <FontAwesomeIcon
                style={{ color: "red" }}
                className="float-end"
                icon={faXmark}
                size="6x"
              />
            </h3>
          )}
        </div>
      </div>
    );
  };

  const showScoreCard = () => {
    return (
      <div>
        <ScoreCard
          gameScore={gameScore}
          detailedResults={JSON.stringify(detailedResults.current)}
          showAverageTimeMs={false}
          showCorrectMoves={true}
          showCorrectSequences={false}
          showLenghtOfSequence={false}
          showCorrectnessPercentage={false}
          restartGame={restartGame}
          setGameStage={setGameStage}
          gameStage={gameStage}
        />
      </div>
    );
  };

  if (start) {
    return (
      <CountDown
        counter={counter}
        setCounter={setCounter}
        setStart={setStart}
      />
    );
  }

  return (
    <div className="container py-5 h-100">
      {gameStage === "ShowingSequence" ? (
        <Fragment>{showSeries(currentIndex)}</Fragment>
      ) : gameStage === "AskingSequence" ? (
        <Fragment>{askSeries()}</Fragment>
      ) : gameStage === "ShowScore" || gameStage === "ScoreSent" ? (
        <div>
          <Fragment>{showScoreCard()}</Fragment>
        </div>
      ) : gameStage === "ShowingCorrectness" ||
        gameStage === "ShowingCorrectnessBeforeScore" ? (
        <Fragment>{showingCorrectness()}</Fragment>
      ) : null}
    </div>
  );
};

export default LightUp;

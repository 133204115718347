import * as React from "react";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { useLocation } from "react-router";

//import UpdatingSpatial from "./games/updating-spatial";
import UpdatingItemsInstructions from "./updating-items-instructions";
import Games from "./games";
import CountDown from "../../components/count-down";
import { GameType } from "../../models/game-type";
import { getGameLevelData, GameCategoryProps, GameCriterion, getGameButtonsCategory, getRandomSequences } from "../../helpers/updating-items-helpers";

import "./style.css";

import { GameLevelsData } from "../../models/game-level-data";
import { UserDataForGame } from "../../models/user-data-for-game";

import { useActiveTab } from "../../contexts/active-tab-context";
import { useUserDataForGame } from "../../contexts/user-data-for-game-context";
import {handleUpdateAppWithUserWeeklyScheduleGames} from "../../helpers/routes";
import {useUserInfo} from "../../contexts/user-info-context";

const UpdatingItems: React.FunctionComponent = () => {
  const { t } = useTranslation("common");
  const location: any = useLocation();

  const { activeTab } = useActiveTab();
  const { userDataForGames, updateUserDataForGames } = useUserDataForGame();
  const {noSchedule, updateCurrentUserInfoWithGameSchedule} = useUserInfo();

  const [gameTypeScheduleGame, setGameTypeScheduleGame] = useState();
  const [titleScheduleGame, setTitleScheduleGame] = useState();

  const [start, setStart] = useState<boolean>(false);
  const [chooseLevel, setChooseLevel] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(3);

  const [gameCategorySelectedButton, setGameCategorySelectedButton] = useState<number[]>([]);
  const [gameLevelSelectedButton, setGameLevelSelectedButton] = useState<number[]>([]);
  const [selectedGameCriterion, setSelectedGameCriterion] = useState<GameCriterion>({
    gameType: "Undefined",
    title: "",
    level: 0,
    difficultyLevel: {
      min: 0,
      max: 0,
    },
  });

  const [sequence, setSequence] = useState<string[][]>([]);

  useEffect(() => {
    let seq = getRandomSequences(selectedGameCriterion.difficultyLevel.min, selectedGameCriterion.difficultyLevel.max, selectedGameCriterion.gameType);
    setSequence(seq);
  }, [selectedGameCriterion]);
  useEffect(() => {}, [selectedGameCriterion]);

  const [userDataForTheGame, setUserDataForTheGame] = useState<UserDataForGame>();
  const [levelAchieved, setLevelAchieved] = useState<number[]>([]);
  const [levelButtonsForTheGame, setLevelButtonsForTheGame] = useState<GameLevelsData[]>([new GameLevelsData()]);

  useEffect(() => {
    if (location.state.gameType != "UpdatingItems") {
      setSelectedGameCriterion({
        ...selectedGameCriterion,
        gameType: location.state.gameType,
        title: location.state.title,
      });
    }
  }, [gameTypeScheduleGame, titleScheduleGame]);

  const [buttonsForGame, setButtonsForGame] = useState<GameCategoryProps[]>(getGameButtonsCategory(location.state.gameType));

  useEffect(() => {}, [selectedGameCriterion]);

  const handleGameCategoryButton = (buttonId: number, gameType: GameType, title: string) => {
    setGameCategorySelectedButton((prevState) => {
      if (prevState.includes(buttonId)) {
        return prevState.filter((el) => el !== buttonId);
      }
      return [buttonId];
    });
    setSelectedGameCriterion({
      ...selectedGameCriterion,
      gameType: gameType,
      title: title,
    });
  };

  const handleGameLevelButton = (difficultyLevel: { min: number; max: number }, buttonTittle: string, level: number) => {
    setGameLevelSelectedButton((prevState) => {
      if (prevState.includes(level)) {
        return prevState.filter((el) => el !== level);
      }
      return [level];
    });
    setSelectedGameCriterion({
      ...selectedGameCriterion,
      level: level,
      difficultyLevel: difficultyLevel,
    });
    setStart(true);
  };

  const handleCategoryChange = () => {
    setSelectedGameCriterion({
      gameType: "Undefined",
      title: "",
      level: 0,
      difficultyLevel: {
        min: 0,
        max: 0,
      },
    });
  };

  useEffect(() => {
    if (userDataForGames != undefined || userDataForGames != null) {
      setUserDataForTheGame(userDataForGames!.find((x: any) => x.gameType === selectedGameCriterion.gameType));
    }
  }, [userDataForGames, selectedGameCriterion]);

  useEffect(() => {
    setLevelButtonsForTheGame(getGameLevelData(activeTab, userDataForTheGame != undefined ? userDataForTheGame.levelAchieved : 1));
  }, [userDataForTheGame]);

  if (start) {
    return <CountDown counter={counter} setCounter={setCounter} setStart={setStart} />;
  }

  const ChooseGameTypes = () => {
    return (
      <div>
        {selectedGameCriterion.gameType === "Undefined" ? (
          <div>
            <h2 className="mb-5 d-flex justify-content-center">{t("games.updating_items.select_game_game_type")}</h2>
            <Row xs={2} md={3} className="g-4">
              {buttonsForGame.map((button, index) => {
                const title =  t(button.title).charAt(0).toUpperCase() + t(button.title).slice(1);
                return (
                <Col key={index} className="btn btn-light">
                  <Card
                    className={`card-style ${button.active ? "" : "disabled-link"}`}
                    onClick={button.active ? () => handleGameCategoryButton(button.id, button.gameType, button.title) : () => {}}
                    role="button"
                  >
                    <Card.Img variant="top" className="card-img-top" src={`${button.image}`}></Card.Img>
                    <Card.Title className="text-center title-style">{title}</Card.Title>
                  </Card>
                </Col>
              )})}
            </Row>
            <div className="text-center mt-5">
              <Link to="/" className="m-3 text-center">
                <Button variant="outline-secondary" size="lg" onClick={() => handleUpdateAppWithUserWeeklyScheduleGames(updateCurrentUserInfoWithGameSchedule, noSchedule)}>
                  {t("common.close")}
                </Button>
              </Link>
            </div>
          </div>
        ) : chooseLevel ? (
          <div>
            <h2 className="mb-5 d-flex justify-content-center">{t("games.select_difficulty_level")}</h2>
            <Row xs={2} md={3} className="g-4">
              {levelButtonsForTheGame.map((button, index) => {
                let image = "";

                switch (selectedGameCriterion.gameType) {
                  case "UpdatingLetter":
                    image = button.imageArray![0];
                    break;
                  case "UpdatingNumber":
                    image = button.imageArray![1];
                    break;
                  case "UpdatingColor":
                    image = button.imageArray![2];
                    break;
                  case "UpdatingSpatial":
                    image = button.imageArray![3];
                    break;

                  default:
                    break;
                }
                return (
                  <Col key={index} className="btn btn-light">
                    <Card
                      className={`card-style ${button.active ? "" : "disabled-link"}`}
                      onClick={button.active ? () => handleGameLevelButton(button.difficultyLevel!, button.title, button.level) : () => {}}
                      role="button"
                    >
                      <Card.Img variant="top" className="card-img-top" src={`${image}`}></Card.Img>
                      <Card.Title className="text-center title-style">{t(button.title)}</Card.Title>
                    </Card>
                  </Col>
                );
              })}
            </Row>
            <div className="text-center mt-5">
              <Link to="/" className="m-3 text-center">
                <Button variant="outline-secondary" size="lg">
                  {t("common.close")}
                </Button>
              </Link>
            </div>
          </div>
        ) : (
          <UpdatingItemsInstructions title={selectedGameCriterion.title} gameType={selectedGameCriterion.gameType} setChooseLevel={setChooseLevel} />
        )}
      </div>
    );
  };

  const startGame = () => {
    return (
      <Games
        level={selectedGameCriterion.level}
        gameType={selectedGameCriterion.gameType}
        sessionSequences={sequence}
        setStart={setStart}
        setCounter={setCounter}
        setSelectedGameCriterion={setSelectedGameCriterion}
        setChooseLevel={setChooseLevel}
      />
    );
  };
  return (
    <Fragment>
      <section className="py-5 h-100">
        <div className="container h-100">{selectedGameCriterion.level === 0 ? <ChooseGameTypes /> : counter === 0 ? startGame() : null}</div>
      </section>
    </Fragment>
  );
};

export default UpdatingItems;

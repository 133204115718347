import g5L from "../images/G5L.png";
import g5N from "../images/G5N.png";
import g5C from "../images/G5C.png";
import g5P from "../images/G5P.png";

import g5LD1 from "../images/G5LD1.png";
import g5LD2 from "../images/G5LD2.png";
import g5LD3 from "../images/G5LD3.png";

import g5ND1 from "../images/G5ND1.png";
import g5ND2 from "../images/G5ND2.png";
import g5ND3 from "../images/G5ND3.png";

import g5CD1 from "../images/G5CD1.png";
import g5CD2 from "../images/G5CD2.png";
import g5CD3 from "../images/G5CD3.png";

import g5PD1 from "../images/G5PD1.png";
import g5PD2 from "../images/G5PD2.png";
import g5PD3 from "../images/G5PD3.png";

import { GameType } from "../models/game-type";
import { ActiveTab } from "../models/active-tab";

export const LAST_N = 4;
export const GAME_LENGTH_SEQUENCES = 6;

export class SequenceAnswer {
  sequenceOfTheSession: Array<string> = [];
  correctSequence: Array<string> = [];
  answeredSequence: Array<string> = [];
  sequenceTimeMs: number = 0;
  correctAnswers: number = 0;
  correct: boolean = false;
  totalPoints: number = 0;
}

export const ALPHABET = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
export const COLORS = ["red", "blue", "yellow", "lime", "Orange", "black", "purple", "DarkSlateGray", "LightSalmon"];

export const MAX_NUMBER = 9;
export const MIN_NUMBER = 0;

// Types and interfaces
export interface Level {
  totalNumber: number;
  lastElements: number;
}

export interface GameCriterion {
  gameType: GameType;
  title: string;
  level: number;
  difficultyLevel: {
    min: number;
    max: number;
  };
}

export const getRandomAlphabet = () => {
  return ALPHABET[Math.floor(Math.random() * ALPHABET.length)];
};

export const getRandomAlphabets = (level: number): string[] => {
  let array: string[] = [];

  for (let index = 0; index < level; index++) {
    let alphabet = getRandomAlphabet();

    if (index > 0) {
      while (alphabet === array[index - 1]) {
        alphabet = getRandomAlphabet();
      }
    }
    array.push(alphabet);
  }
  return array;
};

export const getRandomColor = () => {
  return COLORS[Math.floor(Math.random() * COLORS.length)];
};

export const getRandomColors = (length: number): string[] => {
  let array: string[] = [];

  for (let index = 0; index < length; index++) {
    let color = getRandomColor();

    if (index > 0) {
      while (color === array[index - 1]) {
        color = getRandomColor();
      }
    }
    array.push(color);
  }
  return array;
};

export const getRandomLocations = (length: number): string[] => {
  let array: string[] = [];

  for (let index = 0; index < length; index++) {
    let coord = "";
    var loopedEnough = 0;
    do {
      let x = getRandomNumberBetween(0, 3);
      let y = getRandomNumberBetween(0, 3);
      coord = x + "," + y;
    } while (array.includes(coord) || loopedEnough > 50);
    array.push(coord);
  }
  return array;
};

export const getRandomNumberBetween = (min: number = MIN_NUMBER, max: number = MAX_NUMBER) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const getRandomNumbers = (length: number): number[] => {
  let array: number[] = [];

  for (let index = 0; index < length; index++) {
    let num = getRandomNumberBetween();

    if (index > 0) {
      while (num === array[index - 1]) {
        num = getRandomNumberBetween();
      }
    }
    array.push(num);
  }
  return array;
};

export const getRandomNumbersAsStrings = (length: number): string[] => {
  let array: string[] = [];

  for (let index = 0; index < length; index++) {
    let num = getRandomNumberBetween().toString();

    if (index > 0) {
      while (num === array[index - 1]) {
        num = getRandomNumberBetween().toString();
      }
    }
    array.push(num);
  }
  return array;
};

export const shuffleArray = (array: any[]): any[] => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};

export const compareArrays = (arr1: any[], arr2: any[]) => {
  let count = 0;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] === arr2[i]) {
      ++count;
    }
  }
  return count;
};

export const countAllCorrectAnswerHelper = (arr: number[]) => {
  let count = 0;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === 4) {
      ++count;
    }
  }
  return count;
};

export const getRandomSequences = (min: number, max: number, gameType: GameType) => {
  let sequence = [...Array(6)].map(() => {
    let length = getRandomNumberBetween(min, max);
    switch (gameType) {
      case "UpdatingNumber":
        return getRandomNumbersAsStrings(length);
        break;
      case "UpdatingColor":
        return getRandomColors(length);
        break;
      case "UpdatingLetter":
        return getRandomAlphabets(length);
        break;
      case "UpdatingSpatial":
        return getRandomLocations(length);
        break;
      default:
        return [];
    }
  });
  return sequence;
};

export const getGameButtonsCategory = (gameType: GameType): GameCategoryProps[] => {
  return [
    {
      id: 0,
      title: "common.item_type_from_game_type.UpdatingNumber",
      gameType: "UpdatingNumber",
      image: g5N,
      active: gameType === "UpdatingNumber" || gameType === "UpdatingItems",
      //active: !isSchedule || gameType === "UpdatingNumber",
    },
    {
      id: 1,
      title: "common.item_type_from_game_type.UpdatingLetter",
      gameType: "UpdatingLetter",
      image: g5L,
      active: gameType === "UpdatingLetter" || gameType === "UpdatingItems",
    },
    {
      id: 2,
      title: "common.item_type_from_game_type.UpdatingColor",
      gameType: "UpdatingColor",
      image: g5C,
      active: gameType === "UpdatingColor" || gameType === "UpdatingItems",
    },
    {
      id: 3,
      title: "common.item_type_from_game_type.UpdatingSpatial",
      gameType: "UpdatingSpatial",
      image: g5P,
      active: gameType === "UpdatingSpatial" || gameType === "UpdatingItems",
    },
  ];
};

export interface GameCategoryProps {
  id: number;
  title: string;
  gameType: GameType;
  image: any;
  active?: boolean;
}

export const BUTTONS_GAME_CATEGORY: {
  id: number;
  title: string;
  gameType: GameType;
  image: any;
}[] = [
  {
    id: 0,
    title: "common.item_type_from_game_type.UpdatingNumber",
    gameType: "UpdatingNumber",
    image: g5N,
  },
  {
    id: 1,
    title: "common.item_type_from_game_type.UpdatingLetter",
    gameType: "UpdatingLetter",
    image: g5L,
  },
  {
    id: 2,
    title: "common.item_type_from_game_type.UpdatingColor",
    gameType: "UpdatingColor",
    image: g5C,
  },
  { id: 3, title: "common.item_type_from_game_type.UpdatingSpatial", gameType: "UpdatingSpatial", image: g5P },
];

export const getGameLevelData = (activeTab: ActiveTab, levelAchieved: number) => {
  return [
    {
      id: 1,
      difficultyLevel: {
        min: 4,
        max: 7,
      },
      title: "games.difficulty_level.easy",
      level: 1,
      imageArray: [g5LD1, g5ND1, g5CD1, g5PD1],
      active: true,
    },
    {
      id: 2,
      difficultyLevel: {
        min: 4,
        max: 11,
      },
      title: "games.difficulty_level.intermediate",
      level: 2,
      imageArray: [g5LD2, g5ND2, g5CD2, g5PD2],
      active: activeTab === "Practicing" ? true : levelAchieved >= 2,
    },
    {
      id: 3,
      difficultyLevel: {
        min: 4,
        max: 15,
      },
      title: "games.difficulty_level.hard",
      level: 3,
      imageArray: [g5LD3, g5ND3, g5CD3, g5PD3],
      active: activeTab === "Practicing" ? true : levelAchieved >= 3,
    },
  ];
};

import * as React from "react";
import { Fragment, useEffect, useState } from "react";
import "./index.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { i18n } from "../../i18n/i18n";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faCircleQuestion, faFileCircleQuestion} from "@fortawesome/free-solid-svg-icons";
import { ButtonProps, getGameButtons } from "../../helpers/home-helpers";
import { useUserInfo } from "../../contexts/user-info-context";
import { useAuth0 } from "@auth0/auth0-react";
import { GameType } from "../../models/game-type";
import { ActiveTab } from "../../models/active-tab";
import {
  WeekResults,
  findConsecutiveWeek,
  findConsecutiveWeekReachingGoals,
  findActiveGamingWeek,
  getNumberWithSuffix,
  getNextDayOfWeek,
  findWeeksNumbers,
} from "../../helpers/motivation-message-helpers";
import { getUnscheduledGameResults } from "../../helpers/routes";

interface props {
  activeTab: ActiveTab;
}

export interface Schedule {
  startedAt: "";
  endsAt: "";
  gameTypes: GameType[];
}

const Home = (props: props) => {
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { activeTab } = props;
  const { currentUserInfo, scheduleGameForUser, noSchedule } = useUserInfo();

  const [previousWeekResultsDetails, setPreviousWeekResultDetails] = useState(
    []
  );
  const [weekResults, setWeekResults] = useState<WeekResults[]>([]);
  const [consecutiveWeeks, setConsecutiveWeeks] = useState<number>();
  const [consecutiveWeeksReachingGoals, setConsecutiveWeeksReachingGoals] =
    useState<number>();
  const [activeGamingWeeks, setActiveGamingWeeks] = useState<number>();
  const [totalWeeksCountFromBeginning, setTotalWeeksCountFromBeginning] =
    useState<number>();

  const { t } = useTranslation("common");
  const [buttonsForGame, setButtonsForGame] = useState<ButtonProps[]>([]);

  useEffect(() => {
    if (scheduleGameForUser !== undefined && noSchedule === undefined) {
      setButtonsForGame(getGameButtons(activeTab, scheduleGameForUser));
    } else {
      setButtonsForGame(getGameButtons(activeTab));
    }
  }, [scheduleGameForUser]);

  useEffect(() => {
    if (currentUserInfo !== undefined || true) {
      let previousWeekResults =
        currentUserInfo.testSchedule.weekSchedules.filter(
          (x: any) => new Date(x.startsAt) < new Date()
        );
      previousWeekResults.map((x: any) => {
        setWeekResults((prevState) => [
          ...prevState,
          new WeekResults({
            week: x.week,
            playedPercentage: x.playedPercentage,
          }),
        ]);
      });

      let fetchUnscheduledWeeksData = async () => {
        let token = await getAccessTokenSilently();
        let unscheduledGameResults = await getUnscheduledGameResults(
          token,
          currentUserInfo.id
        );
        setWeekResults((previousState) => [
          ...previousState,
          ...unscheduledGameResults,
        ]);
      };

      fetchUnscheduledWeeksData().catch((err) => console.log(err));
    }
  }, [currentUserInfo]);

  useEffect(() => {
    if (weekResults.length > 0) {
      let previousWeekResults =
        currentUserInfo.testSchedule.weekSchedules.filter(
          (x: any) => new Date(x.startsAt) < new Date()
        );

      let nextSunday = getNextDayOfWeek(new Date(), 7);
      let totalWeeksCountFromBeginning = findWeeksNumbers(
        nextSunday,
        new Date(previousWeekResults[0].startsAt)
      );
      setTotalWeeksCountFromBeginning(totalWeeksCountFromBeginning);

      let consecutiveWeeks = findConsecutiveWeek(
        weekResults,
        previousWeekResults
      );
      setConsecutiveWeeks(consecutiveWeeks);
      let consecutiveWeeksReachingGoals =
        findConsecutiveWeekReachingGoals(weekResults);
      setConsecutiveWeeksReachingGoals(consecutiveWeeksReachingGoals);
      let activeGamingWeeks = findActiveGamingWeek(weekResults);
      setActiveGamingWeeks(activeGamingWeeks);
    }
  }, [weekResults]);

  const ShowMotivationMessage = () => {
    return (
      <div className="d-flex flex-column mt-2">
        {consecutiveWeeks! > 1 &&
        (activeTab !== "Practicing" || weekResults.some((x) => x.week > 24)) ? (
          <div className="div-center">
            <div className="d-flex flex-row">
              <FontAwesomeIcon
                style={{ color: "orange", fontSize: "1rem" }}
                icon={faStar}
              />
              <div className="message-div">
                {t("common.motivational_message.consecutive_week", {
                  consecutive_weeks: getNumberWithSuffix(
                    consecutiveWeeks!,
                    i18n.language
                  ),
                })}
              </div>
            </div>
          </div>
        ) : null}
        {consecutiveWeeksReachingGoals! > 1 &&
        (activeTab !== "Practicing" || weekResults.some((x) => x.week > 24)) ? (
          <div className="div-center mt-2">
            <div className="d-flex flex-row">
              <FontAwesomeIcon
                style={{ color: "orange", fontSize: "1rem" }}
                icon={faStar}
              />
              <div className="message-div">
                {t("common.motivational_message.reaching_goals", {
                  consecutive_weeks_reaching_goals:
                    consecutiveWeeksReachingGoals!,
                })}
              </div>
            </div>
          </div>
        ) : null}
        {activeGamingWeeks! > 0 &&
        (activeTab !== "Practicing" || weekResults.some((x) => x.week > 24)) ? (
          <div className="div-center mt-2">
            <div className="d-flex flex-row">
              <FontAwesomeIcon
                style={{ color: "orange", fontSize: "1rem" }}
                icon={faStar}
              />
              <div className="message-div">
                {t("common.motivational_message.active_week", {
                  active_gamin_weeks: getNumberWithSuffix(
                    activeGamingWeeks!,
                    i18n.language
                  ),
                  week:
                    weekResults.length > 10 ? 10 : totalWeeksCountFromBeginning,
                })}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const handleScheduleGameForUser = () => {
    if (currentUserInfo !== null) {
      return new Promise<void>(async (resolve, reject) => {
        try {
          const token = !isAuthenticated
            ? localStorage.getItem(process.env.REACT_APP_TOKEN_NAME!)
            : await getAccessTokenSilently();
          const requestOptions = {
            method: "PUT",
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };
          const res = await fetch(
            process.env.REACT_APP_API_SERVER_URL +
              `/user/schedule-update/${currentUserInfo!.id}`,
            requestOptions
          );
          const resJson = await res.json();
          resolve();
          //console.log({ resJson });
        } catch (err) {
          reject(err);
          console.log(err);
        }
      });
    }
  };

  return (
    <Fragment>
      {/*<div className="link-div-style">*/}
      {/*    <Link to="/introduction" className="link-style">*/}
      {/*        <FontAwesomeIcon className="float-end" icon={faCircleQuestion} size="2x" />*/}
      {/*    </Link>*/}
      {/*</div>*/}
      <section className="py-2 vh-100">
        <h2 className="text-center introduction-text-style">
          {t("games.select_game")}
        </h2>
        <div className="container h-100">
          <Row xs={2} md={3} className="g-1 center-row-container">
            {buttonsForGame.map((gameButton: ButtonProps, index: number) => (
              <Col key={index} title={t(gameButton.name)}>
                {gameButton.active ? (
                  <Link
                    to={`${gameButton.route}`}
                    state={{
                      isSchedule: gameButton.isSchedule,
                      gameType: gameButton.gameType,
                      title: gameButton.name,
                    }}
                    className="link-style"
                    onClick={
                      noSchedule && true ? handleScheduleGameForUser : () => {}
                    }
                  >
                    <Card className="card-style">
                      <Card.Body>
                        {/*<Card.Title className="text-center font-style">{t(gameButton.name)}</Card.Title>*/}
                        <Card.Text></Card.Text>
                      </Card.Body>
                      <Card.Img
                        className="card-image-top"
                        src={`${gameButton.image}`}
                      ></Card.Img>
                    </Card>
                  </Link>
                ) : (
                  <Link to="" className="disabled-link">
                    <Card className="card-style" key={index}>
                      <Card.Body>
                        {/*<Card.Title className="text-center font-style">{t(gameButton.name)}</Card.Title>*/}
                        <Card.Text></Card.Text>
                      </Card.Body>
                      <Card.Img
                        className="card-image-top"
                        src={`${gameButton.image}`}
                      ></Card.Img>
                    </Card>
                  </Link>
                )}
              </Col>
            ))}
          </Row>
          {/*<hr className="hr-style" />*/}
          {/*<div className="text-center mt-3">*/}
          {/*    <Link to="/" className="m-3 text-center">*/}
          {/*        <Button variant="outline-secondary" size="lg">*/}
          {/*            {t("common.close")}*/}
          {/*        </Button>*/}
          {/*    </Link>*/}
          {/*</div>*/}
          <div className="mt-5">
            <ShowMotivationMessage />
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default Home;

import * as React from "react";
import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";

import "./style.css";
import Grid from "./grid";
import Scorecard from "../../components/scorecard";
import { useAuth0 } from "@auth0/auth0-react";
import GameScore from "../../models/game-result";
import { useUserInfo } from "../../contexts/user-info-context";
import { useActiveTab } from "../../contexts/active-tab-context";

const GRID_WIDTH = 4;
const POINTS_PER_CARD = 1000;
const POINT_DEDUCTION_PER_EXTRA_MOVE = 400;
const POINTS_DEDUCTION_PER_SECOND = 50;

interface Interval {
  callback: (a: any) => void;
  delay: number;
  duration: any;
}

interface props {
  getArrayOfImages: () => string[];
  level: number;
  setStart: React.Dispatch<React.SetStateAction<boolean>>;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
}

// Map is needed to prevent stringify from screwing up the order of moves, this is needed to stringify to stringify
// Map is not yet supported JSON
function replacer(key: any, value: any) {
  if (value instanceof Map) {
    return {
      dataType: "Map",
      value: Array.from(value.entries()),
    };
  } else {
    return value;
  }
}

export class CardDef {
  path: string;
  alreadyFlipped: boolean;
  visible: boolean;
  pairFound: boolean;
  index: number;

  constructor(path: string, index: number) {
    this.path = path;
    this.alreadyFlipped = false;
    this.visible = false;
    this.pairFound = false;
    this.index = index;
  }
}

export class MoveDef {
  card: string;
  alreadyFlipped: boolean;
  x: number;
  y: number;
  timeMs: number;
  constructor(card: CardDef, timeMs: number) {
    this.card = card.path.substring(card.path.lastIndexOf("/") + 1);
    this.x = Math.floor(card.index / GRID_WIDTH);
    this.y = card.index % GRID_WIDTH;
    this.alreadyFlipped = card.alreadyFlipped;
    this.timeMs = timeMs;
  }
}

export class DetailedResults {
  table: string[][] = [];
  moves: Map<number, MoveDef> = new Map<number, MoveDef>();
  score: number = 0;
  totalCorrectAnswers: number = 0;
  level: number = 0;
  movesAboveOptimal: number = 0;
  totalTimeS: number = 0;
  setTable(cards: CardDef[]) {
    let arr: string[] = [];
    for (let i = 0; i < cards.length; ++i) {
      if (i % GRID_WIDTH == 0 && i > 0) {
        i === GRID_WIDTH ? (this.table[0] = arr) : this.table.push([...arr]);
        arr = [];
      }
      let index = Math.floor(i / GRID_WIDTH);
      arr.push(cards[i].path.substring(cards[i].path.lastIndexOf("/")));
    }
    this.table.push([...arr]);
  }
}

const useInterval = (props: Interval) => {
  const durationRef = useRef<any>(props.duration);
  const durationIntervalRef = useRef<NodeJS.Timer>();

  const handler = () => {
    props.callback(durationRef);
  };

  useEffect(() => {
    const durationInterval = setInterval(handler, props.delay);
    durationIntervalRef.current = durationInterval!;
    return () => {
      clearInterval(durationInterval);
    };
  }, [props.delay]);

  return durationIntervalRef;
};

const Game = (props: props) => {
  const { level, setStart, setCounter } = props;
  const { user, getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation("common");
  const { currentUserInfo } = useUserInfo();
  const { activeTab } = useActiveTab();

  //const [newGame, setNewGame] = useState<boolean>(false);
  const [imageData, setImageData] = useState<string[]>([]);

  const [cardDefs, setCardDefs] = useState<Array<CardDef>>([]);
  const [imagesForGame, setImagesForGame] = useState<string[]>([]);
  const [duration, setDuration] = useState<number>(0);
  const [winner, setWinner] = useState<boolean>(false);
  const [moveCount, setMoveCount] = useState<number>(0);
  const [movesAboveOptimal, setMovesAboveOptimal] = useState<number>(0);
  const [submitButton, setSubmitButton] = useState(false);
  const detailedResults = useRef<DetailedResults>(new DetailedResults());
  const [answerStartDateTime, setAnswerStartDateTime] = useState<Date>(
    new Date()
  );

  const [gameScore, setGameScore] = useState<GameScore>({
    correctItems: 0,
    email: currentUserInfo.email,
    isScheduledGame: activeTab === "Training",
    userId: currentUserInfo.id,
    score: 0,
    totalTimeSeconds: 0,
    averageTimeMs: 0,
    gameType: "EpisodicMemorySpatialSpeed",
    level: level,
    percentage: 0,
  });

  useEffect(() => {
    setImageData(props.getArrayOfImages());
  }, []);

  const durationIntervalRef = useInterval({
    callback: (durationRef: any) => {
      durationRef.current++;
      setDuration(durationRef.current);
    },
    delay: 1000,
    duration: duration,
  });

  const checkCards = (firstCard: CardDef, secondCard: CardDef) => {
    if (firstCard.path === secondCard.path && firstCard !== secondCard) {
      firstCard.pairFound = true;
      secondCard.pairFound = true;
    }
    let fromPreviousMs = differenceInMilliseconds(
      new Date(),
      answerStartDateTime!
    );

    let firstCardSameToPreviousFlippedCard = cardDefs.findIndex(
      (x) => x.path === firstCard.path && x.alreadyFlipped
    );
    if (firstCardSameToPreviousFlippedCard !== -1) {
      firstCard.alreadyFlipped = true;
    }

    detailedResults.current.moves.set(
      detailedResults.current.moves.size + 1,
      new MoveDef(firstCard, fromPreviousMs)
    );
    detailedResults.current.moves.set(
      detailedResults.current.moves.size + 1,
      new MoveDef(secondCard, fromPreviousMs)
    );
    setAnswerStartDateTime(new Date());
    //console.log("detailed results", detailedResults.current);
    if (firstCard.pairFound) {
      // give minus for time spent
      if (cardDefs.findIndex((x) => !x.pairFound) === -1) {
        //setWinner(true);
        gameScore.score =
          gameScore.score - POINTS_DEDUCTION_PER_SECOND * duration > 0
            ? gameScore.score -
              Math.round(POINTS_DEDUCTION_PER_SECOND * duration)
            : 0;
        // state is not updating after last pair been found, work around by adding 1 to moveCount
        gameScore.percentage = Math.round(
          (((moveCount + 1) / 2 - movesAboveOptimal) * 100) /
            ((moveCount + 1) / 2)
        );

        detailedResults.current.movesAboveOptimal = movesAboveOptimal;
        detailedResults.current.score = gameScore.score;
        detailedResults.current.totalCorrectAnswers = gameScore.percentage!;
        detailedResults.current.level = level;
        detailedResults.current.totalTimeS = duration;

        setGameScore((prevState) => {
          return {
            ...prevState,
            // state is not updating after last pair been found, work around by adding 1 to moveCount
            correctItems: moveCount - movesAboveOptimal + 1,
            numberOfItems: moveCount + 1,
            totalTimeSeconds: duration,
            level: level,
            score: gameScore.score,
            maxLevel: 3,
            // Don't need to add 1 here with moveCount. Calculation is done here correctly after last sate update
            percentage: gameScore.percentage,
            averageTimeMs: Math.round((duration * 1000) / (moveCount + 1)),
          };
        });
        setWinner((prevState) => !prevState);
        clearInterval(durationIntervalRef.current);
      }
    } else {
      // check if we need to give minus points for opening already flipped cards unnecessarily
      if (firstCard.alreadyFlipped || secondCard.alreadyFlipped) {
        // console.log(
        //   "deducting points",
        //   (firstCard.alreadyFlipped ? POINT_DEDUCTION_PER_EXTRA_MOVE : 0) - (secondCard.alreadyFlipped ? POINT_DEDUCTION_PER_EXTRA_MOVE : 0)
        // );
        gameScore.score =
          gameScore.score -
          (firstCard.alreadyFlipped ? POINT_DEDUCTION_PER_EXTRA_MOVE : 0) -
          (secondCard.alreadyFlipped ? POINT_DEDUCTION_PER_EXTRA_MOVE : 0);
        //setMovesAboveOptimal((previousMovesAboveOptimal) => previousMovesAboveOptimal + (firstCard.alreadyFlipped && secondCard.alreadyFlipped ? 2 : 1));
        setMovesAboveOptimal(
          (previousMovesAboveOptimal) =>
            previousMovesAboveOptimal +
            (firstCard.alreadyFlipped || secondCard.alreadyFlipped ? 1 : 0)
        );
      }
    }
    firstCard.alreadyFlipped = true;
    secondCard.alreadyFlipped = true;

    setTimeout(() => {
      cardDefs.forEach((x) => {
        x.visible = false;
      });
    }, 300);
    let correctItems = gameScore;
  };

  const restartGame = () => {
    setImageData(props.getArrayOfImages());
    setStart(false);
    setCounter(3);
  };

  useEffect(() => {
    shuffleCards();
    detailedResults.current.setTable(cardDefs);
  }, [imageData]);

  const shuffleCards = () => {
    setImagesForGame(
      imageData.concat(imageData.map((item) => item)).sort(() => {
        return 0.5 - Math.random();
      })
    );
    imageData.forEach((x, index) => {
      cardDefs.push(new CardDef(x, cardDefs.length));
      cardDefs.push(new CardDef(x, cardDefs.length));
    });
    shuffleArray(cardDefs);
    let cardDefsWithUpdatedIndex: CardDef[] = cardDefs.map((x, index) => {
      return {
        ...x,
        index: index,
      };
    });
    setCardDefs(cardDefsWithUpdatedIndex);
    //console.log(cardDefsWithUpdatedIndex);
    gameScore.score = POINTS_PER_CARD * cardDefs.length;
  };

  // sort(() => { return 0.5 - Math.random();})) only swaps next to each other not really randomly shuffling (most of the cards end up close to their original position)
  function shuffleArray(array: CardDef[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  return (
    <Fragment>
      {!winner ? (
        <section className="vh-100">
          <h3 className="d-flex align-items-center justify-content-center">
            {t("games.memory_cards.find_pairs")}
          </h3>

          <div className="d-flex align-items-center justify-content-center">
            <Grid
              cards={cardDefs}
              checkCards={checkCards}
              moveCount={moveCount}
              setMoveCount={setMoveCount}
              level={props.level}
            />
          </div>
        </section>
      ) : (
        <Scorecard
          showCorrectSequences={false}
          showCorrectnessPercentage={true}
          showLenghtOfSequence={false}
          showAverageTimeMs={false}
          showCorrectMoves={false}
          gameScore={gameScore}
          restartGame={restartGame}
          detailedResults={JSON.stringify(detailedResults.current, replacer)}
        />
      )}
    </Fragment>
  );
};

export default Game;

import * as React from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

import "./style.css";

import g2h1 from "../../images/G2H1.png";
import { useLocation } from "react-router";

import {handleUpdateAppWithUserWeeklyScheduleGames} from "../../helpers/routes";
import {useUserInfo} from "../../contexts/user-info-context";

const MemorySpatialSpeedInstructions = () => {
  const { t } = useTranslation("common");
  const location: any = useLocation();
  const {noSchedule, updateCurrentUserInfoWithGameSchedule} = useUserInfo();

  return (
    <section className="py-5 h-100">
      <div className="container h-100">
        <Card className="card-style">
          <Card.Body>
            <Card.Title className="text-center title-style">{t("games.titles.memory_cards")}</Card.Title>
            <Card.Text className="introduction-text-style">{t("games.memory_cards.instructions")}</Card.Text>
            <Card.Img className="card-image-memory" src={`${g2h1}`} />
            <Card.Text className="introduction-text-style">{t("games.instructions_difficulty")}</Card.Text>
          </Card.Body>
        </Card>
      </div>
      <div className="text-center mt-5">
        <Link to="/" className="m-3 text-center">
          <Button variant="outline-secondary" size="lg" onClick={() => handleUpdateAppWithUserWeeklyScheduleGames(updateCurrentUserInfoWithGameSchedule, noSchedule)}>
            {t("common.close")}
          </Button>
        </Link>
        <Link to="/episodic-memory-spatial-speed" state={{ isSchedule: location.state.isSchedule }} className="m-3 text-center">
          <Button className="m-3 text-center" variant="outline-secondary" size="lg">
            {t("common.play")}
          </Button>
        </Link>
      </div>
    </section>
  );
};

export default MemorySpatialSpeedInstructions;

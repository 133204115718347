import * as React from "react";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import {
  getUnscheduledGameResults,
  loadGamingStatistics,
} from "../../helpers/routes";
import { GameplayReportForWeek } from "../../models/game-play-report-for-week";
import { useUserInfo } from "../../contexts/user-info-context";
import "react-circular-progressbar/dist/styles.css";
import "./style.css";

import { Link } from "react-router-dom";
import LineChart from "../line-chart";
import { WeekResults } from "../../helpers/motivation-message-helpers";
import { addWeeks, max } from "date-fns";

const GamingStatistics = () => {
  const { t } = useTranslation("common");
  const [weekReport, setweekReport] = useState<
    GameplayReportForWeek | undefined
  >(undefined);
  const [dailyGamingPercentage, setDailyGamingPercentage] = useState(0);
  const [weeklyGamingPercentage, setWeeklyGamingPercentage] = useState(0);
  const [weekResults, setWeekResults] = useState<WeekResults[]>([]);

  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const { currentUserInfo } = useUserInfo();

  useEffect(() => {
    if (currentUserInfo != null) {
      (async () => {
        const token = await getAccessTokenSilently();
        let weekReport = await loadGamingStatistics(
          token,
          currentUserInfo.id,
          currentUserInfo.languageCulture.languageCode
        );

        let previousWeekResults =
          currentUserInfo.testSchedule.weekSchedules.filter(
            (x: any) => new Date(x.startsAt) < new Date()
          );
        previousWeekResults.map((x: any) => {
          setWeekResults((prevState) => [
            ...prevState,
            new WeekResults({
              week: x.week,
              playedPercentage: x.playedPercentage,
              startsAt: x.startsAt,
              endsAt: x.endsAt,
            }),
          ]);
        });

        let unscheduledGameResults = await getUnscheduledGameResults(
          token,
          currentUserInfo.id
        );

        setWeekResults((previousState) => [
          ...previousState,
          ...unscheduledGameResults,
        ]);

        setweekReport(weekReport);
        setWeeklyGamingPercentage(weekReport.weeklyCompletionPercentage);
        setDailyGamingPercentage(weekReport.todaysCompletionPercentage);
      })();
    }
  }, [currentUserInfo]);

  const toMinutesAndSeconds = (playingTimeSeconds: number) => {
    let minutes = Math.floor(playingTimeSeconds / 60);
    let seconds = playingTimeSeconds - minutes * 60;
    return seconds === 0
      ? minutes + " min"
      : minutes + " min " + seconds + " s";
  };

  const getEndDate = () => {
    let scheduledWeeksEndDateString =
      currentUserInfo.testSchedule.weekSchedules[
        currentUserInfo.testSchedule.weekSchedules.length - 1
      ]?.endsAt ?? addWeeks(new Date(), 24).toString();
    let scheduledOrUnscheduledWeeksEndDateString =
      weekResults[weekResults.length - 1]?.endsAt ??
      new Date(1900, 1, 1).toString();
    return max([
      new Date(scheduledWeeksEndDateString),
      new Date(scheduledOrUnscheduledWeeksEndDateString),
    ]);
  };

  return (
    <section className="py- 2 vh-100">
      <div className="container">
        <Card className="card-style">
          <Card.Body>
            <Card.Title className="text-center font-style mb-4">
              cTRAIN
            </Card.Title>
            <div className="d-flex flex-row justify-content-around">
              <div>
                <h2 className="m-3 text-center font-style-1">
                  {t("games.score_card.daily_target")}
                </h2>
                <CircularProgressbar
                  className="stats-circular-progress-bar"
                  value={dailyGamingPercentage}
                  text={`${dailyGamingPercentage}%`}
                  styles={buildStyles({
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "butt",
                    // Text size
                    textSize: "20px",
                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 0.5,
                    // Can specify path transition in more detail, or remove it entirely
                    // pathTransition: 'none',
                    // Colors
                    pathColor: "lime",
                    textColor: "black",
                    trailColor: `#3e98c7`,
                  })}
                />
                {weekReport ? (
                  <p className="m-3 text-center font-style-2">
                    {toMinutesAndSeconds(weekReport.todaysPlayingTimeSeconds)} /{" "}
                    {toMinutesAndSeconds(
                      weekReport.dailyTargetPlayingTimeSeconds
                    )}
                  </p>
                ) : null}
              </div>

              <div>
                <h2 className="m-3 text-center font-style-1">
                  {t("games.score_card.weekly_target")}
                </h2>
                <CircularProgressbar
                  className="stats-circular-progress-bar"
                  value={weeklyGamingPercentage}
                  text={`${weeklyGamingPercentage}%`}
                  styles={buildStyles({
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "butt",

                    // Text size
                    textSize: "20px",

                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 0.5,

                    // Can specify path transition in more detail, or remove it entirely
                    // pathTransition: 'none',

                    // Colors
                    pathColor: "Gold",
                    textColor: "black",
                    trailColor: `#3e98c7`,
                  })}
                />

                {weekReport ? (
                  <p className="m-3 text-center font-style-2">
                    {toMinutesAndSeconds(weekReport.totalPlayingTimeSeconds)} /{" "}
                    {toMinutesAndSeconds(
                      weekReport.weeklyTargetPlayingTimeSeconds
                    )}
                  </p>
                ) : null}
                {weekReport ? (
                  <p className="m-3 text-center font-style-2">
                    {weekReport.daysPlayedThisWeek} /{" "}
                    {weekReport.daysPlayedTarget} {t("games.score_card.days")}
                  </p>
                ) : null}
                {weekReport && weekReport.trainingWeekNumber > 0 ? (
                  <p className="m-3 text-center font-style-2">
                    {weekReport.trainingWeekNumber} /{" "}
                    {weekReport.totalTrainingWeeks}{" "}
                    {t("games.score_card.scheduled_week")}
                  </p>
                ) : null}
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="div-style-chart">
        <LineChart weekResults={weekResults} endDate={getEndDate()} />
      </div>
      <div className="text-center mt-5">
        <Link to="/" className="m-3 text-center">
          <Button variant="outline-secondary" size="lg">
            {t("common.close")}
          </Button>
        </Link>
      </div>
    </section>
  );
};

export default GamingStatistics;

export type QuestionType = "HighLowLeft" | "EvenOddLeft" | "HighLowRight" | "EvenOddRight";

export default class MentalAnswer {
  value: number;
  correct: boolean;
  type: QuestionType;
  reactionTimeMs: number;

  constructor(value: number, correct: boolean, type: QuestionType) {
    this.value = value;
    this.type = type;
    this.correct = correct;
    this.reactionTimeMs = 0;
  }
}

import { GameType } from "../models/game-type";
import GameScore from "../models/game-result";
import { GameplayReportForWeek } from "../models/game-play-report-for-week";
import { LanguageCulture } from "../models/language-culture";
import { WeekResults } from "./motivation-message-helpers";

export const loadGameResultsFromBackend = async (
  token: any,
  gameType: GameType,
  userId: number,
  level: number,
  topX: number = 10
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let res = await fetch(
    process.env.REACT_APP_API_SERVER_URL +
      "/scores/" +
      gameType +
      "/user/" +
      userId +
      "/level/" +
      level +
      "/" +
      topX,
    requestOptions
  );
  let resJson = await res.json();
  let results = resJson.map((x: any) => new GameScore(x));
  return results;
};

export const postGameDetails = async (
  token: any,
  gameType: GameType,
  userId: number,
  details: string
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: details,
  };
  let res = await fetch(
    process.env.REACT_APP_API_SERVER_URL +
      "/scores/details/users/" +
      userId +
      "/" +
      gameType,
    requestOptions
  );
  let status = res.status;
  return status;
};

export const loadLevelUpInfoFromBackend = async (
  token: any,
  gameType: GameType,
  userId: number,
  level: number
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let res = await fetch(
    process.env.REACT_APP_API_SERVER_URL +
      "/scores/" +
      gameType +
      "/" +
      userId +
      "/" +
      level,
    requestOptions
  );
  let resJson = await res.json();
  //let results = resJson.map((x: any) => new GameScore(x));
  return resJson.levelUp;
};

export const loadUserDataForAllGames = async (token: any, userId: number) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const url =
    process.env.REACT_APP_API_SERVER_URL + `/users/${userId}/gameData`;

  let res = await fetch(url, requestOptions);
  let resJson = await res.json();
  return resJson;
};

export const loadGamingStatistics = async (
  token: any,
  userId: number,
  lang: LanguageCulture
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const url =
    process.env.REACT_APP_API_SERVER_URL +
    `/user/${userId}/gaming-statistics/${lang}`;

  let res = await fetch(url, requestOptions);
  let resJson = await res.json();
  let playdata = new GameplayReportForWeek(resJson);
  return playdata;
};

export const getUnscheduledGameResults = async (token: any, userId: number) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const url =
    process.env.REACT_APP_API_SERVER_URL +
    `/user/${userId}/gaming-statistics/unscheduled`;

  let res = await fetch(url, requestOptions);
  let resJson = await res.json();
  let playdata: WeekResults[] = [];
  resJson.map((x: any) => playdata.push(new WeekResults(x)));
  return playdata;
};

export const loadLastWeekGamingStatistics = async (
  token: any,
  userId: number,
  lang: LanguageCulture
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const url =
    process.env.REACT_APP_API_SERVER_URL +
    `/user/${userId}/gaming-statistics/last-week/${lang}`;

  let res = await fetch(url, requestOptions);
  let resJson = await res.json();
  let playdata = new GameplayReportForWeek(resJson);
  return playdata;
};

export const handleUpdateAppWithUserWeeklyScheduleGames = (
  updateCurrentUserInfoWithGameSchedule: any,
  noSchedule: any
) => {
  if (noSchedule) {
    console.log("Heelo from memory");
    updateCurrentUserInfoWithGameSchedule!();
  }
};

import { initReactI18next } from "react-i18next";
import i18next, { i18n as i18nInstance } from "i18next";
import { resources } from "./i18n-constants";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-http-backend";

const options = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng",
};

const createI18n = (): i18nInstance => {
  const i18n = i18next.createInstance().use(initReactI18next);

  i18n
    .use(XHR)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      //lng: language,
      defaultNS: "common",
      resources: resources,
      fallbackLng: "en",
      detection: options,
      //detection: {
      //order: ["localStorage", "navigator"],
      //caches: ["localStorage"],
      //},
      keySeparator: ".",
      lowerCaseLng: false,
      debug: true,
      interpolation: { escapeValue: false },
      react: {
        bindI18n: "loaded languageChanged",
        bindI18nStore: "added",
        useSuspense: true,
      },
    });
  return i18n;
};

export const i18n = createI18n();

import * as React from "react";
import { Fragment, useEffect, useState } from "react";
import { LightCoord } from "./light-coord";
import "./style.css";
import RoundButton from "./round-button";

interface CoordSetup {
  size: number;
  maxLength: number;
  disabled: boolean;
  answered: LightCoord[];
  correct: LightCoord[];
  addAnswered: (coordinate: LightCoord) => void;
}

const MultiSelectLightGrid = (props: CoordSetup) => {
  const [answered, setAnswered] = useState<LightCoord[]>(props.answered);

  const clickedLocation = (xloc: number, yloc: number) => {
    //console.log("clicked", xloc, yloc);
    if (answered.length < props.maxLength && !isAnswered(xloc, yloc)) {
      props.addAnswered(new LightCoord(xloc, yloc));
      setAnswered((prevState) => [...prevState, new LightCoord(xloc, yloc)]);
    }
  };

  const getStyle = () => {
    return "align-items-center justify-content-center light-grid-row light-items-center light-grid-row-" + props.size + "x" + props.size;
  };

  const isAnswered = (x: number, y: number) => {
    let index = answered.findIndex((z) => z.x === x && z.y === y);
    return index !== -1;
  };

  const isCorrect = (x: number, y: number) => {
    return false;
  };

  useEffect(() => {}, []);

  const rows = (column: number) =>
    Array.from({ length: props.size }, (_, index) => {
      return (
        <RoundButton
          className=""
          size={props.size}
          clicked={() => clickedLocation(index, column)}
          active={isAnswered(index, column)}
          disabled={props.disabled}
          isCorrect={() => isCorrect(index, column)}
          key={index}
          text=""
        ></RoundButton>
      );
    });

  const columns = Array.from({ length: props.size }, (_, index) => {
    return (
      <div className={getStyle()} key={index}>
        {rows(index)}
      </div>
    );
  });

  return (
    <Fragment>
      <div className="container light-grid-center">{columns}</div>
    </Fragment>
  );
};

export default MultiSelectLightGrid;

import * as React from "react";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router";

import { TripletData, TripletsColor } from "../../models/triplets";
import { ActiveTab } from "../../models/active-tab";
import { GameLevelsData } from "../../models/game-level-data";
import { UserDataForGame } from "../../models/user-data-for-game";

import { useActiveTab } from "../../contexts/active-tab-context";
import { useUserDataForGame } from "../../contexts/user-data-for-game-context";

import { getGameLevelData, MAX_LEVEL, numberOfQuestions, displayTimeMs, getNumberOfOptions, WORD_SETS } from "../../helpers/episodic-memory-relational-helpers";

import Game from "./game";
import CountDown from "../../components/count-down";

import "./style.css";
import {handleUpdateAppWithUserWeeklyScheduleGames} from "../../helpers/routes";
import {useUserInfo} from "../../contexts/user-info-context";

const EpisodicMemoryRelational: React.FunctionComponent = (dto: any) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const location: any = useLocation();

  const { activeTab } = useActiveTab();
  const { userDataForGames, updateUserDataForGames } = useUserDataForGame();
  const {updateCurrentUserInfoWithGameSchedule, noSchedule} = useUserInfo();

  const [tripletData, setTripletData] = useState<TripletData[]>([]);
  const [colorDefinitions, setColorDefinitions] = useState<TripletsColor[]>([]);
  const [start, setStart] = useState<boolean>(false);
  const [intervalDuration, setIntervalDuration] = useState<number>(0);
  const [level, setLevel] = useState<number>(0);
  const { t, i18n } = useTranslation("common");
  const [randomWordSet, setRandomWordSet] = useState<string>(WORD_SETS[Math.floor(Math.random() * WORD_SETS.length)]);

  const [resultSent, setResultSent] = useState<boolean>(false);

  const [counter, setCounter] = useState<number>(3);

  const [userDataForTheGame, setUserDataForTheGame] = useState<UserDataForGame>(new UserDataForGame(dto));
  const [levelButtonsForTheGame, setLevelButtonsForTheGame] = useState<GameLevelsData[]>([new GameLevelsData()]);

  const loadTriplets = useCallback(async () => {
    const lang = i18n.language;
    const token = !isAuthenticated ? localStorage.getItem(process.env.REACT_APP_TOKEN_NAME!) : await getAccessTokenSilently();
    const requestOptions = {
      method: "GET",
      headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
    };
    let res = await fetch(process.env.REACT_APP_API_SERVER_URL + "/triplets-data/" + lang, requestOptions);
    let resJson = await res.json();
    setTripletData(resJson);
  }, []);

  const loadColorDefinitions = useCallback(async () => {
    const lang = i18n.language;
    const token = !isAuthenticated ? localStorage.getItem(process.env.REACT_APP_TOKEN_NAME!) : await getAccessTokenSilently();
    const requestOptions = {
      method: "GET",
      headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
    };
    let res = await fetch(process.env.REACT_APP_API_SERVER_URL + "/triplets-data/colors/" + lang, requestOptions);
    let resJson = await res.json();
    setColorDefinitions(resJson);
  }, []);

  useEffect(() => {
    loadColorDefinitions();
    loadTriplets();
  }, [loadTriplets, loadColorDefinitions]);
  
  // useEffect(() => {
  //   console.log({tripletData});
  //   //console.log({colorDefinitions});
  // },[tripletData, colorDefinitions])

  useEffect(() => {
    if (userDataForGames != undefined || userDataForGames != null) {
      setUserDataForTheGame(userDataForGames!.find((x: any) => x.gameType === "EpisodicMemoryRelational"));
    }
  }, [userDataForGames]);

  useEffect(() => {
    if (userDataForTheGame) {
      setLevelButtonsForTheGame(getGameLevelData(activeTab, userDataForTheGame!.levelAchieved));
    }
  }, [userDataForTheGame]);

  const handleGameLevelButton = (buttonTittle: string, level: number) => {
    setIntervalDuration(displayTimeMs(level, levelButtonsForTheGame)!);
    setLevel(level);
    setResultSent(false);
    setStart(true);
  };

  if (start) {
    return <CountDown counter={counter} setCounter={setCounter} setStart={setStart} />;
  }

  return (
    <Fragment>
      <div className="container py-5 h-100">
        <section className="vh-100">
          <div className="container align-items-center">
            {counter === 0 ? (
              <Game
                tripletData={tripletData}
                colorDefinitions={colorDefinitions}
                intervalDuration={intervalDuration}
                level={level}
                setCounter={setCounter}
                setStart={setStart}
                levelButtonsForTheGame={levelButtonsForTheGame}
              />
            ) : (
              <div className="d-flex flex-column justify-content-between">
                <h2 className="text-center mb-4">{t("games.select_difficulty_level")}</h2>
                <div className="container h-100 mb-5">
                  <Row xs={2} md={3} className="g-4">
                    {levelButtonsForTheGame.map((button, index) => (
                      <Col key={index} className="btn btn-light">
                        <Card
                          className={`card-style ${button.active ? "" : "disabled-link"}`}
                          onClick={button.active ? () => handleGameLevelButton(button.title, button.level) : () => {}}
                          role="button"
                        >
                          <Card.Img variant="top" className="card-image-top" src={`${button.image}`}></Card.Img>
                          <Card.Title className="text-center title-style">{t(button.title)}</Card.Title>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
                <div className="text-center mt-5">
                  <Link to="/" className="m-3 text-center">
                    <Button variant="outline-secondary" size="lg" onClick={() => handleUpdateAppWithUserWeeklyScheduleGames(updateCurrentUserInfoWithGameSchedule, noSchedule)}>
                      {" "}
                      {t("common.close")}
                    </Button>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default EpisodicMemoryRelational;

export const RANDOM_NUMBER_TO_SHOW = [1, 2, 3, 4, 6, 7, 8, 9];
export const RANDOM_COLUMN_NUMBER = [0, 1];
export const RANDOM_ROW_NUMBER = [0, 1];

export const generateRandomNumber = () => {
  return RANDOM_NUMBER_TO_SHOW[Math.floor(Math.random() * RANDOM_NUMBER_TO_SHOW.length)];
};

export const generateRandomColumnNumber = () => {
  return RANDOM_COLUMN_NUMBER[Math.floor(Math.random() * RANDOM_COLUMN_NUMBER.length)];
};

export const generateRandomRowNumber = () => {
  return RANDOM_ROW_NUMBER[Math.floor(Math.random() * RANDOM_ROW_NUMBER.length)];
};

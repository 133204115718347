import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GameType } from "../../../models/game-type";
import { SHOW_ITEM_TIME_MS } from "./index";
import { compareArrays, GAME_LENGTH_SEQUENCES, LAST_N, SequenceAnswer, shuffleArray } from "../../../helpers/updating-items-helpers";

const UpdatingTexts = (props: {
  sessionSequence: string[];
  completePhase: (score: SequenceAnswer) => void;
  startTiming: () => void;
  key: number;
  sequenceNumber: number;
  gameType: GameType;
}) => {
  const [randomSequenceOfSession, setRandomSequenceOfSession] = useState<string[]>(props.sessionSequence);
  const [index, setIndex] = useState<number>(0);
  const [arrayOfLastElements, setArrayOfLastElements] = useState<any[]>(randomSequenceOfSession.slice(-4));
  const [shuffleItems, setShuffleItems] = useState<any[]>(shuffleArray(arrayOfLastElements.slice(0)));
  const [pickedElements, setPickedElements] = useState<string[]>([]);
  const [disableButtonIndex, setDisabledButtonIndex] = useState<number[]>([]);

  const [score, setScore] = useState<number>(0);
  const [reset, setReset] = useState<boolean>(false);
  const [timingSet, setTimingSet] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const { t } = useTranslation("common");

  const handleIndex = () => {
    setIndex(index === randomSequenceOfSession.length ? 0 : index + 1);
  };

  useEffect(() => {
    if (index < randomSequenceOfSession.length) {
      setTimeout(handleIndex, SHOW_ITEM_TIME_MS);
    } else if (!timingSet) {
      props.startTiming();
      setTimingSet(true);
    }
  });

  useEffect(() => {
    setScore(compareArrays(arrayOfLastElements, pickedElements));
  }, [pickedElements, score]);

  const currentItem = randomSequenceOfSession[index];

  const onPickedElements = (pickedItem: string, index: number) => {
    setDisabledButtonIndex((prevState) => [...prevState, index]);
    setPickedElements((prevState) => [...prevState, pickedItem]);
    setReset(false);
  };

  const resetPickedElements = () => {
    pickedElements.splice(0, pickedElements.length);
    setReset(true);
    setSubmit(false);
    setDisabledButtonIndex([]);
  };

  const onSubmitAnswer = () => {
    let sequence = new SequenceAnswer();
    sequence.sequenceOfTheSession = [...randomSequenceOfSession];
    sequence.answeredSequence = [...pickedElements];
    sequence.correctSequence = [...arrayOfLastElements];
    props.completePhase(sequence);
    setSubmit(true);
    setTimingSet(false);
  };

  return (
    <section>
      <div className="container h-100">
        <div className="row d-flex flex-column align-items-center h-100">
          {index > randomSequenceOfSession.length - 1 ? null : (
            <div className="col">
              <div className="d-flex flex-row justify-content-center">
                <h3>
                  {t("games.updating_items.memorize_last_four_items_shown", {
                    items_plural_partitive: t("common.item_type_from_game_type_partitive." + props.gameType),
                  })}
                </h3>
              </div>
              <div className="d-flex justify-content-center align-items-center h-100 mt-4">
                {props.gameType !== "UpdatingColor" ? (
                  <h1 style={{ fontSize: "300px" }}>{currentItem}</h1>
                ) : (
                  <div className="color-box" style={{ backgroundColor: `${currentItem}` }} />
                )}
              </div>
              <div className="d-flex flex-row justify-content-end">
                <h4>
                  {t("games.updating_items.sequence")}: {props.sequenceNumber + 1}/{GAME_LENGTH_SEQUENCES}
                </h4>
              </div>
            </div>
          )}

          {index > randomSequenceOfSession.length - 1 ? (
            <div>
              <div className="d-flex flex-row justify-content-center">
                <h3 className={`mt-5`}>
                  {t("games.updating_items.select_last_four_items_shown", {
                    items_plural_partitive: t("common.item_type_from_game_type_partitive." + props.gameType),
                  })}
                </h3>
              </div>
              <div className="d-flex flex-row justify-content-center">
                {shuffleItems.map((shuffleItem, index) => {
                  return (
                    <div
                      className={`box-style-lg box-style-md box-style-sm box-style-xs m-lg-2 m-md-2 m-sm-2 ${
                        disableButtonIndex.includes(index) ? "disabled-button" : ""
                      }`}
                      role="button"
                      onClick={() => onPickedElements(String(shuffleItem), index)}
                      key={index}
                      picked-element={shuffleItem}
                      style={props.gameType === "UpdatingColor" ? { backgroundColor: `${shuffleItem}` } : {}}
                    >
                      {props.gameType === "UpdatingColor" ? "" : shuffleItem}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}

          {pickedElements.length > 0 ? (
            <div>
              <div className="d-flex flex-row justify-content-center">
                <h3 className={`mt-5 text-style-lg text-style-md text-style-sm text-style-xs`}>
                  {t("games.updating_items.picked_items", {
                    items: t("common.item_type_from_game_type_partitive." + props.gameType),
                  })}
                  :
                </h3>
              </div>
              {props.gameType !== "UpdatingColor" ? (
                <div className="d-flex flex-row justify-content-center">
                  {reset
                    ? ""
                    : pickedElements.map((pickedElement, index) => (
                        <div className="bg-gradient box-style-2 m-3" key={index}>
                          {pickedElement}
                        </div>
                      ))}
                </div>
              ) : (
                <div className="d-flex flex-row justify-content-center">
                  {reset
                    ? ""
                    : pickedElements.map((pickedElement, index) => (
                        <div className="box-style-2 m-3" key={index} style={{ backgroundColor: `${pickedElement}` }} />
                      ))}
                </div>
              )}
              {
                <div className="d-flex flex-row  justify-content-center">
                  <button className="button-clear m-4" onClick={resetPickedElements}>
                    {t("games.updating_items.clear")}
                  </button>
                  <button
                    className={`${pickedElements.length != LAST_N && !submit ? "button-ok-disable" : "button-ok"} m-4`}
                    onClick={() => onSubmitAnswer()}
                    disabled={pickedElements.length != LAST_N && !submit}
                  >
                    {t("games.updating_items.submit")}
                  </button>
                </div>
              }
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </section>
  );
};

export default UpdatingTexts;

import * as React from "react";
import { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion, faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import {loadGamingStatistics, loadLastWeekGamingStatistics} from "../../helpers/routes";
import { GameplayReportForWeek } from "../../models/game-play-report-for-week";
import "react-circular-progressbar/dist/styles.css";
import "./style.css";

import LanguageSwitcher from "../language-switcher/language-switcher";
import { LogoutButton } from "../buttons/logout-button";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { useUserInfo } from "../../contexts/user-info-context";

const HomeNavbar: React.FunctionComponent = (props) => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const { currentUserInfo } = useUserInfo();
  const { t } = useTranslation("common");

  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const [dailyGamingPercentage, setDailyGamingPercentage] = useState(0);
  const [weeklyGamingPercentage, setWeeklyGamingPercentage] = useState(0);
  
  const [lastWeekReport, setLastWeekReport] = useState<GameplayReportForWeek>(new GameplayReportForWeek({}));

  useEffect(() => {
    if (currentUserInfo != null) {
      setCurrentUserEmail(currentUserInfo.email);
      (async () => {
        const token = !isAuthenticated ? localStorage.getItem(process.env.REACT_APP_TOKEN_NAME!) : await getAccessTokenSilently();
        let weekReport = await loadGamingStatistics(token, currentUserInfo.id, currentUserInfo.languageCulture.languageCode);
        setWeeklyGamingPercentage(weekReport.weeklyCompletionPercentage);
        setDailyGamingPercentage(weekReport.todaysCompletionPercentage);
      })();
    }
  }, [currentUserInfo]);

    // useEffect(() => {
    //     if (currentUserInfo != null) {
    //         setCurrentUserEmail(currentUserInfo.email);
    //         (async () => {
    //             const token = !isAuthenticated ? localStorage.getItem(process.env.REACT_APP_TOKEN_NAME!) : await getAccessTokenSilently();
    //             let weekReport = await loadLastWeekGamingStatistics(token, currentUserInfo.id, currentUserInfo.languageCulture.languageCode);
    //            setLastWeekReport(weekReport);
    //         })();
    //     }
    // }, [currentUserInfo]);

  return (
      <>
        <Navbar expand="sm" variant="dark" className= "py-2" style={{ backgroundColor: "#4473AF", fontWeight: "bold" }}>
          <Container>
            <Navbar.Brand className="navbar-brand" href="#" style={{ fontSize: "1.7rem" }}>
              cTRAIN
            </Navbar.Brand>
              <div className="circular-progress-bar-center d-flex flex-row">
                  <Nav className= "navbar-brand" title={t("games.score_card.daily_target")}>
                      <Nav.Link href="#/gaming-stats">
                          <CircularProgressbar
                              className="circular-progress-bar"
                              value={dailyGamingPercentage}
                              text={`${dailyGamingPercentage} %`}
                              styles={buildStyles({
                                  strokeLinecap: "butt",
                                  pathTransitionDuration: 0.5,
                                  pathColor: "lime",
                                  textColor: "white",
                                  trailColor: "#3e98c7",
                                  textSize: "25px"
                              })}
                          />
                      </Nav.Link>
                  </Nav>
                  <Nav className= "navbar-brand" title={t("games.score_card.weekly_target")}>
                      <Nav.Link href="#/gaming-stats">
                          <CircularProgressbar
                              className="circular-progress-bar"
                              value={weeklyGamingPercentage}
                              text={`${weeklyGamingPercentage} %`}
                              styles={buildStyles({
                                  strokeLinecap: "butt",
                                  textSize: "25px",
                                  pathTransitionDuration: 0.5,
                                  pathColor: "Gold",
                                  textColor: "white",
                                  trailColor: "#3e98c7",
                              })}
                          />
                      </Nav.Link>
                  </Nav>
              </div>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                      <Nav className="me-auto" />
                      <Nav className="m-lg-3 mt-lg-4 mt-sm-2" style={{ display: "flex", flexDirection: "row", marginLeft: "0.8rem" }} title={t("common.about")}>
                          <Link to="/introduction" className="link-style">
                              <FontAwesomeIcon className="float-end text-white" icon={faCircleQuestion} size="2x" />
                          </Link>
                      </Nav>
                      <Nav className="m-lg-3 mt-lg-4 mt-sm-2" title={t("common.language_change")}>
                          <LanguageSwitcher />
                      </Nav>

                      <Nav className="m-lg-3 mt-lg-4 mt-sm-2" title={t("common.user")}>
                          <DropdownButton title={<FontAwesomeIcon className="float-end text-white" icon={faCircleUser} size="2x" />} variant="#4473AF">
                              <Dropdown.Item>{user?.email}</Dropdown.Item>
                              {!isAuthenticated ? <Dropdown.Item>{currentUserEmail}</Dropdown.Item> : null}
                              <Dropdown.Item style={{ color: "black" }}>
                                  {" "}
                                  <LogoutButton />
                              </Dropdown.Item>
                              {/*<Dropdown.Item style={{color: "black"}}> {!currentUserIsAuthenticated ? null : <LogoutButton />}</Dropdown.Item>  */}
                          </DropdownButton>
                      </Nav>
                  </Navbar.Collapse>
          </Container>
        </Navbar>
          {/*New navbar with bootstrap*/}
      </>
    
  );
};

export default HomeNavbar;

import * as React from "react";
import {useEffect, useState} from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import { i18n } from "../../i18n/i18n";
import { NUMBER_OF_QUESTIONS } from "./game";
import g6h1 from "../../images/G6H1.png";
import g6h2_eng from "../../images/G6H2-ENG.png";
import g6h2_fin from "../../images/G6H2-FIN.png"
import g6h2_ger from "../../images/G6H2-GER.png"
import g6h2_ita from "../../images/G6H2-ITA.png"
import g6h2_swe from "../../images/G6H2-SWE.png"
import g6h3 from "../../images/G6H3.png";
import g6h4 from "../../images/G6H4.png";
import {handleUpdateAppWithUserWeeklyScheduleGames} from "../../helpers/routes";
import {useUserInfo} from "../../contexts/user-info-context";


const MentalSetShiftingInstructions = () => {
  const { t } = useTranslation("common");
  const location: any = useLocation();
  const {noSchedule, updateCurrentUserInfoWithGameSchedule} = useUserInfo();
  //const isSchedule = location.state.isSchedule
  const [imageByLang, setImageByLang] = useState<string>("");
  
  useEffect(() => {
    switch (i18n.language) {
      case "fi" : {
        setImageByLang(g6h2_fin);
        break;
      }
      case "sv": {
        setImageByLang(g6h2_swe);
        break;
      }
      case "it": {
        setImageByLang(g6h2_ita);
        break;
      }
      case "de": {
        setImageByLang(g6h2_ger);
        break;
      }
      default: {
        setImageByLang(g6h2_eng);
        break;
      }
    }
  },[i18n.language])

  return (
    <section className="py-5 h-100">
      <div className="container h-100">
        <Card className="card-style">
          <Card.Body>
            <Card.Title className="text-center title-style">{t("games.titles.mental_shifting")}</Card.Title>
            <Card.Text className="introduction-text-style">{t("games.mental_shifting.instructions_overall")}</Card.Text>
            <Card.Text className="introduction-text-style ">
              <span className="m-4">
                <img className="card-image-top-1" src={`${g6h3}`} alt="left-arrow" /> {t("games.mental_shifting.instructions_if_less")}
                {t("games.mental_shifting.instructions_top_row")}
              </span>
              <br></br>
              <span className="m-4">
                <img className="card-image-top-1" src={`${g6h4}`} alt="right-arrow" /> {t("games.mental_shifting.instructions_if_greater")}
                {t("games.mental_shifting.instructions_top_row")}
              </span>
              <br></br>
              <span className="m-4">
                <img className="card-image-top-1" src={`${g6h3}`} alt="left-arrow" /> {t("games.mental_shifting.instructions_if_odd")}
                {t("games.mental_shifting.instructions_bottom_row")}
              </span>
              <br></br>
              <span className="m-4">
                <img className="card-image-top-1" src={`${g6h4}`} alt="right-arrow" /> {t("games.mental_shifting.instructions_if_even")}
                {t("games.mental_shifting.instructions_bottom_row")}
              </span>
            </Card.Text>
            <Card.Text className="introduction-text-style">{t("games.mental_shifting.examples")}: </Card.Text>
            <Card.Img className="card-image-top" src={`${g6h1}`} />
            <Card.Img className="card-image-top" src={`${imageByLang}`} />

            <Card.Text className="introduction-text-style">{t("games.mental_shifting.instructions_speed", { number_of_tasks: NUMBER_OF_QUESTIONS })}</Card.Text>
          </Card.Body>
        </Card>
      </div>
      <div className="text-center">
        <Link to="/" className="m-3 text-center">
          <Button variant="outline-secondary" size="lg" onClick={() => handleUpdateAppWithUserWeeklyScheduleGames(updateCurrentUserInfoWithGameSchedule, noSchedule)}>
            {t("common.close")}
          </Button>
        </Link>
        <Link to="/mental-set-shifting" state={{ isSchedule: location.state.isSchedule }} className="m-3 text-center">
          <Button className="m-3 text-center" variant="outline-secondary" size="lg">
            {t("common.play")}
          </Button>
        </Link>
      </div>
    </section>
  );
};

export default MentalSetShiftingInstructions;

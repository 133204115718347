import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";

import g6h3 from "../../images/G6H3.png";
import g6h4 from "../../images/G6H4.png";
import "./style.css";

import ScoreCard from "../../components/scorecard";

import GameScore from "../../models/game-result";
import { GameLevelsData } from "../../models/game-level-data";

import { useKeyPress } from "../../hooks/use-key-press";
import { useUserInfo } from "../../contexts/user-info-context";
import { useActiveTab } from "../../contexts/active-tab-context";
import { useUserDataForGame } from "../../contexts/user-data-for-game-context";
import { Level } from "../light-up";
import {handleUpdateAppWithUserWeeklyScheduleGames} from "../../helpers/routes";

interface props {
  shapesSequenceForGame: string[];
  setStart: React.Dispatch<React.SetStateAction<boolean>>;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
  setGameSelectionLevel: React.Dispatch<React.SetStateAction<number>>;
  level: number;
  taskLength: number;
  levelButtonsForTheGame: GameLevelsData[];
}

class ShapeMatchAnswer {
  shapePath: string;
  userAnsweredSame: boolean;
  wasSame: boolean;
  correct: boolean;
  reactionTimeMs: number;

  constructor(shapePath: string, userAnsweredSame: boolean, wasSame: boolean, correct: boolean) {
    this.shapePath = shapePath;
    this.userAnsweredSame = userAnsweredSame;
    this.wasSame = wasSame;
    this.correct = correct;
    this.reactionTimeMs = 0;
  }
}

class DetailedResults {
  email: string;
  score: number;
  level: number;
  initialShapePath: string;
  totalCorrectAnswers: number;
  totalTimeSeconds: number;
  answers: ShapeMatchAnswer[];
  constructor(initialShapePath: string, score: GameScore, answers: ShapeMatchAnswer[]) {
    this.level = score.level === null ? 0 : score.level!;
    this.email = score.email;
    this.score = score.score;
    this.initialShapePath = initialShapePath;
    this.totalCorrectAnswers = score.percentage!;
    this.totalTimeSeconds = score.totalTimeSeconds;
    this.answers = answers;
  }
}

const getRandomImage = (images: string[]) => {
  return images[Math.floor(Math.random() * images.length)];
};

const Game = (props: props) => {
  const MAX_ANSWERING_TIME_MS = 10000;
  const WAIT_INTERVAL_MS = 375;

  const { t } = useTranslation("common");
  const { user } = useAuth0();
  const { currentUserInfo, noSchedule, updateCurrentUserInfoWithGameSchedule } = useUserInfo();
  const { activeTab } = useActiveTab();
  const { userDataForGames, updateUserDataForGames } = useUserDataForGame();

  const { shapesSequenceForGame, setStart, setCounter, level, taskLength, setGameSelectionLevel, levelButtonsForTheGame } = props;
  const [index, setIndex] = useState<number>(0);
  const [givenAnswers, setGivenAnswers] = useState<ShapeMatchAnswer[]>([]);
  const [countAnswer, setCountAnswer] = useState<number>(0);
  const [startDateTime, setStartDateTime] = useState<Date>(new Date());
  const [totalScore, setTotalScore] = useState<number>(0);

  const [gameScore, setGameScore] = useState<GameScore>({
    correctItems: 0,
    email: currentUserInfo.email,
    isScheduledGame: activeTab === "Training",
    userId: currentUserInfo.id,
    score: 0,
    totalTimeSeconds: 0,
    averageTimeMs: 0,
    gameType: "ShapeMatchMentalSpeed",
    level: level,
    percentage: 0,
    maxLevel: 3,
  });

  const leftKeyPress: boolean = useKeyPress("ArrowLeft");
  const rightKeyPress: boolean = useKeyPress("ArrowRight");

  const [isWaitToShow, setIsWaitToShow] = useState(false);
  const [keyPressed, setKeyPressed] = useState<boolean>(false);

  const handleIndex = () => {
    if (!isWaitToShow) {
      const waitBeforeShow = WAIT_INTERVAL_MS;
      const timer = setTimeout(() => {
        setIndex(index + 1);
        setIsWaitToShow(false);
      }, waitBeforeShow);
      return () => clearTimeout(timer);
    }
  };

  useEffect(() => {
    if (index < 1) {
      setTimeout(handleIndex, 2000);
    }
  }, [index, isWaitToShow]);

  useEffect(() => {
    if (!isWaitToShow) {
      const waitBeforeShow = WAIT_INTERVAL_MS;
      const timer = setTimeout(() => {
        setIsWaitToShow(true);
      }, waitBeforeShow);
      return () => clearTimeout(timer);
    }
  }, [isWaitToShow]);

  useEffect(() => {
    if (index > 0) {
      if (leftKeyPress) {
        onButtonClicked("yes");
      } else if (rightKeyPress) {
        onButtonClicked("no");
      }
    }
  }, [leftKeyPress, rightKeyPress]);

  const scoreCalculations = (givenAnswers: ShapeMatchAnswer[]) => {
    let tempScore = 0;
    let maxPoints =
      levelButtonsForTheGame.find((x) => x.level === level) === undefined ? 10000 : levelButtonsForTheGame.find((x) => x.level === level)!.max_points;
    const MAX_POINTS_FOR_ANSWER = maxPoints! / givenAnswers.length;

    for (let i = 0; i < givenAnswers.length; ++i) {
      tempScore +=
        givenAnswers[i].correct && givenAnswers[i].reactionTimeMs < MAX_ANSWERING_TIME_MS
          ? (MAX_POINTS_FOR_ANSWER * (MAX_ANSWERING_TIME_MS - givenAnswers[i].reactionTimeMs)) / MAX_ANSWERING_TIME_MS
          : 0;
    }
    return Math.round(tempScore);
  };

  const updateReactionTime = () => {
    const fromStart = differenceInMilliseconds(new Date(), startDateTime);
    let fromStartToPrevious = 0;
    givenAnswers.forEach((x) => {
      fromStartToPrevious += x.reactionTimeMs;
    });
    givenAnswers[givenAnswers.length - 1].reactionTimeMs = fromStart - fromStartToPrevious;
    setCountAnswer((prevState) => prevState + 1);
    const totalTime = givenAnswers.reduce((total, obj) => obj.reactionTimeMs + total, 0);
    const correctItems = givenAnswers.map((obj, index) => obj.correct).filter(Boolean).length;

    setGameScore((prevState) => {
      return {
        ...prevState,
        score: Math.round(scoreCalculations(givenAnswers)),
        totalTimeSeconds: Math.round(totalTime / 1000),
        averageTimeMs: Math.round(totalTime / taskLength),
        correctItems: correctItems,
        numberOfItems: taskLength,
        maxLevel: levelButtonsForTheGame[levelButtonsForTheGame.length - 1].level,
        percentage: Math.round((correctItems * 100) / taskLength),
      };
    });
  };

  const restartGame = () => {
    setCounter(3);
    setStart(false);
    setGameSelectionLevel(0);
  };

  const onButtonClicked = (value: string) => {
    if (countAnswer >= taskLength) {
      return;
    }

    setIndex((prevState) => prevState + 1);
    setIsWaitToShow(false);
    const pickedAnswer = value;
    const compareShapesResult = shapesSequenceForGame[index - 1]!.localeCompare(shapesSequenceForGame[index]!);
    const userAnsweredSame = pickedAnswer === "yes";
    const wasSame = compareShapesResult === 0;

    const compareCorrectAnswer = (userAnsweredSame: boolean, wasSame: boolean) => {
      return Number(userAnsweredSame) - Number(wasSame);
    };

    const correct = compareCorrectAnswer(userAnsweredSame, wasSame) === 0;
    givenAnswers.push(new ShapeMatchAnswer(shapesSequenceForGame[index], userAnsweredSame, wasSame, correct));
    updateReactionTime();
  };

  const ShowInitialImage = () => {
    return (
      <>
        <div>
          <div className="div-style-shape">
            <img src={process.env.REACT_APP_API_SERVER_URL + `/${shapesSequenceForGame[index]}`} />
          </div>
          <div className="d-flex flex-row justify-content-around mt-4" style={{ visibility: "hidden", pointerEvents: "none" }}>
            <button id="button-event" onClick={() => onButtonClicked("yes")} className="div-button">
              <img className="img-style" src={g6h3} alt="left-arrow" draggable="false" />
              <h1 style={{ color: "black" }}>{t("games.shape-matching.yes")}</h1>
            </button>
            <button id="button-event" onClick={() => onButtonClicked("no")} className="div-button">
              <img className="img-style" src={g6h4} alt="right-arrow" draggable="false" />
              <h1 style={{ color: "black" }}>{t("games.shape-matching.no")}</h1>
            </button>
          </div>
          <hr className="hr-style" />
        </div>
        <div className="text-center mt-5">
          <Link to="/" className="m-3 text-center">
            <Button variant="outline-secondary" size="lg" onClick={updateUserDataForGames}>
              {t("common.close")}
            </Button>
          </Link>
        </div>
      </>
    );
  };

  const ShowQuestionAnswerSequences = () => {
    return (
      <>
        <div>
          <div className="div-style-shape">
            <img src={isWaitToShow ? process.env.REACT_APP_API_SERVER_URL + `/${shapesSequenceForGame[index]}` : ""} />
          </div>
          <div className="d-flex flex-row justify-content-around mt-4">
            <button id="button-event" onClick={() => onButtonClicked("yes")} className="div-button">
              <img className="img-style" src={g6h3} alt="left-arrow" draggable="false" />
              <h1 style={{ color: "black" }}>{t("games.shape-matching.yes")}</h1>
            </button>
            <button id="button-event" onClick={() => onButtonClicked("no")} className="div-button">
              <img className="img-style" src={g6h4} alt="right-arrow" draggable="false" />
              <h1 style={{ color: "black" }}>{t("games.shape-matching.no")}</h1>
            </button>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <h2>
              {t("games.task")}: {countAnswer + 1} / {taskLength}
            </h2>
          </div>
          <hr className="hr-style" />
        </div>
        <div className="text-center mt-5">
          <Link to="/" className="m-3 text-center">
            <Button variant="outline-secondary" size="lg" onClick={() => handleUpdateAppWithUserWeeklyScheduleGames(updateCurrentUserInfoWithGameSchedule, noSchedule)}>
              {t("common.close")}
            </Button>
          </Link>
        </div>
      </>
    );
  };

  return (
    <div>
      {countAnswer >= taskLength ? (
        <ScoreCard
          showCorrectSequences={false}
          showCorrectnessPercentage={true}
          showLenghtOfSequence={false}
          showAverageTimeMs={false}
          showCorrectMoves={false}
          gameScore={gameScore}
          detailedResults={JSON.stringify(new DetailedResults(shapesSequenceForGame[0], gameScore, givenAnswers))}
          restartGame={restartGame}
        />
      ) : (
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="text-style mb-3">{index === 0 ? t("games.shape-matching.remember_the_shape") : t("games.shape-matching.question")}</div>
          {index === 0 ? <ShowInitialImage /> : <ShowQuestionAnswerSequences />}
        </div>
      )}
    </div>
  );
};

export default Game;

export class TripletsItem {
    item: string;
    translation: string;
    image: string;

    constructor(dto: any) {
        this.item = dto.color;
        this.translation = dto.translation;
        this.image = dto.image;
    }
}

export class TripletsColor {
    color: string;
    translation: string;
    htmlColorName: string;

    constructor(dto: any) {
        this.color = dto.color;
        this.translation = dto.translation;
        this.htmlColorName = dto.htmlColorName;
    }
}

export class TripletData {
    word: TripletsItem;
    referencedWord: TripletsItem;
    color: TripletsColor;

    constructor(dto: any) {
        this.word = dto.word;
        this.referencedWord = dto.referencedWord;
        this.color = dto.color;
    }
}

export class TripletAnswer {
    item: string;
    referencedItem: string;
    color: string;
    colorAnswer: string;

    constructor(
        item: string,
        refItem: string,
        color: string,
        colorAnswer: string
    ) {
        this.item = item;
        this.referencedItem = refItem;
        this.color = color;
        this.colorAnswer = colorAnswer;
    }
}

import * as React from "react";
import {createContext, useContext, useEffect, useState} from "react";
import { User } from "../models/user";
import { useUserInformation } from "../hooks/user-info";
import GameSchedule from "../models/game-schedule";
import {useAuth0} from "@auth0/auth0-react";
import {useUserInfo} from "./user-info-context";
import {loadUserDataForAllGames} from "../helpers/routes";

interface UserDataForGameInterface {
    userDataForGames: any;
    updateUserDataForGames?: () => void
}

const UserInfoContext = createContext<UserDataForGameInterface>({
    userDataForGames: null
});

export const UserDataForGamesProvider = (props: { children: JSX.Element | JSX.Element[] }) => {
    const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
    const { currentUserInfo } = useUserInfo();
   
    const [userDataForGames, setUserDataForGames] = useState();
    
    useEffect(()=> {
        if(currentUserInfo !== null) {
            (async () => {
                const token = !isAuthenticated ? localStorage.getItem(process.env.REACT_APP_TOKEN_NAME!) : await getAccessTokenSilently();
                const userDataForGames = await loadUserDataForAllGames(token, currentUserInfo.id);
                setUserDataForGames(userDataForGames);
            })()
        }
    },[currentUserInfo])
    
    const updateUserDataForGames = async () => {
        const token = !isAuthenticated ? localStorage.getItem(process.env.REACT_APP_TOKEN_NAME!) : await getAccessTokenSilently();
        const userDataForGames = await loadUserDataForAllGames(token, currentUserInfo.id);
        setUserDataForGames(userDataForGames);
    }
    
    return <UserInfoContext.Provider value={{userDataForGames, updateUserDataForGames}}>{props.children}</UserInfoContext.Provider>;
};

export const useUserDataForGame = () => useContext(UserInfoContext) as UserDataForGameInterface;

export const COORD_DELIM = ",";
export class LightCoord {
  x: number = 0;
  y: number = 0;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  equal(comp: LightCoord) {
    return comp.x === this.x && comp.y === this.y;
  }
  toString() {
    return this.x.toString() + COORD_DELIM + this.y.toString();
  }
}

export const fromString = (str: string) => {
  if (str && str.length > 0) {
    var xy = str.split(COORD_DELIM);
    if (xy.length == 2) {
      return new LightCoord(Number(xy[0]), Number(xy[1]));
    }
  }
  return new LightCoord(0, 0);
};

export const randomizeNewCoord = (prev: LightCoord | null, gridSize: number) => {
  let tooMany = 100;
  while (tooMany-- > 0) {
    let randCoord = new LightCoord(Math.floor(Math.random() * gridSize), Math.floor(Math.random() * gridSize));
    if (prev === null || !randCoord.equal(prev)) {
      return randCoord;
    }
  }
  return new LightCoord(Math.floor(Math.random() * gridSize), Math.floor(Math.random() * gridSize));
};

import { useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { User } from "../models/user";
import i18next from "i18next";
import GameSchedule from "../models/game-schedule";

interface NoSchedule {
  noSchedule: boolean;
}

export const useUserInformation = () => {
  const [currentUserInfo, setCurrentUserInfo] = useState<any>(null);
  const { isAuthenticated, user, getAccessTokenSilently, logout } = useAuth0();
  const [userInfo, setUserInfo] = useState<User>();
  const [jwtToken, setJwtToken] = useState<string>();
  const [scheduleGameForUser, setScheduleGameForUser] =
    useState<GameSchedule>();
  const [noSchedule, setNoSchedule] = useState<NoSchedule>();
  const [scheduleCompleted, setScheduleCompleted] = useState(false);
  const getLanguage = () => i18next.language || window.localStorage.i18nextLng;
  const [browserLanguage, setBrowserLanguage] = useState<string>(getLanguage());

  const [gotToSignUpPage, setGoToSignUpPage] = useState<boolean>(false);
  const getUrlPath = () => window.location.href;
  const [urlPath, setUrlPath] = useState<string>(getUrlPath());
  const [tokenOrEmail, setTokenOrEmail] = useState<string>(
    urlPath.substring(urlPath.lastIndexOf("/") + 1)
  );
  const email = useRef<string>();
  const [loginSuccessful, setloginSuccessful] = useState<boolean>(false);

  // useEffect(() => {
  //   if (urlPath.toLowerCase().includes("/lethe/")) {
  //     try {
  //       email.current = tokenOrEmail;
  //       var tokenAccepted = getJwtToken();
  //     } catch (error) {
  //       // should show error page
  //       console.log("Auto-login failed");
  //       return;
  //     }
  //   } else if (urlPath.toLowerCase().includes("/lethe-login/")) {
  //     try {
  //       var tokenAccepted = getJwtTokenWithAesToken();
  //     } catch (error) {
  //       // should show error page
  //       localStorage.setItem("loginSuccessful", "false");
  //       console.log("Login with lethe token failed.");
  //       return;
  //     }
  //   }
  // }, [urlPath]);

  useEffect(() => {
    if (isAuthenticated || (jwtToken !== undefined && jwtToken.length > 0)) {
      if (
        userInfo != undefined &&
        userInfo?.email !== null &&
        userInfo?.email === user?.email
      ) {
        return;
      }
      fetchCurrentUserInfo();
    }
  }, [isAuthenticated, jwtToken]);

  const fetchCurrentUserInfo = () => {
    const tempmail = user && user.email ? user!.email : email.current;
    const url = process.env.REACT_APP_API_SERVER_URL + `/user/${tempmail}`;
    const fetchData = async () => {
      let token = !isAuthenticated
        ? localStorage.getItem(process.env.REACT_APP_TOKEN_NAME!)
        : await getAccessTokenSilently();
      try {
        const response = await fetch(url, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.ok) {
          const json = await response.json();
          setUserInfo(json);
          setCurrentUserInfo(json);
          localStorage.setItem("loginSuccessful", "true");
        } else {
          await createUser();
        }
      } catch (error) {
        localStorage.setItem("loginSuccessful", "false");
        setCurrentUserInfo(null);
        console.log(error);
        logout({ returnTo: window.location.origin });
        //logout();
      }
    };
    fetchData().then((x) => {
      console.log("login successfull");
    });
  };
  const createUser = () => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        let token = !isAuthenticated
          ? localStorage.getItem(process.env.REACT_APP_TOKEN_NAME!)
          : await getAccessTokenSilently();

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            email: user?.email,
            languageCulture: user?.locale,
          }),
        };

        //const res = await fetch(process.env.REACT_APP_API_SERVER_URL+`/user/${user?.email}/${browserLanguage}`, requestOptions);
        const res = await fetch(
          process.env.REACT_APP_API_SERVER_URL +
            `/user/${
              isAuthenticated ? user?.email : email.current
            }/${browserLanguage}`,
          requestOptions
        );
        const resJson = await res.json();
        setUserInfo(resJson);
        setCurrentUserInfo(resJson);
        localStorage.setItem("loginSuccessful", "true");
        resolve();
      } catch (err) {
        logout({ returnTo: window.location.origin });
        //logout();
        setCurrentUserInfo(null);
        reject(err);
        console.log(err);
      }
    });
  };

  useEffect(() => {
    fetchUserInfoWithGameSchedule();
  }, [currentUserInfo]);

  const fetchUserInfoWithGameSchedule = () => {
    if (currentUserInfo != null) {
      const url =
        process.env.REACT_APP_API_SERVER_URL +
        `/scheduled_games/${currentUserInfo!.id}`;
      const fetchData = async () => {
        try {
          var token = !isAuthenticated
            ? localStorage.getItem(process.env.REACT_APP_TOKEN_NAME!)
            : await getAccessTokenSilently();

          const response = await fetch(url, {
            headers: { Authorization: `Bearer ${token}` },
          });
          if (response.ok) {
            const json = await response.json();
            setScheduleGameForUser(json);
            setNoSchedule(json);
          } else {
            const json = await response.json();
            setScheduleCompleted(true);
          }
        } catch (error) {
          console.log(error);
          logout({ returnTo: window.location.origin });
          //logout();
        }
      };
      fetchData().then((r) => r);
    }
  };

  const getJwtToken = () => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const requestOptions = {
          method: "GET",
          headers: { "Content-type": "application/json" },
        };
        const res = await fetch(
          process.env.REACT_APP_API_SERVER_URL +
            `/lethe-straight/${email.current}`,
          requestOptions
        );
        //const resJson = await res.json();
        resolve();
        if (res.ok) {
          // we have identified user, can use the token instead of the normal one
          var tokeni = await res.text();
          if (tokeni[0] === '"') {
            tokeni = tokeni.slice(1, -1);
          }
          //console.log("token", tokeni);

          setJwtToken(tokeni);
          localStorage.setItem(process.env.REACT_APP_TOKEN_NAME!, tokeni);
          return true;
        } else {
          // go to error page for failed LETHE login/token
          localStorage.setItem("loginSuccessful", "false");
          return false;
        }
      } catch (err) {
        reject(err);
        console.log("token check failed", err);
        localStorage.setItem("loginSuccessful", "false");
        return false;
      }
    });
  };

  const getJwtTokenWithAesToken = () => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const requestOptions = {
          method: "GET",
          headers: { "Content-type": "application/json" },
        };
        const res = await fetch(
          process.env.REACT_APP_API_SERVER_URL +
            `/lethe-login-with-token/${tokenOrEmail}`,
          requestOptions
        );
        const resJson = await res.json();
        resolve();
        if (res.ok) {
          // we have identified user, can use the token instead of the normal one
          var tokeni = resJson.token;
          if (tokeni[0] === '"') {
            tokeni = tokeni.slice(1, -1);
          }
          var userEmail = resJson.email;
          email.current = userEmail;
          setJwtToken(tokeni);
          localStorage.setItem(process.env.REACT_APP_TOKEN_NAME!, tokeni);
          localStorage.setItem("loginSuccessful", "false");

          return true;
        } else {
          // go to error page for failed LETHE login/token
          return false;
        }
        //console.log("send token: ", resJson);
      } catch (err) {
        reject(err);
        console.log("token check failed", err);
        localStorage.setItem("loginSuccessful", "false");

        return false;
      }
    });
  };

  return [
    currentUserInfo,
    scheduleGameForUser,
    gotToSignUpPage,
    fetchCurrentUserInfo,
    fetchUserInfoWithGameSchedule,
    scheduleCompleted,
    noSchedule?.noSchedule,
  ];
};

export class WeekResults {
  week: number;
  playedPercentage: number;
  startsAt: string;
  endsAt: string;
  constructor(dto: any) {
    this.week = dto.week;
    this.playedPercentage = dto.playedPercentage;
    this.startsAt = dto.startsAt;
    this.endsAt = dto.endsAt;
  }
}

export const findWeeksNumbers = (d1: any, d2: any) => {
  return Math.round((d1 - d2) / (7 * 24 * 60 * 60 * 1000));
};

// To get the Sunday of the current week to calculate one full week from Monday to Sunday
export const getNextDayOfWeek = (date: any, dayOfWeek: number) => {
  let resultDate = new Date(date.getTime());
  resultDate.setDate(date.getDate() + ((7 + dayOfWeek - date.getDay()) % 7));

  return new Date(resultDate.setHours(23, 59, 0, 0));
};

export const findConsecutiveWeek = (
  weekResults: WeekResults[],
  previousWeekResults: any
) => {
  let copyOfWeekResults = [...weekResults];
  copyOfWeekResults.pop();
  let consecutiveWeek = copyOfWeekResults.map((x) => x.playedPercentage > 0);
  let checkForActivePlayingWeek = consecutiveWeek.every((v) => v);
  let newArray = [];
  for (let i = consecutiveWeek.length - 1; i < consecutiveWeek.length; i--) {
    if (!consecutiveWeek[i]) {
      break;
    }
    newArray.push(consecutiveWeek[i]);
  }
  let nextSunday = getNextDayOfWeek(new Date(), 7);
  return checkForActivePlayingWeek
    ? findWeeksNumbers(nextSunday, new Date(previousWeekResults[0].startsAt))
    : newArray.length + 1;
};

export const findConsecutiveWeekReachingGoals = (
  weekResults: WeekResults[]
) => {
  let copyOfWeekResults = [...weekResults];
  let newArray = [];
  let consecutiveWeek = copyOfWeekResults.map((x) => x.playedPercentage >= 100);

  if (copyOfWeekResults[copyOfWeekResults.length - 1].playedPercentage < 100) {
    consecutiveWeek.pop();
    for (let i = consecutiveWeek.length - 1; i < consecutiveWeek.length; i--) {
      if (!consecutiveWeek[i]) {
        break;
      }
      newArray.push(consecutiveWeek[i]);
    }
  } else {
    for (let i = consecutiveWeek.length - 1; i < consecutiveWeek.length; i--) {
      if (!consecutiveWeek[i]) {
        break;
      }
      newArray.push(consecutiveWeek[i]);
    }
  }
  return newArray.length;
};

export const findLargest3 = (arr: WeekResults[]) => {
  let copyArr = [...arr];
  if (arr.length > 3) {
    copyArr.sort((a, b) =>
      a.playedPercentage < b.playedPercentage
        ? 1
        : a.playedPercentage > b.playedPercentage
        ? -1
        : 0
    );
    let arr3 = copyArr.slice(0, 3);
    return arr3;
  } else {
    return copyArr.sort((a, b) => b.playedPercentage - a.playedPercentage);
  }
};

export const findActiveGamingWeek = (weekResults: WeekResults[]) => {
  let copyOfWeekResults = [...weekResults];
  let consecutiveWeek = copyOfWeekResults.map((x) => x.playedPercentage === 0);
  let checkForActivePlayingWeek = consecutiveWeek.every((v) => v);
  let newArray: any = [];

  if (copyOfWeekResults.length > 10) {
    for (
      let i = copyOfWeekResults.length - 1;
      i < copyOfWeekResults.length;
      i--
    ) {
      if (i === copyOfWeekResults.length - 1 - 10) {
        break;
      }
      newArray.push(copyOfWeekResults[i]);
    }
  } else {
    newArray = [...copyOfWeekResults];
  }

  let isPercentageZero = newArray.filter((x: any) => {
    if (x.playedPercentage === 0) {
      return true;
    }
  });

  let largestSortedArray = findLargest3(newArray);
  let isCurrentWeekActive = largestSortedArray.filter(
    (item) =>
      item.week === copyOfWeekResults[copyOfWeekResults.length - 1].week &&
      item.playedPercentage ===
        copyOfWeekResults[copyOfWeekResults.length - 1].playedPercentage
  );
  if (!checkForActivePlayingWeek) {
    if (isCurrentWeekActive.length > 0) {
      if (
        isCurrentWeekActive[isCurrentWeekActive.length - 1].week > 0 &&
        isCurrentWeekActive[isCurrentWeekActive.length - 1].playedPercentage > 0
      ) {
        return (
          largestSortedArray.findIndex(
            (x) =>
              x.week ===
              isCurrentWeekActive[isCurrentWeekActive.length - 1].week
          ) + 1
        );
      }
      return 0;
    } else {
      return 0;
    }
  }
  return 0;
};

export const getNumberWithSuffix = (number: number, lang: string) => {
  switch (lang) {
    case "en": {
      let j = number % 10;
      let k = number % 100;
      if (j == 1 && k != 11) {
        return number + "st";
      }
      if (j == 2 && k != 12) {
        return number + "nd";
      }
      if (j == 3 && k != 13) {
        return number + "rd";
      }
      return number + "th";
    }

    case "sv": {
      let j = number % 10;
      let k = number % 100;

      if (j == 1 && k != 11) {
        return number + ":a";
      }

      if (j == 2 && k != 12) {
        return number + ":a";
      }

      if (j == 2 && k == 12) {
        return number + ":e";
      }

      if (j == 3 && k != 13) {
        return number + ":e";
      }
      return number + ":e";
    }
    default:
      return number;
  }
};

export const getDaysBetweenDates = (startDate: string, endDate: string) => {
  let result = [];
  let day = 0; // to get date of Sundays.
  let start = new Date(startDate);
  let end = new Date(endDate);
  end.setHours(0,0,0);
  start.setHours(0,0,0)
  // Copy start date
  let current = new Date(start);
  // Shift to next of required days
  current.setDate(current.getDate() + ((day - current.getDay() + 7) % 7));
  while (current <= end) {
    result.push(new Date(+current));
    current.setDate(current.getDate() + 7);
  }
  return result;
};

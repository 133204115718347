import * as React from "react";
import { Fragment, useEffect } from "react";
import { LightCoord } from "./light-coord";
import "./style.css";
import RoundButton from "./round-button";

interface ActiveLoc {
  loc: LightCoord;
  size: number;
  disabled: boolean;
  correctLocation?: LightCoord;
  answeredLocation: (xloc: number, yloc: number) => void;
}

const LightGrid = (props: ActiveLoc) => {
  const clickedLocation = (xloc: number, yloc: number) => {
    props.answeredLocation(xloc, yloc);
  };

  const getStyle = () => {
    return (
      "align-items-center justify-content-center light-grid-row light-items-center light-grid-row-" +
      props.size +
      "x" +
      props.size
    );
  };

  const isCorrect = (xloc: number, yloc: number) => {
    return (
      props.correctLocation != undefined &&
      props.correctLocation.x == xloc &&
      props.correctLocation.y == yloc
    );
  };

  const rows = (column: number) =>
    Array.from({ length: props.size }, (_, index) => {
      return (
        <RoundButton
          className=""
          size={props.size}
          clicked={() => clickedLocation(index, column)}
          active={index === props.loc.x && props.loc.y == column}
          disabled={props.disabled}
          key={index}
          isCorrect={() => isCorrect(index, column)}
          text=""
        ></RoundButton>
      );
    });

  const columns = Array.from({ length: props.size }, (_, index) => {
    return (
      <div className={getStyle()} key={index}>
        {rows(index)}
      </div>
    );
  });

  return (
    <Fragment>
      <div className="container light-grid-center">{columns}</div>
    </Fragment>
  );
};

export default LightGrid;

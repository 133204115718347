import * as React from "react";
import { useEffect, useRef, useState } from "react";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";

import UpdatingTexts from "./updating-texts";
import { GAME_LENGTH_SEQUENCES, GameCriterion, LAST_N, SequenceAnswer } from "../../../helpers/updating-items-helpers";
import ScoreCard from "../../../components/scorecard";
import { Link } from "react-router-dom";
import { GameType } from "../../../models/game-type";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import GameScore from "../../../models/game-result";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserInfo } from "../../../contexts/user-info-context";
import { BUTTONS_GAME_LEVEL } from "../choose-category-and-level";

import "../style.css";
import { useActiveTab } from "../../../contexts/active-tab-context";
import UpdatingSpatial from "./updating-spatial";
import {handleUpdateAppWithUserWeeklyScheduleGames} from "../../../helpers/routes";

export const SHOW_ITEM_TIME_MS = 2000;

interface Interval {
  callback: (a: any) => void;
  delay: number;
  duration: any;
}

class DetailedResults {
  email: string;
  level: number;
  score: number;
  totalCorrectAnswers: number;
  totalTimeSeconds: number;
  answers: Array<SequenceAnswer>;
  constructor(gameScoreInfo: GameScore, answers: Array<SequenceAnswer>, score: number, totalCorrectAnswers: number, totalTimeSeconds: number) {
    this.email = gameScoreInfo.email;
    this.level = gameScoreInfo.level == null ? 0 : gameScoreInfo.level!;
    this.score = score;
    this.totalCorrectAnswers = totalCorrectAnswers;
    this.totalTimeSeconds = totalTimeSeconds;
    this.answers = answers;
  }
}

const useInterval = (props: Interval) => {
  const durationRef = useRef<any>(props.duration);
  const durationIntervalRef = useRef<NodeJS.Timer>();

  const handler = () => {
    props.callback(durationRef);
  };

  useEffect(() => {
    const durationInterval = setInterval(handler, props.delay);
    durationIntervalRef.current = durationInterval!;
    return () => {
      clearInterval(durationInterval);
    };
  }, [props.delay]);

  return durationIntervalRef;
};

interface props {
  gameType: GameType;
  level: number;
  sessionSequences: any[];
  setStart: React.Dispatch<React.SetStateAction<boolean>>;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
  setSelectedGameCriterion: React.Dispatch<React.SetStateAction<GameCriterion>>;
  setChooseLevel: React.Dispatch<React.SetStateAction<boolean>>;
  //isSchedule: boolean;
}

const Games = (props: props) => {
  const { t } = useTranslation("common");
  const { user, getAccessTokenSilently } = useAuth0();

  const { currentUserInfo, noSchedule, updateCurrentUserInfoWithGameSchedule } = useUserInfo();
  const { activeTab } = useActiveTab();

  const { gameType, level, sessionSequences, setCounter, setStart, setSelectedGameCriterion, setChooseLevel } = props;

  const [sequenceNumber, setSequenceNumber] = useState<number>(0);
  const [totalTime, setTotalTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [showResult, setShowResult] = useState<boolean>(false);
  const [answers, setAnswers] = useState<Array<SequenceAnswer>>([]);
  const [sequenceStartTime, setSequenceStartTime] = useState<Date>();
  const [detailsResult, setDetailsResult] = useState<DetailedResults>();

  const [gameScore, setGameScore] = useState<GameScore>({
    correctItems: 0,
    email: currentUserInfo.email,
    isScheduledGame: activeTab === "Training",
    userId: currentUserInfo.id,
    score: 0,
    totalTimeSeconds: 0,
    averageTimeMs: 0,
    gameType: gameType,
    level: level,
    percentage: 0,
  });
  const POINTS_MAX = 12000;
  const POINTS_CORRECT_ITEM = 100;
  const MINUS_POINTS_PER_SECOND = 5;
  const POINTS_CORRECT_SEQUENCE = POINTS_MAX / GAME_LENGTH_SEQUENCES;

  const startTiming = () => {
    setSequenceStartTime(new Date());
  };

  /*
    Calculations: if sequence is correct, use POINTS_CORRECT_SEQUENCE else give points for each correct item.
    After that, time reduces points by MINUS_POINTS_PER_SECOND. 
  */

  const completePhase = (seqAnwers: SequenceAnswer) => {
    setSequenceNumber(sequenceNumber + 1);
    let fromStart = differenceInMilliseconds(new Date(), sequenceStartTime!);
    seqAnwers.sequenceTimeMs = fromStart;
    let pts = 0;
    for (let i = 0; i < seqAnwers.answeredSequence.length && i < seqAnwers.correctSequence.length; ++i) {
      seqAnwers.correctAnswers += seqAnwers.answeredSequence[i] === seqAnwers.correctSequence[i].toString() ? 1 : 0;
      pts += seqAnwers.answeredSequence[i] === seqAnwers.correctSequence[i].toString() ? POINTS_CORRECT_ITEM : 0;
    }
    seqAnwers.correct = pts === seqAnwers.answeredSequence.length * POINTS_CORRECT_ITEM;
    seqAnwers.totalPoints = seqAnwers.correct ? POINTS_CORRECT_SEQUENCE : pts;
    seqAnwers.totalPoints =
      seqAnwers.totalPoints - (MINUS_POINTS_PER_SECOND * seqAnwers.sequenceTimeMs) / 1000 > 0
        ? seqAnwers.totalPoints - Math.round((MINUS_POINTS_PER_SECOND * seqAnwers.sequenceTimeMs) / 1000)
        : 0;
    answers.push(seqAnwers);
  };

  const restartGame = () => {
    setCounter(3);
    setStart(false);
    setSelectedGameCriterion({
      gameType: gameType,
      title: "",
      level: 0,
      difficultyLevel: {
        min: 0,
        max: 0,
      },
    });
    setChooseLevel(true);
  };

  useEffect(() => {
    if (sequenceNumber >= GAME_LENGTH_SEQUENCES) {
      setShowResult(true);
      clearInterval(durationIntervalRef.current);
    }
  }, [sequenceNumber]);

  useEffect(() => {
    let correctSequences = 0;
    let correctAnswers = 0;
    let totalScore = 0;
    let averageTimeSequenceMs = 0;
    answers.forEach((x) => {
      correctAnswers += x.correctAnswers;
      correctSequences += x.correct ? 1 : 0;
      totalScore += x.totalPoints;
      averageTimeSequenceMs += x.sequenceTimeMs;
    });

    if (answers.length === GAME_LENGTH_SEQUENCES) {
      setGameScore((prevState) => {
        return {
          ...prevState,
          score: totalScore,
          correctItems: correctAnswers,
          correctSequences: correctSequences,
          numberOfItems: GAME_LENGTH_SEQUENCES * LAST_N,
          numberOfSequencers: GAME_LENGTH_SEQUENCES,
          totalTimeSeconds: duration,
          averageTimeMs: Math.round(averageTimeSequenceMs / GAME_LENGTH_SEQUENCES),
          level: level,
          percentage: Math.round((correctAnswers * 100) / (GAME_LENGTH_SEQUENCES * LAST_N)),
          maxLevel: BUTTONS_GAME_LEVEL[BUTTONS_GAME_LEVEL.length - 1].level,
        };
      });
      
    }
  }, [answers, showResult]);

  const durationIntervalRef = useInterval({
    callback: (durationRef: any) => {
      durationRef.current++;
      setDuration(durationRef.current);
    },
    delay: 1000,
    duration: duration,
  });

  return (
    <React.Fragment>
      {sessionSequences.map((sessionSequence, index) =>
        index === sequenceNumber && (gameType === "UpdatingLetter" || gameType === "UpdatingNumber" || gameType === "UpdatingColor") ? (
          <div key={index}>
            <UpdatingTexts
              sessionSequence={sessionSequence}
              gameType={gameType}
              completePhase={completePhase}
              startTiming={startTiming}
              key={index}
              sequenceNumber={sequenceNumber}
            />
            <hr className="hr-style" />
            <div className="text-center">
              <Link to={`/`} className="m-3 text-center">
                <Button variant="outline-secondary" size="lg" onClick={() => handleUpdateAppWithUserWeeklyScheduleGames(updateCurrentUserInfoWithGameSchedule, noSchedule)}>
                  {t("common.close")}
                </Button>
              </Link>
            </div>
          </div>
        ) : null
      )}
      {sessionSequences.map((sessionSequence, index) =>
        index === sequenceNumber && gameType === "UpdatingSpatial" ? (
          <div key={index}>
            <UpdatingSpatial
              sessionSequence={sessionSequence}
              gameType={gameType}
              completePhase={completePhase}
              startTiming={startTiming}
              key={index}
              sequenceNumber={sequenceNumber}
            />
            <hr className="hr-style" />
            <div className="text-center">
              <Link to={`/`} className="m-3 text-center">
                <Button variant="outline-secondary" size="lg" onClick={() => handleUpdateAppWithUserWeeklyScheduleGames(updateCurrentUserInfoWithGameSchedule, noSchedule)}>
                  {t("common.close")}
                </Button>
              </Link>
            </div>
          </div>
        ) : null
      )}

      {showResult ? (
        <div>
          <ScoreCard
            showCorrectSequences={false}
            showCorrectnessPercentage={true}
            showLenghtOfSequence={false}
            showAverageTimeMs={false}
            showCorrectMoves={false}
            gameScore={gameScore}
            restartGame={restartGame}
            detailedResults={JSON.stringify(new DetailedResults(gameScore, answers, gameScore.score, gameScore.percentage!, gameScore.totalTimeSeconds))}
          />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default Games;

import React from "react";
import { ActiveTab } from "../../models/active-tab";

interface props {
  id: ActiveTab;
  activeTab: ActiveTab;
  children: React.ReactNode;
}

const TabContent = (props: props) => {
  const { id, activeTab, children } = props;
  return activeTab === id ? <div className="TabContent">{children}</div> : null;
};

export default TabContent;

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "jquery";
import "bootstrap/dist/js/bootstrap.min.js";
import React, {Fragment} from "react";
import {createRoot} from 'react-dom/client';
import {App} from './App';
import {Auth0ProviderWithConfig} from './auth0-provider-with-config';
import {I18nextProvider} from 'react-i18next';
import {i18n} from "./i18n/i18n";
import "./index.css"
import {UserInfoProvider} from "./contexts/user-info-context";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

root.render(
    <Fragment>
        <I18nextProvider i18n={i18n}>
            <Auth0ProviderWithConfig>
                <UserInfoProvider>
                    <App/>
                </UserInfoProvider>
            </Auth0ProviderWithConfig>
        </I18nextProvider>
    </Fragment>
);

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import Signup from "./signup";
import { PageLayout } from "./page-layout";
import Home from "../pages/home";
import Tabs from "../components/tab-component/tabs";
import "../index.css";
import { useUserInfo } from "../contexts/user-info-context";
import { useActiveTab } from "../contexts/active-tab-context";
import { PageLoader } from "../components/page-loader";

const MainPage: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const { activeTab, toggleTab } = useActiveTab();

  const [isAuthenticatedForOutsideUrl, setIsAuthenticatedForOutsideUrl] =
    useState(false);
  const getUrlPath = () => window.location.pathname;
  const [urlPath, setUrlPath] = useState<string>(getUrlPath());
  const [url, setUrl] = useState<string>(urlPath);

  const { currentUserInfo, goToSignUpPage, scheduleCompleted } = useUserInfo();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showSinUpPage, setSignupPage] = useState(false);

  useEffect(() => {
    if (url != "/") {
      setIsAuthenticatedForOutsideUrl(true);
    }
  }, [urlPath]);

  useEffect(() => {
    if (currentUserInfo !== null) {
      setIsLoading(false);
    }
  }, [currentUserInfo]);

  const authenticated = () => {
    return isAuthenticated;
  };

  const handleToGoSignUpPage = () => {
    //navigate(0)
    navigate("../signup", { replace: true });
  };

  const HomePageView = () => {
    return (
      <PageLayout>
        <section className="py-3 vh-100">
          <div className="container h-100">
            <div className="align-items-center">
              {process.env.REACT_APP_BACKEND_URL !== "https://localhost:6060" &&
              !currentUserInfo.isClinician && !scheduleCompleted
                  ? ( <Home activeTab="Training" />)
                  : scheduleCompleted ? (<Home activeTab="Practicing" />)
                  : (
                      <Tabs activeTab={activeTab} toggleTab={toggleTab}>
                       {activeTab === "Training" ? (<Home activeTab={activeTab} />) : (<Home activeTab={activeTab} />)}
                      </Tabs>
              )}
            </div>
          </div>
        </section>
      </PageLayout>
    );
  };

  const showSignUpPage = () => {
    setTimeout(() => {
      setSignupPage(true);
    }, 3000);
  };

  useEffect(() => {
    showSignUpPage();
  }, [currentUserInfo]);

  return (
    <React.Fragment>
      {authenticated() ? (
        <>{isLoading ? <PageLoader /> : <HomePageView />}</>
      ) : (
        showSinUpPage && <Signup />
      )}
    </React.Fragment>
  );
};

export default MainPage;

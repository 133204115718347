import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SHOW_ITEM_TIME_MS } from "./index";
import { GameType } from "../../../models/game-type";
import { compareArrays, GAME_LENGTH_SEQUENCES, LAST_N, SequenceAnswer, shuffleArray } from "../../../helpers/updating-items-helpers";
import { LightCoord, fromString } from "../../../components/light-grid/light-coord";
import LightGrid from "../../../components/light-grid/light-grid";
import MultiSelectLightGrid from "../../../components/light-grid/multiselect-light-grid";

const UpdatingSpatial = (props: {
  sessionSequence: string[];
  completePhase: (score: SequenceAnswer) => void;
  startTiming: () => void;
  key: number;
  sequenceNumber: number;
  gameType: GameType;
}) => {
  const [randomSequenceOfSession, setRandomSequenceOfSession] = useState<string[]>(props.sessionSequence);
  const [index, setIndex] = useState<number>(0);
  const [arrayOfLastElements, setArrayOfLastElements] = useState<any[]>(randomSequenceOfSession.slice(-4));
  const [shuffleItems, setShuffleItems] = useState<any[]>(shuffleArray(arrayOfLastElements.slice(0)));
  const [pickedElements, setPickedElements] = useState<string[]>([]);

  const [score, setScore] = useState<number>(0);
  const [reset, setReset] = useState<boolean>(false);
  const [timingSet, setTimingSet] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const [resetCounter, setResetCounter] = useState<number>(0);
  const { t } = useTranslation("common");
  const [answered, setAnswered] = useState<LightCoord[]>([]);

  const handleIndex = () => {
    setIndex(index === randomSequenceOfSession.length ? 0 : index + 1);
  };

  useEffect(() => {
    if (index < randomSequenceOfSession.length) {
      setTimeout(handleIndex, SHOW_ITEM_TIME_MS);
    } else if (!timingSet) {
      props.startTiming();
      setTimingSet(true);
    }
  });

  useEffect(() => {
    setScore(compareArrays(arrayOfLastElements, pickedElements));
  }, [pickedElements, score]);

  const currentItem = randomSequenceOfSession[index];

  const onPickedElements = (pickedItem: string, index: number) => {
    setPickedElements((prevState) => [...prevState, pickedItem]);
    setReset(false);
  };

  const resetPickedElements = () => {
    pickedElements.splice(0, pickedElements.length);
    setAnswered([]);
    setReset(true);
    setResetCounter((oldCount) => oldCount + 1);
    setSubmit(false);
  };

  const onSubmitAnswer = () => {
    let sequence = new SequenceAnswer();
    sequence.sequenceOfTheSession = [...randomSequenceOfSession];
    sequence.answeredSequence = [...pickedElements];
    sequence.correctSequence = [...arrayOfLastElements];
    props.completePhase(sequence);
    setSubmit(true);
    setTimingSet(false);
  };

  const answeredLocation = (xloc: number, yloc: number) => {};

  const answeredCoordinates = (coordinate: LightCoord) => {
    setAnswered((prevState) => [...prevState, coordinate]);
    setPickedElements((prevState) => [...prevState, coordinate.toString()]);
    setResetCounter((oldCount) => oldCount + 1);
  };

  useEffect(() => {}, []);

  return (
      <div className="container h-100">
        <div className="row d-flex flex-column justify-content-center align-items-center">
          {index > randomSequenceOfSession.length - 1 ? null : (
            <div>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <h3 className="mb-5 text-size">
                  {t("games.updating_items.memorize_last_four_items_shown", {
                    items_plural_partitive: t("common.item_type_from_game_type_plural_partitive." + props.gameType),
                  })}
                </h3>
                <LightGrid size={4} answeredLocation={answeredLocation} disabled={true} loc={fromString(currentItem)}></LightGrid>
              </div>
            </div>
          )}
          {index > randomSequenceOfSession.length - 1 ? (
              <div>
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <h3 className="mb-5 text-size">
                    {t("games.updating_items.select_last_four_items_shown", {
                      items_plural_partitive: t("common.item_type_from_game_type_plural_partitive." + props.gameType),
                    })}
                  </h3>
                  <MultiSelectLightGrid
                      key={resetCounter}
                      addAnswered={answeredCoordinates}
                      answered={answered}
                      size={4}
                      maxLength={LAST_N}
                      disabled={false}
                      correct={[]}
                  ></MultiSelectLightGrid>
                </div>
              </div>
          ) : null}
          <div className="d-flex flex-row justify-content-end">
            <h4>
              {t("games.updating_items.sequence")}: {props.sequenceNumber + 1}/{GAME_LENGTH_SEQUENCES}
            </h4>
          </div>
          {pickedElements.length > 0 && (
              <div className="d-flex flex-row  justify-content-center">
                <button className="button-clear m-4" onClick={resetPickedElements}>
                  {t("games.updating_items.clear")}
                </button>
                <button
                    className={`${pickedElements.length != LAST_N && !submit ? "button-ok-disable" : "button-ok"} m-4`}
                    onClick={() => onSubmitAnswer()}
                    disabled={pickedElements.length != LAST_N && !submit}
                >
                  {t("games.updating_items.submit")}
                </button>
              </div>
          )}
        </div>
      </div>
  );
};

export default UpdatingSpatial;

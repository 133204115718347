import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useUserInfo } from "../../contexts/user-info-context";

export const LogoutButton: React.FC = () => {
  const { isAuthenticated, logout } = useAuth0();
  const { t } = useTranslation("common");
  return isAuthenticated ? (
    <button
      className="btn btn-outline-secondary"
      onClick={() => {
        localStorage.clear();
        localStorage.setItem("loginSuccessful", "false");
        logout({ returnTo: window.location.origin });
      }}
    >
      <span>{t("common.logout")}</span>
    </button>
  ) : (
    <button
      className="btn btn-outline-secondary"
      onClick={() => {
        localStorage.clear();
        localStorage.setItem("loginSuccessful", "false");
        logout({ returnTo: window.location.origin });
      }}
    >
      <span>{t("common.logout")}</span>
    </button>
  );
};

import * as React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import lethe_logo from "../../images/lethe.png";
import combi_logo from "../../images/Logo_Combinostics_FullColor.png";

import { useTranslation } from "react-i18next";

import "./index.css";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Introduction = () => {
  const { t } = useTranslation("common");

  return (
    <section className="py-5 vh-100">
      <div className="container h-75">
        <Card className="card-style">
          <Card.Body>
            <Card.Title className="text-center font-style mb-4">
              cTRAIN
            </Card.Title>
            <Card.Text className="introduction-text-style">
              {t("common.overall_instructions")}
            </Card.Text>
          </Card.Body>
        </Card>
        <div className="mt-5">
          <Row xs={2} md={3} className="g-1 center-row-container">
            <Col>
              <Card className="card-style">
                <Card.Body>
                  <Card.Text></Card.Text>
                </Card.Body>
                <Card.Img
                  className="card-image-top margin-top"
                  src={`${lethe_logo}`}
                ></Card.Img>
              </Card>
            </Col>
            <Col>
              <Card className="card-style">
                <Card.Body>
                  <Card.Text></Card.Text>
                </Card.Body>
                <Card.Img
                  className="card-image-top margin-top"
                  src={`${combi_logo}`}
                ></Card.Img>
              </Card>
            </Col>
          </Row>
          <Row className="g-1 center-row-container">
            <Col>
              <div className="form-check d-flex justify-content-center mb-5">
                <label className="form-check-label" htmlFor="form2Example3">
                  {t("common.view")}{" "}
                  <a
                    href={
                      process.env.PUBLIC_URL +
                      "/privacy-policy/privacy-policy.pdf"
                    }
                    target="_blank"
                  >
                    {t("common.terms_service")}
                  </a>
                </label>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="text-center mt-5">
        <Link to="/" className="m-3 text-center">
          <Button variant="outline-secondary" size="lg">
            {t("common.close")}
          </Button>
        </Link>
      </div>
    </section>
  );
};

export default Introduction;

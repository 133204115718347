import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserInfo } from "../contexts/user-info-context";

const ScheduleGamePage: React.FC = () => {
  const { i18n } = useTranslation("common");
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

  const { currentUserInfo } = useUserInfo();
  const { t } = useTranslation("common");

  const [schedule, setSchedule] = useState<any>();

  useEffect(() => {
    if (currentUserInfo != null) {
      const url = process.env.REACT_APP_API_SERVER_URL + `/scheduled_games/${currentUserInfo!.id}`;

      const fetchData = async () => {
        try {
          const token = !isAuthenticated ? localStorage.getItem(process.env.REACT_APP_TOKEN_NAME!) : await getAccessTokenSilently();
          const response = await fetch(url, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const json = await response.json();
          setSchedule(json);
        } catch (error) {
          console.log(error);
        }
      };
      fetchData().then((r) => r);
    }
  }, [currentUserInfo]);

  useEffect(() => {}, [schedule]);
  return (
    <>
      <h1>Schedule page</h1>
      {schedule != undefined ? (
        <ul>
          {schedule.gameTypes.map((x: any, index: number) => (
            <li key={index}>{x}</li>
          ))}
        </ul>
      ) : null}
      {/*<hr className="hr-style" />*/}
      {/*<div className="text-center mt-3">*/}
      {/*    <Link to="/" className="m-3 text-center">*/}
      {/*        <Button variant="outline-secondary" size="lg">*/}
      {/*            {t("common.close")}*/}
      {/*        </Button>*/}
      {/*    </Link>*/}
      {/*</div>*/}
    </>
  );
};

export default ScheduleGamePage;

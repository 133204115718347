import React, {useEffect, useState} from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "./page-loader-styles.css"

export const PageLoader: React.FC = () => {
    // const points = 30000 //only of testing
    // const percentage = (points / 10000) * 100; //calculate the percentage
    // return (
    //     <div className="div-style-loading">
    //         <CircularProgressbar className="circular-progress-bar-loading" value={percentage} text={" Loading... "} />
    //     </div>
    // );
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            if (percentage < 100) {
                setPercentage(percentage + 1);
            }
        }, 10);
    }, [percentage]);

    return (
        <div className="div-style-loading">
            <div style={{ width: "100px", height: "100px", borderRadius: "50%", backgroundColor: "#4473AF"}}>
                <CircularProgressbar value={percentage} text={`${percentage}%`} />
            </div>
        </div>
    );
};

import { GameType } from "./game-type";

export class GameplayReportForWeek {
  email: string;
  updatedAt: Date;
  weekStartingDay: Date;
  weekEndingDay: Date;
  daysPlayedThisWeek: number;
  daysPlayedTarget: number;
  gameStreakDays: number;
  gameStreakWeeks: number;
  trainingWeekNumber: number;
  totalTrainingWeeks: number;
  isScheduledWeek: boolean;
  totalPlayingTimeSeconds: number;
  todaysPlayingTimeSeconds: number;
  todaysCompletionPercentage: number;
  weeklyCompletionPercentage: number;
  dailyTargetPlayingTimeSeconds: number;
  weeklyTargetPlayingTimeSeconds: number;
  gameDataForWeek: GameDataForWeek[] = [];
  // gamePlayReportForAllGames: GameDataForAllWeeks = new GameDataForAllWeeks({});
  constructor(dto: any) {
    this.email = dto.email;
    this.updatedAt = new Date(dto.updatedAt);
    this.weekStartingDay = dto.weekStartingDay;
    this.weekEndingDay = dto.weekEndingDay;
    this.daysPlayedThisWeek = dto.daysPlayedThisWeek;
    this.daysPlayedTarget = dto.daysPlayedTarget;
    this.gameStreakDays = dto.gameStreakDays;
    this.gameStreakWeeks = dto.gameStreakWeeks;
    this.trainingWeekNumber = dto.trainingWeekNumber;
    this.totalTrainingWeeks = dto.totalTrainingWeeks;
    this.isScheduledWeek = dto.isScheduledWeek;
    this.totalPlayingTimeSeconds = dto.totalPlayingTimeSeconds;
    this.todaysPlayingTimeSeconds = dto.todaysPlayingTimeSeconds;
    this.todaysCompletionPercentage = dto.todaysCompletionPercentage;
    this.weeklyCompletionPercentage = dto.weeklyCompletionPercentage;
    this.dailyTargetPlayingTimeSeconds = dto.dailyTargetPlayingTimeSeconds;
    this.weeklyTargetPlayingTimeSeconds = dto.weeklyTargetPlayingTimeSeconds;
    if (dto.userDataForGames) {
      this.gameDataForWeek = (dto.gameDataForWeek as []).map(
        (x: any) => new GameDataForWeek(x)
      );
    }
  }
}

export class GameDataForWeek {
  updatedAt: Date;
  gameType: GameType;
  gameName: string;
  trainingWeekNumber: number;
  averageScoreOfWeek: number;
  averageScoreOfDay: number;
  highScoreOfWeek: number;
  todaysPlayingTimeSeconds: number;
  totalPlayingTimeSeconds: number;
  daysPlayedThisWeek: number;
  daysScheduledForWeek: number;
  timeSecondsScheduledForWeek: number;
  constructor(dto: any) {
    this.updatedAt = new Date(dto.updatedAt);
    this.gameName = dto.gameName;
    this.gameType = dto.gameType;
    this.trainingWeekNumber = dto.trainingWeekNumber;
    this.averageScoreOfDay = dto.averageScoreOfDay;
    this.averageScoreOfWeek = dto.averageScoreOfWeek;
    this.daysPlayedThisWeek = dto.daysPlayedThisWeek;
    this.daysScheduledForWeek = dto.daysScheduledForWeek;
    this.timeSecondsScheduledForWeek = dto.timeSecondsScheduledForWeek;
    this.highScoreOfWeek = dto.highScoreOfWeek;
    this.todaysPlayingTimeSeconds = dto.todaysPlayingTimeSeconds;
    this.totalPlayingTimeSeconds = dto.totalPlayingTimeSeconds;
  }
}

export class GameDataForAllWeeks {
  gamePlayReportForWeek: GameplayReportForWeek[] = [];
  constructor(dto: any) {
    this.gamePlayReportForWeek = dto.gamePlayReportForWeek;
    // if(dto.userDataForGames){
    //   this.gameplayReportForWeek = (dto.gameplayReportForWeek as []).map((x:any) => new GameplayReportForWeek(x));
    // }
  }
}

import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";

import Card from "./card";
import { CardDef } from "./game";
import {handleUpdateAppWithUserWeeklyScheduleGames} from "../../helpers/routes";

import "./style.css";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import {useUserInfo} from "../../contexts/user-info-context";

interface GridProps {
  cards: CardDef[];
  checkCards: (firstCard: CardDef, secondCard: CardDef) => void;
  moveCount: number;
  setMoveCount: React.Dispatch<React.SetStateAction<number>>;
  level: number;
}

const Grid = (props: GridProps) => {
  const { cards, checkCards, moveCount, setMoveCount, level } = props;
  const { t } = useTranslation("common");
  const {noSchedule, updateCurrentUserInfoWithGameSchedule} = useUserInfo()

  const preventDrag = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
  };
  
  return (
    <section className="vh-100">
      <div className="container h-100">
        <div className="grid-container m-4" onDragStart={preventDrag}>
          {cards.map((card, index) => (
            <Card
              key={index}
              className={`card ${card.visible ? "grid-card-show" : ""} ${card.pairFound ? "grid-card-show grid-card-finished" : ""}`}
              onClick={() => {
                setMoveCount((moveCount) => moveCount + 1);
                
                let gridStyle = document.querySelector('.grid-container');
                let colCount = getComputedStyle(gridStyle!).gridTemplateColumns.split(' ').length;
                const rowPosition = Math.floor(index / colCount);
                const colPosition = index % colCount;
                
                //console.log({row: rowPosition, column: colPosition});
                
                if (!card.pairFound) {
                  switch (cards.filter((x) => x.visible).length) {
                    case 0:
                      card.visible = true;
                      break;
                    case 1:
                      card.visible = true;
                      if (cards.filter((x) => x.visible).length == 2) {
                        let card1 = cards.find((x) => x !== card && x.visible);
                        checkCards(card1!, card);
                      }
                      break;
                    case 2:
                      break;
                    default:
                      cards.forEach((x) => {
                        x.visible = false;
                      });
                  }
                }
              }}
              imgSource={process.env.REACT_APP_API_SERVER_URL + `/${card.path}`}
              imgDesc={card.path}
            />
          ))}
        </div>
        <hr className="hr-style" />
        <div className="text-center mt-4">
          <Link to="/" className="m-3 text-center">
            <Button variant="outline-secondary" size="lg" onClick={() => handleUpdateAppWithUserWeeklyScheduleGames(updateCurrentUserInfoWithGameSchedule, noSchedule)}>
              {t("common.close")}
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
};

Grid.defaultProps = {
  list: [],
};

export default Grid;

import MentalAnswer from "../models/mental-shifting-details";

export const MAX_POINTS_FOR_GAME = 10000;
export const MAX_ANSWERING_TIME_MS = 10000;

export const scoreCalculation = (answers: MentalAnswer[]) => {
  let tempScore = 0;
  const MAX_POINTS_FOR_ANSWER = MAX_POINTS_FOR_GAME / answers.length;
  for (let i = 0; i < answers.length; ++i) {
    tempScore +=
      answers[i].correct && answers[i].reactionTimeMs < MAX_ANSWERING_TIME_MS
        ? (MAX_POINTS_FOR_ANSWER * (MAX_ANSWERING_TIME_MS - answers[i].reactionTimeMs)) / MAX_ANSWERING_TIME_MS
        : 0;
  }
  return tempScore;
};

export const  moveElement= (array: any[], fromIndex: number, toIndex: number) => {
  const element = array.splice(fromIndex, 1)[0];
  array.splice(toIndex, 0, element);
  return array;
}

export const convert = (n: any) =>{
  n = String(n)
  if (n.length == 1)
    n = '0' + n
  return n;
}



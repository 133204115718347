import * as React from "react";
import { ActiveTab } from "../../models/active-tab";

interface props {
  id: ActiveTab;
  title: string;
  activeTab: ActiveTab;
  toggleTab?: (id: ActiveTab) => void;
}

const TabNavItem = (props: props) => {
  const { id, title, toggleTab, activeTab } = props;
  const handleClick = () => {
    if (toggleTab) {
      toggleTab(id);
    }
  };

  return (
    <li onClick={handleClick} className={`nav-item nav-link ${activeTab === id ? "active" : ""}`} role="button">
      {title}
    </li>
  );
};
export default TabNavItem;

import * as React from "react";
import { Fragment, useEffect, useState, useCallback } from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";

import CountDown from "../../components/count-down";
import Game from "./game";

import g4d1 from "../../images/G4D1.png";
import g4d2 from "../../images/G4D2.png";
import g4d3 from "../../images/G4D3.png";
import "./style.css";

import { useActiveTab } from "../../contexts/active-tab-context";
import { useUserDataForGame } from "../../contexts/user-data-for-game-context";
import { UserDataForGame } from "../../models/user-data-for-game";
import { GameLevelsData } from "../../models/game-level-data";
import { ActiveTab } from "../../models/active-tab";
import {handleUpdateAppWithUserWeeklyScheduleGames} from "../../helpers/routes";
import {useUserInfo} from "../../contexts/user-info-context";

export const NUMBER_OF_SEQUENCES = 32;

export const getGameLevelData = (activeTab: ActiveTab, levelAchieved: number) => {
  return [
    { title: "games.difficulty_level.easy", level: 1, image: g4d1, max_points: 8000, active: true },
    {
      title: "games.difficulty_level.intermediate",
      level: 2,
      image: g4d2,
      max_points: 10000,
      active: activeTab === "Practicing" ? true : levelAchieved >= 2,
    },
    {
      title: "games.difficulty_level.hard",
      level: 3,
      image: g4d3,
      max_points: 12000,
      active: activeTab === "Practicing" ? true : levelAchieved >= 3,
    },
  ];
};

export const getSequenceOfShapes = (array: string[], numberOfSequences: number, max: number, min: number, thresholdForSameShape: number) => {
  let sequenceOfShapes: string[] = [];

  for (let index: number = 0; index < numberOfSequences + 1; index++) {
    let randomThresholdToHaveSameShapes = Math.floor(Math.random() * (max - min + 1) + min);
    let randomShape = array[Math.floor(Math.random() * array.length)];

    if (index > 0) {
      if (thresholdForSameShape > randomThresholdToHaveSameShapes) {
        randomShape = sequenceOfShapes[index - 1];
      } else {
        randomShape = array[Math.floor(Math.random() * array.length)];
      }
    }
    sequenceOfShapes.push(randomShape);
  }
  return sequenceOfShapes;
};

const ShapeMatchMentalSpeed: React.FunctionComponent = () => {
  const THRESHOLD = 35;
  const MAX = 99;
  const MIN = 0;

  const { activeTab } = useActiveTab();
  const { userDataForGames, updateUserDataForGames } = useUserDataForGame();
  const {noSchedule, updateCurrentUserInfoWithGameSchedule} = useUserInfo();

  const { t } = useTranslation("common");

  const [gameSelectionLevel, setGameSelectionLevel] = useState<number>(0);
  const [shapes, setShapes] = useState<string[]>([]);
  const [counter, setCounter] = useState<number>(3);
  const [startGame, setStartGame] = useState<boolean>(false);

  const [sequenceOfShapesForTheGame, setSequenceOfShapesForTheGame] = useState<string[]>([]);

  const [userDataForTheGame, setUserDataForTheGame] = useState<UserDataForGame>();
  const [levelAchieved, setLevelAchieved] = useState<number[]>([]);
  const [levelButtonsForTheGame, setLevelButtonsForTheGame] = useState<GameLevelsData[]>([new GameLevelsData()]);

  const handleGameLevelButton = (buttonTitle: string, level: number) => {
    setGameSelectionLevel(level);
    setStartGame(true);
  };

  useEffect(() => {
    if (gameSelectionLevel !== 0) {
      fetch(process.env.REACT_APP_API_SERVER_URL + `/picture_match/images/${gameSelectionLevel}`)
        .then((res) => {
          return res.json();
        })
        .then((shapes) => {
          setShapes(shapes);
          setSequenceOfShapesForTheGame(getSequenceOfShapes(shapes, NUMBER_OF_SEQUENCES, MAX, MIN, THRESHOLD));
        });
    }
  }, [gameSelectionLevel]);

  useEffect(() => {}, [levelAchieved]);

  useEffect(() => {
    if (userDataForGames != undefined || userDataForGames != null) {
      setUserDataForTheGame(userDataForGames!.find((x: any) => x.gameType === "ShapeMatchMentalSpeed"));
    }
  }, [userDataForGames]);

  useEffect(() => {
    setLevelButtonsForTheGame(getGameLevelData(activeTab, userDataForTheGame != undefined ? userDataForTheGame.levelAchieved : 1));
  }, [userDataForTheGame]);

  if (startGame) {
    return <CountDown counter={counter} setCounter={setCounter} setStart={setStartGame} />;
  }

  return (
    <Fragment>
      <div className="container py-5 h-100">
        <section className="vh-100">
          {counter === 0 ? (
            <Game
              shapesSequenceForGame={sequenceOfShapesForTheGame}
              setStart={setStartGame}
              setCounter={setCounter}
              setGameSelectionLevel={setGameSelectionLevel}
              level={gameSelectionLevel}
              taskLength={NUMBER_OF_SEQUENCES}
              levelButtonsForTheGame={levelButtonsForTheGame}
            />
          ) : (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div className="container py-5 h-100"></div>
              <h2 className="text-center mb-4">{t("games.select_difficulty_level")}:</h2>
              <div className="container h-100">
                <Row xs={2} md={3} className="g-4">
                  {levelButtonsForTheGame.map((button, index) => (
                    <Col key={index}>
                      <Card
                        className={`card-style ${button.active ? "" : "disabled-link"}`}
                        onClick={button.active ? () => handleGameLevelButton(button.title, button.level) : () => {}}
                        // className="card-style"
                        // onClick={() => handleGameLevelButton(button.id, button.title, button.level)}
                        role="button"
                      >
                        <Card.Img variant="top" className="image-style-shape" src={`${button.image}`} />
                        <Card.Title className="text-center font-style">{t(button.title)}</Card.Title>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
              <hr className="hr-style" />
              <div className="text-center mt-5">
                <Link to="/" className="m-3 text-center">
                  <Button variant="outline-secondary" size="lg" onClick={() => handleUpdateAppWithUserWeeklyScheduleGames(updateCurrentUserInfoWithGameSchedule, noSchedule)}>
                    {t("common.close")}
                  </Button>
                </Link>
              </div>
            </div>
          )}
        </section>
      </div>
    </Fragment>
  );
};

export default ShapeMatchMentalSpeed;

import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import "./style.css";

interface props {
  counter: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
  setStart: React.Dispatch<React.SetStateAction<boolean>>;
}

const CountDown = (props: props) => {
  const { counter, setCounter, setStart } = props;
  const { t } = useTranslation("common");
  //const [counter, setCounter] = useState<number>(3);
  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setStart(false);
    }
  }, [counter]);
  return (
    <div className="d-flex flex-column justify-content-between align-items-center h-100 mt-4">
      <h3 className="m-5">{t("common.count_down_text")}</h3>
      <div className="circle-div">{counter}</div>
    </div>
  );
};

export default CountDown;

import * as React from "react";
import "@progress/kendo-theme-default/dist/all.css";
import { useTranslation } from "react-i18next";

import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
  ChartTooltip,
  WeekStartDay,
} from "@progress/kendo-react-charts";

import "hammerjs";
import {
  getDaysBetweenDates,
  WeekResults,
} from "../../helpers/motivation-message-helpers";

interface Props {
  weekResults: WeekResults[];
  endDate: Date;
}

const LineChart = (props: Props) => {
  const { t } = useTranslation("common");
  const { weekResults } = props;

  return (
    <div>
      <Chart>
        <ChartTitle text={`${t("games.score_card.weekly_target")} (%)`} />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            type="date"
            baseUnit="weeks"
            categories={getDaysBetweenDates(
              weekResults.length > 0 ? weekResults[0].startsAt : "",
              props.endDate.toString() ?? ""
            )}
            maxDivisions={12}
            labels={{ format: "MM/yyyy" }}
            rangeLabels={{ format: "MM/yyyy", visible: true }}
            weekStartDay={WeekStartDay.Monday}
          />
        </ChartCategoryAxis>
        <ChartSeries>
          <ChartSeriesItem
            color="#4473AF"
            type="line"
            data={weekResults.map((x) => x.playedPercentage)}
            markers={{ visible: true }}
            highlight={{ visible: true }}
          />
        </ChartSeries>
        <ChartTooltip
          render={(props: any) => <div>{props.point?.value}%</div>}
        />
      </Chart>
    </div>
  );
};

export default LineChart;

export class MaxMin {
  min: number;
  max: number;
  constructor(dto: any) {
    this.min = dto.min;
    this.max = dto.max;
  }
}

export class GameLevelsData {
  difficultyLevel?: MaxMin = undefined;
  title: string = "";
  level: number = 0;
  time_ms?: number = undefined;
  items?: number = undefined;
  options?: number = undefined;
  image?: string = undefined;
  imageArray?: string[] = undefined;
  max_points?: number = undefined;
  active: boolean = false;
}

import * as React from "react";
import {createContext, useContext, useEffect, useState} from "react";
import { ActiveTab } from "../models/active-tab";
import {useUserInformation} from "../hooks/user-info";
import {useUserInfo} from "./user-info-context";

interface ActiveTabInterface {
  activeTab: ActiveTab;
  toggleTab?: (id: ActiveTab) => void;
}

const defaultTabState: ActiveTabInterface = {
  activeTab: "Training",
};
const ActiveTabContext = createContext<ActiveTabInterface>(defaultTabState);

export const ActiveTabProvider = (props: { children: JSX.Element | JSX.Element[] }) => {
  const [activeTab, setActive] = useState<ActiveTab>(defaultTabState.activeTab);
  const { scheduleCompleted } = useUserInfo();
  
  useEffect(() => {
    if (scheduleCompleted) {
      setActive("Practicing");
    }
  },[scheduleCompleted])

  const toggleTab = (id: ActiveTab) => {
    setActive(id);
  };

  return <ActiveTabContext.Provider value={{ activeTab, toggleTab }}>{props.children}</ActiveTabContext.Provider>;
};

export const useActiveTab = () => useContext(ActiveTabContext) as ActiveTabInterface;

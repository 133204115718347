import g1 from "../images/G1.png";
import g1h1_eng from "../images/G1H1-ENG.png";
import g1h1_fin from "../images/G1H1-FIN.png";
import g1h1_ger from "../images/G1H1-GER.png";
import g1h1_ita from "../images/G1H1-ITA.png";
import g1h1_swe from "../images/G1H1-SWE.png";
import g2 from "../images/G2.png";
import g3 from "../images/G3.png";
import g4 from "../images/G4.png";
import g5 from "../images/G5ND1.png";
import g6 from "../images/G6.png";
import g5L from "../images/G5L.png";
import g5N from "../images/G5N.png";
import g5C from "../images/G5C.png";
import g5P from "../images/G5P.png";
import { GameType } from "../models/game-type";
import { ActiveTab } from "../models/active-tab";
import {i18n} from "../i18n/i18n";

export interface ButtonProps {
  name: string;
  image: string;
  active: boolean;
  route: string;
  isSchedule: boolean;
  gameType: GameType;
}

export const getGameButtons = (activeTab: ActiveTab, schedule?: any) => {
  let gameTypesForSchedule: string[] = []
  
  if(schedule !== undefined) {
    gameTypesForSchedule = schedule!.gameTypes.map((x: string) => x);
  } else {
    gameTypesForSchedule = [
        "EpisodicMemoryRelational",
        "EpisodicMemorySpatialSpeed",
        "MentalSetShifting",
        "ShapeMatchMentalSpeed",
        "UpdatingColor",
        "UpdatingLetter",
        "UpdatingNumber",
        "UpdatingSpatial",
        "WorkingMemoryMaintenance",
      ]
    }

  const gameTypesForPractice = [
    "EpisodicMemoryRelational",
    "EpisodicMemorySpatialSpeed",
    "MentalSetShifting",
    "ShapeMatchMentalSpeed",
    "UpdatingItems",
    "WorkingMemoryMaintenance",
  ];
 
  
  const gameTitleTranslations = [
    "games.titles.triplets",
    "games.titles.memory_cards",
    "games.titles.light_up",
    "games.titles.shape_match",
    "games.titles.last_four",
    "games.titles.last_four_letters",
    "games.titles.last_four_numbers",
    "games.titles.last_four_colors",
    "games.titles.last_four_spatial",
    "games.titles.mental_shifting",
  ];
  
  let triplet_image:any = g1
  switch(i18n.language) {
    case "fi" : {
      triplet_image = g1h1_fin;
      break;
    }
    case "sv" : {
      triplet_image = g1h1_swe;
      break;
    }
    case "it" : {
      triplet_image = g1h1_ita;
      break;
    }
    case "de" : {
      triplet_image = g1h1_ger;
      break;
    }
    default : {
      triplet_image = g1h1_eng;
      break;
    }
  }
  const images = [triplet_image, g2, g3, g4, g5, g5L, g5N, g5C, g5P, g6];
  const routes = [
    "/episodic-memory-relational-instructions",
    "/episodic-memory-spatial-speed-instructions",
    "/light-up-instructions",
    "/shape-match-mental-speed-instructions",
    "/updating-items",
    "/mental-set-shifting-instructions",
  ];

  const getGameButtonsForGame = (type: string) => {
    switch (type) {
      case "EpisodicMemoryRelational": {
        return {
          name: gameTitleTranslations[0],
          image: images[0],
          active: true,
          route: routes[0],
          gameType: type,
        };
      }
      case "EpisodicMemorySpatialSpeed": {
        return {
          name: gameTitleTranslations[1],
          image: images[1],
          active: true,
          route: routes[1],
          gameType: type,
        };
      }
      case "WorkingMemoryMaintenance": {
        return {
          name: gameTitleTranslations[2],
          image: images[2],
          active: true,
          route: routes[2],
          gameType: type,
        };
      }
      case "ShapeMatchMentalSpeed": {
        return {
          name: gameTitleTranslations[3],
          image: images[3],
          active: true,
          route: routes[3],
          gameType: type,
        };
      }
      case "UpdatingItems": {
        return {
          name: gameTitleTranslations[4],
          image: images[4],
          active: true,
          route: routes[4],
          gameType: type,
        };
      }
      case "UpdatingLetter": {
        return {
          name: gameTitleTranslations[5],
          image: images[5],
          active: true,
          route: routes[4],
          gameType: type,
        };
      }
      case "UpdatingNumber": {
        return {
          name: gameTitleTranslations[6],
          image: images[6],
          active: true,
          route: routes[4],
          gameType: type,
        };
      }
      case "UpdatingColor": {
        return {
          name: gameTitleTranslations[7],
          image: images[7],
          active: true,
          route: routes[4],
          gameType: type,
        };
      }
      case "UpdatingSpatial": {
        return {
          name: gameTitleTranslations[8],
          image: images[8],
          active: true,
          route: routes[4],
          gameType: type,
        };
      }
      case "MentalSetShifting": {
        return {
          name: gameTitleTranslations[9],
          image: images[9],
          active: true,
          route: routes[5],
          gameType: type,
        };
      }
      default: {
        break;
      }
    }
  };

  const buttonsForTheGames = (activeTab: ActiveTab) => {
    const newArrayForScheduleGameButton: ButtonProps[] | any = gameTypesForSchedule.map((x) => getGameButtonsForGame(x));
    const newArrayForPracticeGameButton: ButtonProps[] | any = gameTypesForPractice.map((x) => getGameButtonsForGame(x));
    return activeTab === "Practicing" ? newArrayForPracticeGameButton : newArrayForScheduleGameButton;
  };
  return buttonsForTheGames(activeTab);
};

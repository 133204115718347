import React from "react";

interface ButtonProps {
  className: string;
  clicked: () => void;
  disabled: boolean;
  isCorrect: () => boolean;
  active: boolean;
  size: number;
  text: string;
}

const RoundButton = (props: ButtonProps) => {
  const { className, clicked } = props;

  const style = () => {
    return (
      "light-circle-" +
      props.size +
      "x" +
      props.size +
      (props.disabled ? " light-circle-disabled" : " light-circle")
    );
  };

  const correctnessStyle = () => {
    return props.isCorrect()
      ? "light-circle-selected-correct"
      : "light-circle-selected";
  };

  //  const btnToActives = document.querySelectorAll(".for-mobile");
  // btnToActives.forEach((btn) => {
  //   btn.addEventListener("click", (e) => {
  //     btnToActives.forEach(f => f.classList.remove("active"));
  //     (e.target as Element).classList.toggle("active");
  //    })
  // })

  // const toggleActive = () => {
  //  const elem = document.querySelector(".for-mobile");
  //  console.log(elem);
  //  elem!.classList.toggle("active");
  // }

  // const handleClick = () => {
  //  clicked();
  //  toggleActive();
  // }

  return props.active ? (
    <button
      className={`${style()} ${correctnessStyle()} light-circle ${className}`}
      disabled={props.disabled}
      onClick={() =>
        setTimeout(() => {
          clicked();
        }, 50)
      }
      style={{ cursor: "pointer" }}
    >
      {props.text}
    </button>
  ) : (
    <button
      className={`${style()} ${className}`}
      disabled={props.disabled}
      onClick={() =>
        setTimeout(() => {
          clicked();
        }, 50)
      }
      style={{ cursor: "pointer" }}
    ></button>
  );
};

export default RoundButton;
